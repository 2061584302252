import {
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  arrayUnion,
  documentId,
  setDoc,
} from "firebase/firestore";
import { db } from "./firebase";

// Fetch all turfs
export const fetchAllTurfs = async () => {
  try {
    const turfCollection = collection(db, "turfDetails");
    const turfSnapshot = await getDocs(turfCollection);
    const turfs = turfSnapshot.docs.map((doc) => ({
      turf_id: doc.id,
      ...doc.data(),
    }));
    return turfs;
  } catch (error) {
    console.error("Error fetching turfs:", error.message);
    throw error;
  }
};

// Fetch turf by ID
export const fetchTurfById = async (turfId) => {
  try {
    const turfRef = doc(db, "turfDetails", turfId);
    const turfDoc = await getDoc(turfRef);
    if (turfDoc.exists()) {
      return { turf_id: turfDoc.id, ...turfDoc.data() };
    } else {
      console.log("No such turf found!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching turf by ID:", error.message);
    throw error;
  }
};

// Add a new turf
export const addTurf = async (turfData) => {
  try {
    const turfCollection = collection(db, "turfDetails");
    const turfRef = await addDoc(turfCollection, {
      ...turfData,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    });
    console.log("New turf added with ID:", turfRef.id);
    return turfRef.id;
  } catch (error) {
    console.error("Error adding new turf:", error.message);
    throw error;
  }
};

// Update a turf by ID
export const updateTurfById = async (turfId, updatedData) => {
  try {
    const turfRef = doc(db, "turfDetails", turfId);
    console.log(updatedData);

    await updateDoc(turfRef, {
      ...updatedData,
      updated_at: serverTimestamp(),
    });
    console.log("Turf updated successfully:", turfId);
  } catch (error) {
    console.error("Error updating turf:", error.message);
    throw error;
  }
};

// Delete a turf by ID
export const deleteTurfById = async (turfId) => {
  try {
    const turfRef = doc(db, "turfDetails", turfId);
    await deleteDoc(turfRef);
    console.log("Turf deleted successfully:", turfId);
  } catch (error) {
    console.error("Error deleting turf:", error.message);
    throw error;
  }
};

// Fetch turfs by location within a given radius
export const fetchTurfsByLocation = async (latitude, longitude, radius) => {
  try {
    const turfCollection = collection(db, "turfDetails");
    const turfSnapshot = await getDocs(turfCollection);
    const turfs = turfSnapshot.docs
      .map((doc) => ({
        turf_id: doc.id,
        ...doc.data(),
      }))
      .filter((turf) => {
        const distance = getDistanceFromLatLonInKm(
          latitude,
          longitude,
          turf.geo_coordinates.latitude,
          turf.geo_coordinates.longitude
        );
        return distance <= radius;
      });
    return turfs;
  } catch (error) {
    console.error("Error fetching turfs by location:", error.message);
    throw error;
  }
};

// Fetch turfs by availability
export const fetchTurfsByAvailability = async (date, startTime, endTime) => {
  try {
    const turfCollection = collection(db, "turfDetails");
    const turfSnapshot = await getDocs(turfCollection);
    const availableTurfs = turfSnapshot.docs
      .map((doc) => ({
        turf_id: doc.id,
        ...doc.data(),
      }))
      .filter((turf) =>
        turf.availability.some(
          (slot) =>
            slot.date === date &&
            slot.start_time <= startTime &&
            slot.end_time >= endTime
        )
      );
    return availableTurfs;
  } catch (error) {
    console.error("Error fetching turfs by availability:", error.message);
    throw error;
  }
};

// Fetch turfs by price range
export const fetchTurfsByPriceRange = async (minPrice, maxPrice) => {
  try {
    const turfCollection = collection(db, "turfDetails");
    const priceQuery = query(
      turfCollection,
      where("price_per_hour", ">=", minPrice),
      where("price_per_hour", "<=", maxPrice)
    );
    const turfSnapshot = await getDocs(priceQuery);
    const turfs = turfSnapshot.docs.map((doc) => ({
      turf_id: doc.id,
      ...doc.data(),
    }));
    return turfs;
  } catch (error) {
    console.error("Error fetching turfs by price range:", error.message);
    throw error;
  }
};

// Book a turf
export const bookTurf = async (turfId, bookingDetails) => {
  try {
    const turfRef = doc(db, "turfDetails", turfId);
    const turfDoc = await getDoc(turfRef);

    if (turfDoc.exists()) {
      const turfData = turfDoc.data();
      const { booking_history } = turfData;

      let existingBookings = [];

      if (booking_history.length > 0) {
        // Fetch existing bookings using their references from booking_history
        const existingBookingsQuery = query(
          collection(db, "bookings"),
          where(
            documentId(),
            "in",
            booking_history.map((ref) => ref.id),
            where("bookingDate", "==", bookingDetails.bookingDate),
            where(
              "selectedTurf.turfNumber",
              "==",
              bookingDetails.selectedTurf.turfNumber
            ),
            where("status", "==", "paid")
          )
        );

        const existingBookingsSnapshot = await getDocs(existingBookingsQuery);
        existingBookings = existingBookingsSnapshot.docs.map((doc) =>
          doc.data()
        );
      }

      // console.log(existingBookings);

      // Check for conflicts with existing bookings
      const isConflict = existingBookings.some(
        (booking) =>
          booking.bookingDate === bookingDetails.bookingDate &&
          booking.start_time < bookingDetails.end_time &&
          booking.end_time > bookingDetails.start_time &&
          booking.selectedTurf.turfNumber ===
            bookingDetails.selectedTurf.turfNumber
      );

      console.log(isConflict);

      if (!isConflict) {
        // Create a new booking document in the 'bookings' collection
        const newBookingRef = doc(collection(db, "bookings"));
        await setDoc(newBookingRef, bookingDetails);

        // Add the new booking reference to the turf document
        await updateDoc(turfRef, {
          booking_history: arrayUnion(newBookingRef),
        });

        console.log("Turf booked successfully");
      } else {
        console.log("Turf is already booked at the selected time.");
      }
    } else {
      console.log("Turf not found.");
    }
  } catch (error) {
    console.error("Error booking turf:", error.message);
    throw error;
  }
};

// Helper function to calculate distance between two coordinates
const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
};

const deg2rad = (deg) => deg * (Math.PI / 180);

// Fetch all turfs
export const fetchAllBookings = async () => {
  try {
    const bookingCollection = collection(db, "bookings");
    const bookingSnapshot = await getDocs(bookingCollection);
    const bookings = bookingSnapshot.docs.map((doc) => ({
      booking_id: doc.id,
      ...doc.data(),
    }));
    return bookings;
  } catch (error) {
    console.error("Error fetching bookings:", error.message);
    throw error;
  }
};

// Fetch booking by ID
export const fetchBookingById = async (bookingId) => {
  try {
    const bookingRef = doc(db, "bookings", bookingId);
    const bookingDoc = await getDoc(bookingRef);
    if (bookingDoc.exists()) {
      return { booking_id: bookingDoc.id, ...bookingDoc.data() };
    } else {
      console.log("No such booking found!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching booking by ID:", error.message);
    throw error;
  }
};
