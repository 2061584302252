import React, { useState } from "react";
import RoleToggleButton from "../../components/RoleToggleButton";
import googleIcon from "../../assets/loginpage/flat-color-icons_google.png";
import appleIcon from "../../assets/loginpage/foundation_social-apple.png";
import mailIcon from "../../assets/loginpage/mail-01.png";
import lockIcon from "../../assets/loginpage/lock.png";
import eyeIcon from "../../assets/loginpage/eye-slash.png";
import eyeOpenIcon from "../../assets/loginpage/eye.png";
import userIcon from "../../assets/loginpage/user-01.png";
import {
  signInWithApple,
  signInWithGoogle,
  signUpWithEmail,
} from "../../firebase/authController";
import { playerSchema } from "../../firebase/schemas";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../app/features/authSlice";
import { useDispatch } from "react-redux";
import logoImg2 from "../../assets/homepage/image 3.png";

const CreateUser = ({ setNewUser, handleToggle, role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  // Validation messages
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Name regex
  const nameRegex = /^[A-Za-z\s]+$/;
  // Email regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Password must be at least 8 characters, contain one number, and one special character
  const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]{8,}$/;

  const validateName = (value) => {
    const nameRegex = /^[A-Za-z\s]+$/; // Allows only alphabets and spaces

    if (value.length < 3) {
      setNameError("Name must be at least 3 characters long!");
    } else if (value.length > 50) {
      setNameError("Name cannot be longer than 50 characters!");
    } else if (!nameRegex.test(value)) {
      setNameError("Name can only contain alphabets and spaces!");
    } else if (/\s{2,}/.test(value)) {
      setNameError("Name cannot have consecutive spaces!");
    } else if (value.trim() !== value) {
      setNameError("Name cannot start or end with a space!");
    } else {
      setNameError(""); // Clear error if all conditions are met
    }
  };

  const validateEmail = (value) => {
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (value) => {
    if (!passwordRegex.test(value)) {
      setPasswordError(
        "Password must be at least 8 characters, include a number and a special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const validateConfirmPassword = (value) => {
    if (value !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  function handleEmailSignUp(e) {
    e.preventDefault();
    if (password === confirmPassword) {
      signUpWithEmail(email, password, {
        ...playerSchema,
        name,
        email,
        role,
      }).then(() => {
        window.location.reload();
      });
    } else {
      alert("Passwords do not match. Please check your values and try again.");
    }
  }

  function handleGoogleSignIn(e) {
    e.preventDefault();
    signInWithGoogle(playerSchema, role).then((userData) => {
      dispatch(setUser(userData));
      navigate("/");
    });
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <div className="max-w-xs lg:max-w-md p-4 lg:p-0">
      <div className="flex justify-center mb-[5vw] md:mb-[2vw] w-full h-full">
        <img src={logoImg2} className="w-full h-full object-contain" alt="" />
      </div>
      <h1 className="font-poppins my-3 text-lg font-bold">
        Create a {role} Account
      </h1>
      <p className="font-poppins mb-5 text-sm lg:text-base text-gray-700">
        Create your {role} account to get started!
      </p>
      <p className="flex justify-center items-center my-6 lg:my-9">
        <span
          className={`font-poppins mx-4 text-lg lg:text-xl ${
            role === "Player" ? "text-blue-500 font-bold" : "text-blue-300"
          }`}
        >
          Player
        </span>
        <RoleToggleButton role={role} handleToggle={handleToggle} />
        <span
          className={`font-poppins mx-4 text-lg lg:text-xl ${
            role !== "Player" ? "text-blue-500 font-bold" : "text-blue-300"
          }`}
        >
          Turf Owner
        </span>
      </p>
      <form
        action=""
        onSubmit={handleEmailSignUp}
        className="flex flex-col gap-[5vw] md:gap-[1.75vw] w-full md:min-w-[30vw] md:w-full"
      >
        <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
          <div className="relative w-full h-12 drop-shadow-xl">
            <img
              src={userIcon}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              alt="icon"
            />
            <input
              className="w-full h-full px-10 py-2 select-none outline-none bg-gray-100 border border-gray-300 rounded-lg"
              type="text"
              required
              placeholder="Name"
              name="name"
              id="name"
              autocomplete="new-password"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                validateName(e.target.value);
              }}
            />
          </div>
          {name ? (
            nameError ? (
              <p className="text-red-600 text-sm">{nameError}</p>
            ) : (
              <p className="text-green-600 text-sm">Looks good!</p>
            )
          ) : (
            <p className="text-green-600 text-sm"></p>
          )}
        </div>
        <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
          <div className="relative w-full h-12 drop-shadow-xl">
            <img
              src={mailIcon}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              alt="icon"
            />
            <input
              className="w-full h-full px-10 py-2 select-none outline-none bg-gray-100 border border-gray-300 rounded-lg"
              type="email"
              required
              placeholder="Email"
              name="email"
              id="email"
              autocomplete="new-password"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
            />
          </div>
          {email ? (
            emailError ? (
              <p className="text-red-600 text-sm">{emailError}</p>
            ) : (
              <p className="text-green-600 text-sm">Looks good!</p>
            )
          ) : (
            <p className="text-green-600 text-sm"></p>
          )}
        </div>
        <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
          <div className="relative w-full h-12 drop-shadow-xl">
            <img
              src={lockIcon}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              alt="icon"
            />
            <input
              className="w-full h-full px-10 py-2 select-none outline-none bg-gray-100 border border-gray-300 rounded-lg"
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              name="password"
              required
              id="password"
              autocomplete="new-password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
              }}
            />
            <img
              src={passwordVisible ? eyeOpenIcon : eyeIcon}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 cursor-pointer"
              alt="icon"
              onClick={togglePasswordVisibility}
            />
          </div>
          {password ? (
            passwordError ? (
              <p className="text-red-600 text-sm">Error: {passwordError}</p>
            ) : (
              <p className="text-green-600 text-sm">Looks good!</p>
            )
          ) : (
            <p className="text-green-600 text-sm"></p>
          )}
        </div>
        <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
          <div className="relative w-full h-12 drop-shadow-xl">
            <img
              src={lockIcon}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              alt="icon"
            />
            <input
              className="w-full h-full px-10 py-2 select-none outline-none bg-gray-100 border border-gray-300 rounded-lg"
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="Confirm Password"
              name="confirmPassword"
              required
              id="confirmPassword"
              autocomplete="new-password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                validateConfirmPassword(e.target.value);
              }}
            />
            <img
              src={confirmPasswordVisible ? eyeOpenIcon : eyeIcon}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 cursor-pointer"
              alt="icon"
              onClick={toggleConfirmPasswordVisibility}
            />
          </div>
          {password && confirmPasswordError && (
            <p className="text-red-600 text-sm">{confirmPasswordError}</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full h-12 rounded-lg font-poppins text-base mb-5 mt-5 bg-blue-600 text-white"
        >
          Sign Up
        </button>
      </form>
      <div className="flex items-center text-center my-6 lg:my-9">
        <div className="flex-1 h-px bg-gray-300"></div>
        <p className="font-poppins text-sm lg:text-base mx-4 text-gray-700">
          Or Sign Up with
        </p>
        <div className="flex-1 h-px bg-gray-300"></div>
      </div>
      <div
        className="flex justify-center items-center w-full h-12 shadow-xl rounded-lg font-poppins text-base cursor-pointer bg-white"
        onClick={handleGoogleSignIn}
      >
        <img src={googleIcon} alt="" className="w-6 h-6 mr-3" />
        <p>Sign Up with Google</p>
      </div>
      {/* <div
        className="flex justify-center items-center w-full h-12 shadow-xl rounded-lg font-poppins text-base cursor-pointer bg-white"
        onClick={() => {
          signInWithApple({ ...playerSchema, role });
        }}
      >
        <img src={appleIcon} alt="" className="w-6 h-6 mr-3" />
        <p>Sign Up with Apple</p>
      </div> */}
      <p className="text-center my-6 lg:my-9">
        Already have an account?{" "}
        <button onClick={() => setNewUser(false)} className="text-red-600">
          Sign In
        </button>
      </p>
    </div>
  );
};

export default CreateUser;
