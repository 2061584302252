import {
  query,
  collection,
  where,
  getDocs,
  getDoc,
  doc,
  documentId,
} from "firebase/firestore"; // Import Firestore functions
import { db } from "../../firebase/firebase";

// Utility function to convert time to minutes
const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

// Utility function to convert minutes to time
const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};

// Function to calculate available slots
export async function calculateSlots(
  selectedTurf,
  selectedDate,
  turfId,
  startTime,
  endTime,
  intervalType
) {
  const intervals = {
    Hourly: 60,
    "1.5hrs": 90,
    "2hrs": 120,
  };

  const intervalMinutes = intervals[intervalType];
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);

  let currentMinutes = startMinutes;
  const availableSlots = [];

  // Get the current date and time
  const today = new Date();
  const currentDay = today.toISOString().split("T")[0];
  const currentMinutesOfDay = today.getHours() * 60 + today.getMinutes();

  // Check if the selected date is today
  const isToday = selectedDate === currentDay;

  // Get the turf's booking history
  const turfRef = doc(db, "turfDetails", turfId);
  const turfDoc = await getDoc(turfRef);

  if (!turfDoc.exists()) {
    throw new Error("Turf not found");
  }

  const turfData = turfDoc.data();
  const { booking_history } = turfData;

  // If booking_history is empty, return all slots as available
  if (booking_history.length === 0) {
    while (currentMinutes + intervalMinutes <= endMinutes) {
      const slotStartTime = minutesToTime(currentMinutes);
      const slotEndTime = minutesToTime(currentMinutes + intervalMinutes);
      const isAvailable = !(isToday && currentMinutes < currentMinutesOfDay);

      availableSlots.push({
        slot_time: `${slotStartTime} - ${slotEndTime}`,
        available: isAvailable,
      });
      currentMinutes += intervalMinutes;
    }
    return availableSlots;
  }

  // Convert booking history references to booking objects
  const bookingRefs = booking_history.map((ref) => ref.id);
  const bookingsQuery = query(
    collection(db, "bookings"),
    where(documentId(), "in", bookingRefs),
    where("bookingDate", "==", selectedDate),
    where("selectedTurf.turfNumber", "==", selectedTurf.turfNumber),
    where("status", "==", "paid")
  );

  const bookingSnapshot = await getDocs(bookingsQuery);
  const bookedSlots = bookingSnapshot.docs.map((doc) => doc.data());

  // Check each slot for availability
  while (currentMinutes + intervalMinutes <= endMinutes) {
    const slotStartTime = minutesToTime(currentMinutes);
    const slotEndTime = minutesToTime(currentMinutes + intervalMinutes);
    const slotRange = { start: slotStartTime, end: slotEndTime };

    const isAvailable =
      !bookedSlots.some((booking) => {
        const bookingStart = booking.start_time;
        const bookingEnd = booking.end_time;
        return slotStartTime < bookingEnd && slotEndTime > bookingStart;
      }) && !(isToday && currentMinutes < currentMinutesOfDay);

    availableSlots.push({
      slot_time: `${slotStartTime} - ${slotEndTime}`,
      available: isAvailable,
    });

    currentMinutes += intervalMinutes;
  }

  return availableSlots;
}
