import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import LocationSelector from "./LocationSelector";
import BottomNavBar from "./BottomNavBar";
import logoImg from "../assets/homepage/image 2.png";
import logoImg2 from "../assets/homepage/image 3.png";
import { useSelector } from "react-redux";
import { logout } from "../firebase/authController";

const Navbar = () => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  return (
    <>
      <nav className="navbar relative top-0 md:top-[0.5vw] mx-0 md:mx-[2vw] bg-[#4277FF] md:bg-[#E7EEFF] h-[20vw] md:h-[5vw] flex items-center justify-around md:justify-between md:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] z-50 lg:rounded-[1.875vw] max-w-[100vw] overflow-hidden">
        <Link to="/">
          <div className="flex items-center gap-[5vw] md:gap-[1.5vw] mx-[1vw] md:mx-[2vw]">
            <img
              className="md:hidden rounded-full w-[15vw] h-[15vw]"
              src={logoImg}
              alt="Logo"
            />
            <img
              className="hidden md:block md:w-[14vw] md:h-[3.75vw]"
              src={logoImg2}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="hidden md:flex items-center gap-[1vw] md:gap-[1.5vw] font-poppins text-[#27346A] text-sm md:text-[1.125vw] lg:text-xl">
          <div
            className={`${
              location.pathname === "/"
                ? "text-[#27346A]"
                : "text-[#27346A]/70 hover:text-[#27346A]"
            }`}
          >
            <NavLink
              className="text-[1.5vw] font-medium font-poppins leading-[160%] tracking-[0.4px]"
              to="/"
            >
              Home
            </NavLink>
          </div>
          <div
            className={`${
              location.pathname === "/myBookings"
                ? "text-[#27346A]"
                : "text-[#27346A]/70 hover:text-[#27346A]"
            }`}
          >
            <NavLink
              className="text-[1.5vw] font-medium font-poppins leading-[160%] tracking-[0.4px]"
              to={user?.uid ? "/myBookings" : "/login"}
            >
              My Bookings
            </NavLink>
          </div>
          <div
            className={`${
              location.pathname === `/userProfile/${user?.uid}`
                ? "text-[#27346A]"
                : "text-[#27346A]/70 hover:text-[#27346A]"
            }`}
          >
            <NavLink
              className="text-[1.5vw] font-medium font-poppins leading-[160%] tracking-[0.4px]"
              to={user?.uid ? `/userProfile/${user?.uid}` : "/login"}
            >
              Profile
            </NavLink>
          </div>
          <div
            className={`${
              location.pathname === "/login"
                ? "text-[#27346A]"
                : "text-[#27346A]/70 hover:text-[#27346A]"
            }`}
          >
            {user?.uid ? (
              <NavLink
                className="text-[1.5vw] font-medium font-poppins leading-[160%] tracking-[0.4px]"
                onClick={() => {
                  logout();
                }}
                to="/login"
              >
                Logout
              </NavLink>
            ) : (
              <NavLink
                className="text-[1.5vw] font-medium font-poppins leading-[160%] tracking-[0.4px]"
                to="/login"
              >
                Login
              </NavLink>
            )}
          </div>
        </div>
        <div className="flex items-center gap-[1vw] md:gap-[1.5vw] mx-[1vw] md:mx-[2vw]">
          <LocationSelector />
        </div>
      </nav>
      <BottomNavBar userId={user?.uid} />
    </>
  );
};

export default Navbar;
