import React from "react";
import Users from "./pages/Users";
import Turfs from "./pages/Turfs";
import TurfVerification from "./pages/TurfVerification";
import Bookings from "./pages/Bookings";
import WebsiteContent from "./pages/WebsiteContent";

const Template = ({ page, setIsAdmin }) => {
  const pages = {
    Users: <Users />,
    Turfs: <Turfs />,
    Bookings: <Bookings />,
    // "Turf Verification": <TurfVerification />,
    "Website Content": <WebsiteContent />,
  };

  const handleLogout = () => {
    setIsAdmin(false);
    localStorage.setItem("isAdmin", false);
  };

  return (
    <div className="h-full col-span-4 flex flex-col">
      {/* Top Bar */}
      <div className="h-[70px] bg-black text-white flex items-center justify-between px-6">
        <div className="text-lg font-bold">{page}</div>
        <div className="flex space-x-4">
          <span onClick={handleLogout} className="cursor-pointer">
            Logout
          </span>
        </div>
      </div>
      {/* Content */}
      <div className="flex-1 p-6">{pages[page]}</div>
    </div>
  );
};

export default Template;
