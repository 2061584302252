import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import turfReducer from "./features/turfSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    turf: turfReducer,
  },
});

export default store;
