import React, { useEffect, useState } from "react";

const TurfGrid = ({ turfs, setTurfs }) => {
  const [data, setData] = useState(turfs || []);

  const [showForm, setShowForm] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [formData, setFormData] = useState({
    turfNumber: "",
    size: "5v5", // Default value
    surfaceType: "",
    pricing: 0, //Default value
  });

  const handleShowForm = (row = null) => {
    if (row) {
      setFormData(row);
      setEditRow(row.id);
    } else {
      setFormData({ turfNumber: "", size: "5v5", surfaceType: "", pricing: 0 });
      setEditRow(null);
    }
    setShowForm(true);
  };

  const handleCloseForm = () => setShowForm(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    if (editRow) {
      setData(
        data.map((item) =>
          item.id === editRow ? { ...formData, id: editRow } : item
        )
      );
    } else {
      setData([...data, { ...formData, id: Date.now() }]);
    }
    handleCloseForm();
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  useEffect(() => {
    setTurfs(data);
  }, [data]);

  return (
    <div className="p-[1.5vw] space-y-[1vw]">
      <button
        onClick={(e) => {
          e.preventDefault();
          handleShowForm();
        }}
        className="bg-[#4277FF] px-[3.75vw] py-[2.5vw] md:px-[2vw] md:py-[0.5vw] mx-auto mt-[1vw] text-white rounded-full w-fit shadow-md shadow-black/25 text-[3.75vw] md:text-[1.125vw]"
      >
        Add Turf
      </button>

      <div className="overflow-x-auto shadow-md shadow-black/25 rounded-lg px-[2vw] py-[0.5vw]">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-[0.75vw] py-[0.375vw] text-left text-[3vw] md:text-[1vw] font-medium text-gray-500 uppercase tracking-wider">
                Turf Number
              </th>
              <th className="px-[0.75vw] py-[0.375vw] text-left text-[3vw] md:text-[1vw] font-medium text-gray-500 uppercase tracking-wider">
                Size
              </th>
              <th className="px-[0.75vw] py-[0.375vw] text-left text-[3vw] md:text-[1vw] font-medium text-gray-500 uppercase tracking-wider">
                Surface Type
              </th>
              <th className="px-[0.75vw] py-[0.375vw] text-left text-[3vw] md:text-[1vw] font-medium text-gray-500 uppercase tracking-wider">
                Pricing
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 ">
            {data.map((row) => (
              <tr key={row.id}>
                <td className="px-[1.5vw] py-[1vw] text-[3vw] md:text-[1vw] whitespace-nowrap">
                  {row.turfNumber}
                </td>
                <td className="px-[1.5vw] py-[1vw] text-[3vw] md:text-[1vw] whitespace-nowrap">
                  {row.size}
                </td>
                <td className="px-[1.5vw] py-[1vw] text-[3vw] md:text-[1vw] whitespace-nowrap">
                  {row.surfaceType}
                </td>
                <td className="px-[1.5vw] py-[1vw] text-[3vw] md:text-[1vw] whitespace-nowrap">
                  {row.pricing}
                </td>
                <td className="px-[1.5vw] py-[1vw] text-[3vw] md:text-[1vw] whitespace-nowrap text-right">
                  <button
                    onClick={() => handleShowForm(row)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(row.id)}
                    className="text-red-600 hover:text-red-900 ml-4"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-[0.75vw] rounded-[0.5vw] shadow-lg w-full max-w-md">
            <h2 className="text-[1.25vw] font-semibold mb-[1vw]">
              {editRow ? "Edit Turf" : "Add Turf"}
            </h2>
            <form>
              <div className="mb-[1vw]">
                <label className="block text-[1vw] font-medium text-gray-700">
                  Turf Number
                </label>
                <input
                  type="text"
                  name="turfNumber"
                  value={formData.turfNumber}
                  onChange={handleChange}
                  className="mt-[0.25vw] block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1vw]"
                />
              </div>
              <div className="mb-[1vw]">
                <label className="block text-[1vw] font-medium text-gray-700">
                  Size
                </label>
                <select
                  name="size"
                  value={formData.size}
                  onChange={handleChange}
                  className="mt-[0.25vw] block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1vw]"
                >
                  <option value="5v5">5v5</option>
                  <option value="7v7">7v7</option>
                  <option value="11v11">11v11</option>
                </select>
              </div>
              <div className="mb-[1vw]">
                <label className="block text-[1vw] font-medium text-gray-700">
                  Surface Type
                </label>
                <input
                  type="text"
                  name="surfaceType"
                  value={formData.surfaceType}
                  onChange={handleChange}
                  className="mt-[0.25vw] block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1vw]"
                />
              </div>
              <div className="mb-[1vw]">
                <label className="block text-[1vw] font-medium text-gray-700">
                  Pricing
                </label>
                <input
                  type="text"
                  name="pricing"
                  value={formData.pricing}
                  onChange={handleChange}
                  className="mt-[0.25vw] block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1vw]"
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={handleCloseForm}
                  className="bg-gray-500 px-[2vw] py-[0.5vw] mt-[1vw] text-white rounded-full w-fit shadow-md shadow-black/25 text-[1vw] md:text-[1.125vw]"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-[#4277FF] px-[2vw] py-[0.5vw] mt-[1vw] text-white rounded-full w-fit shadow-md shadow-black/25 text-[1vw] md:text-[1.125vw]"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TurfGrid;
