import React, { useEffect, useState } from "react";
import cricketBall from "../../assets/homepage/image 61.png";
import ball from "../../assets/homepage/image 63.png";
import tt from "../../assets/homepage/image 62.png";
import star from "../../assets/homepage/Star.png";
import starfilled from "../../assets/homepage/star-filled.png";
import { Link } from "react-router-dom";
import { fetchTurfById, updateTurfById } from "../../firebase/turfController";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const DiscoverCard = ({
  bookingId,
  turfId,
  title,
  address,
  image,
  time,
  date,
  isprevious = false,
  turfRated = 0,
  selectedTurf,
}) => {
  const [turf, setTurf] = useState({});
  const [currentTurfRatings, setCurrentTurfRatings] = useState(turfRated);
  const [ratedState, setRatedStated] = useState(false);

  useEffect(() => {
    const fetchTurf = async () => {
      const fetchedTurf = await fetchTurfById(turfId);
      setTurf(fetchedTurf);
    };
    if (turfId) {
      fetchTurf();
    }
  }, [turfId]);

  const updateTurfRatings = async (e) => {
    const newRating = parseInt(e.target.id);
    let ratings = turf?.ratings ? [...turf.ratings] : [0, 0];

    if (!currentTurfRatings) {
      ratings[0] += newRating;
      ratings[1] += 1;
      setCurrentTurfRatings(newRating);
    } else if (newRating !== currentTurfRatings) {
      ratings[0] -= currentTurfRatings;
      ratings[0] += newRating;
      setCurrentTurfRatings(newRating);
    } else {
      ratings[0] -= currentTurfRatings;
      ratings[1] -= 1;
      setCurrentTurfRatings(0);
    }

    await updateTurfById(turfId, { ...turf, ratings });

    const bookingRef = doc(db, "bookings", bookingId);
    await updateDoc(bookingRef, {
      rated: newRating === currentTurfRatings ? 0 : newRating,
    });
    setRatedStated(true);
  };

  return (
    <Link to={!isprevious && `/communityJoin/${bookingId}`}>
      <div className="flex flex-col-reverse md:flex-row gap-[2vw] w-[86.5vw] md:w-[69.25vw] md:h-[16.875vw] bg-white drop-shadow-[0_12px_12px_rgba(0,0,0,0.25)] rounded-[3.125vw] md:rounded-[1.875vw] md:pl-[4vw] md:p-[2vw] p-[3.125vw]">
        {/* Details */}
        <div className="flex-1 flex flex-col gap-[3.125vw] md:gap-[1.5vw]">
          <div className="flex justify-between items-center">
            <p className="font-poppins font-normal md:font-medium text-[4.325vw] md:text-[2.25vw] leading-[6.25vw] md:leading-[4vw] tracking-[0.08px] md:tracking-[0.24px]">
              {title}
            </p>
            <div className="flex gap-0 md:gap-[1vw] items-center">
              <div className="w-[10vw] h-[10vw] md:w-[1.5vw] md:h-[1.5vw] flex justify-end items-center">
                {ratedState ? (
                  <p className="md:text-[0.8vw] text-[3.25vw]">Rating saved!</p>
                ) : (
                  Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <img
                        key={index}
                        className="w-[5vw] h-[5vw] md:w-[1.25vw] md:h-[1.25vw] object-contain flex-shrink-0"
                        src={index < currentTurfRatings ? starfilled : star}
                        alt={`Star ${index + 1}`}
                        id={index + 1}
                        onClick={updateTurfRatings}
                      />
                    ))
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="font-poppins text-[3.75vw] md:text-[1.5vw] leading-[5vw] md:leading-[3vw] tracking-[0.2px] md:tracking-[0.6px]">
              {time}, {selectedTurf?.turfNumber}, {date}
            </p>
            <div className="flex gap-[1.25vw] md:gap-[0.625vw]">
              <div className="w-[2.5vw] h-[2.5vw] md:w-[1.5vw] md:h-[1.5vw] flex-shrink-0">
                <img
                  className="w-full h-full object-contain"
                  src={cricketBall}
                  alt=""
                />
              </div>
              <div className="w-[2.5vw] h-[2.5vw] md:w-[1.5vw] md:h-[1.5vw] flex-shrink-0">
                <img
                  className="w-full h-full object-contain"
                  src={ball}
                  alt=""
                />
              </div>
              <div className="w-[2.5vw] h-[2.5vw] md:w-[1.5vw] md:h-[1.5vw] flex-shrink-0">
                <img className="w-full h-full object-contain" src={tt} alt="" />
              </div>
            </div>
          </div>
          <div>
            <p className="font-poppins text-[3.75vw] md:text-[1.25vw] leading-[5vw] md:leading-[2.5vw] tracking-[0.2px] md:tracking-[0.6px] text-[#61646B]">
              {address}
            </p>
          </div>
        </div>
        {/* Image */}
        <div className="w-[80.25vw] h-[40.125vw] md:w-[19.125vw] md:h-[13.25vw] rounded-[0.75vw] overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={image}
            alt="Turf Image"
          />
        </div>
      </div>
    </Link>
  );
};

export default DiscoverCard;
