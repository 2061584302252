import React from "react";
import Footer from "../../components/Footer";

const Policy = () => {
  return (
    <div>
      <div className="container max-w-4xl mx-auto p-6 font-poppins text-justify mb-[20vw] md:mb-0">
        <h1 className="text-2xl font-bold text-center mb-8">Security Policy</h1>

        <section>
          <h2 className="text-xl font-semibold mb-4">
            1. Data Protection and Privacy
          </h2>

          <h3 className="text-lg font-semibold mt-4">
            1.1 Data Collection and Usage
          </h3>
          <p>
            We collect only necessary personal data for service provision. All
            data usage complies with our Privacy Policy and applicable data
            protection laws. Regular privacy impact assessments are conducted to
            ensure compliance.
          </p>

          <h3 className="text-lg font-semibold mt-4">
            1.2 Data Storage and Encryption
          </h3>
          <p>
            All user data is encrypted at rest using industry-standard
            encryption protocols. Sensitive data (e.g., payment information) is
            subject to additional encryption measures.
          </p>

          <h3 className="text-lg font-semibold mt-4">
            1.3 Data Access Controls
          </h3>
          <p>
            Employee access to user data is strictly limited on a need-to-know
            basis. All data access is logged and regularly audited.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            2. Network Security
          </h2>
          <p>
            Our network is protected by next-generation firewalls. Intrusion
            detection and prevention systems are in place to monitor for
            suspicious activities.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            3. Application Security
          </h2>

          <h3 className="text-lg font-semibold mt-4">
            3.1 Secure Development Practices
          </h3>
          <p>
            We follow secure coding guidelines and conduct regular code reviews.
            All code changes undergo security testing before deployment.
          </p>

          <h3 className="text-lg font-semibold mt-4">
            3.2 Authentication and Authorization
          </h3>
          <p>
            Multi-factor authentication is required for all user accounts.
            Role-based access control is implemented for system access.
          </p>

          <h3 className="text-lg font-semibold mt-4">3.3 API Security</h3>
          <p>
            All APIs use secure authentication methods. Rate limiting and
            monitoring are in place to prevent abuse.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            4. Incident Response
          </h2>

          <h3 className="text-lg font-semibold mt-4">
            4.1 Incident Detection and Reporting
          </h3>
          <p>A dedicated team monitors security incidents.</p>

          <h3 className="text-lg font-semibold mt-4">
            4.2 Data Breach Notification
          </h3>
          <p>Users will be notified of any data breaches as required by law.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            5. Employee Security
          </h2>

          <h3 className="text-lg font-semibold mt-4">5.1 Security Training</h3>
          <p>
            All employees undergo security awareness training upon hiring and
            annually thereafter. Regular phishing simulations are conducted to
            test employee vigilance.
          </p>

          <h3 className="text-lg font-semibold mt-4">5.2 Background Checks</h3>
          <p>
            All employees undergo background checks before hiring.
            Confidentiality agreements are required for all staff and
            contractors.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            6. Regulatory Compliance
          </h2>
          <p>
            We maintain compliance with relevant industry standards (e.g., RBI
            for payment processing). Regular internal audits are conducted to
            ensure policy compliance.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">7. Data Backups</h2>
          <p>
            User data is backed up daily, with backups stored in geographically
            separate locations. Backup restoration processes are tested
            quarterly.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            8. Policy Review and Updates
          </h2>
          <p>
            This security policy is reviewed and updated at least annually. All
            employees are notified of policy changes and required to acknowledge
            understanding.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;
