import React, { useState, useEffect } from "react";

function DayCarousel({ month, year, onSelectDay }) {
  const today = new Date();

  // Create state to track the visible start date (center of the week)
  const [currentDate, setCurrentDate] = useState(today);
  const [selectedDay, setSelectedDay] = useState(today);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Month to Int
  const monthToNumber = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  // Days
  const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

  // Function to format the date as Day and Date
  const formatDate = (date) => {
    return {
      day: days[date.getDay()],
      date: date.getDate(),
      fullDate: date,
    };
  };

  // Generate 9 dates (7 visible days + 2 buffer days)
  const getDisplayedDates = (start) => {
    const dates = [];
    const lastDayOfMonth = new Date(
      start.getFullYear(),
      start.getMonth() + 1,
      0
    ).getDate();
    const startDay = start.getDate();

    for (let i = -1; i <= 7 && startDay + i <= lastDayOfMonth; i++) {
      const newDate = new Date(start);
      newDate.setDate(start.getDate() + i);
      dates.push(formatDate(newDate));
    }

    return dates;
  };

  // Get the current set of displayed dates
  const displayedDates = getDisplayedDates(currentDate);

  // Check if we should disable the "next" arrow
  const isNextDisabled =
    displayedDates.length < 9 &&
    displayedDates[displayedDates.length - 1]?.date ===
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();

  // Check if we should disable the "previous" arrow
  const isPrevDisabled =
    displayedDates.some((day) => day.date === 1) &&
    displayedDates.some((day) => day.date === 30 || day.date === 31);

  // Handle transition for the next day
  const handleNextDay = () => {
    if (isTransitioning || isNextDisabled) return;
    setIsTransitioning(true);
    setTimeout(() => {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + 1);
      setCurrentDate(newDate);
      setIsTransitioning(false);
    }, 300);
  };

  // Handle transition for the previous day
  const handlePrevDay = () => {
    if (isTransitioning || isPrevDisabled) return;
    setIsTransitioning(true);
    setTimeout(() => {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() - 1);
      setCurrentDate(newDate);
      setIsTransitioning(false);
    }, 300);
  };

  const handleSelectDate = (day) => {
    const newDate = new Date(currentDate);
    newDate.setDate(day.date);
    setSelectedDay(newDate);
    onSelectDay(newDate.toISOString().split("T")[0]);
  };

  useEffect(() => {
    if (monthToNumber[month] === today.getMonth()) {
      setCurrentDate(today);
      setSelectedDay(today);
    } else {
      const newDate = new Date(currentDate);
      newDate.setDate(1);
      newDate.setMonth(monthToNumber[month]);
      setCurrentDate(newDate);
      if (year) {
        currentDate.setFullYear(parseInt(`20${year}`));
      }
    }
  }, [month, year]);

  const [vw, setVw] = useState();

  // Function to check and log the viewport width
  function checkViewportWidth() {
    if (vw !== window.innerWidth) {
      const viewportWidth = window.innerWidth;
      setVw(viewportWidth);
    }
  }

  checkViewportWidth();
  window.addEventListener("resize", checkViewportWidth);

  return (
    <div>
      <div className="select-none w-full flex gap-[1vw] justify-between items-center relative overflow-x-hidden">
        {/* Left Arrow */}
        <p
          className={`cursor-pointer ${
            isPrevDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handlePrevDay}
        >
          &#10094;
        </p>

        {/* Carousel Container */}
        <div className="overflow-x-hidden w-[90vw] md:w-[31.25vw] py-[5vw]">
          <div
            className="flex gap-[3.75vw] md:gap-[1.25vw] flex-1 "
            style={{
              transform: `translateX(-${vw > 768 ? 4.25 : 12.5}vw)`,
            }}
          >
            {displayedDates.map((day, index) => {
              const isPast =
                day.fullDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);

              return (
                <div
                  key={index}
                  className={`${
                    index === 1
                      ? "rounded-l-[1vw]"
                      : index ===
                        displayedDates.length -
                          (displayedDates.length === 9 ? 2 : 1)
                      ? "rounded-r-[1vw]"
                      : ""
                  } w-[11.25vw] h-[12.5vw] md:w-[3.25vw] md:h-[3.75vw] py-[0.625vw] flex flex-col flex-shrink-0 items-center justify-center drop-shadow-[1.5px_1.5px_4px_rgba(0,0,0,0.25)] ${
                    day.date === selectedDay.getDate() &&
                    day.day === days[selectedDay.getDay()]
                      ? "bg-[#41B7A3] text-white"
                      : day.date === today.getDate() &&
                        monthToNumber[month] === today.getMonth()
                      ? "bg-[#E7EEFF] border border-[#7199FF] text-black"
                      : "bg-white text-[#61646B]"
                  } ${
                    isPast ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (!isPast) handleSelectDate(day);
                  }}
                >
                  <p className="select-none text-[3.25vw] md:text-[1vw]">
                    {day.day}
                  </p>
                  <p className="select-none text-[3.25vw] md:text-[1vw]">
                    {day.date}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Right Arrow */}
        <p
          className={`select-none cursor-pointer ${
            isNextDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleNextDay}
        >
          &#10095;
        </p>
      </div>
    </div>
  );
}

export default DayCarousel;
