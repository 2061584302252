import React from "react";

const TurfRules = ({ rules }) => {
  return (
    <div className="px-[7.5vw] py-[6.25vw] md:px-[4.625vw] md:pt-[1.875vw] pb-[6.25vw] mx-0 md:mx-[1.875vw] border-solid border-2 rounded-[1vw] flex flex-col gap-[3.125vw]">
      <h2 className="text-[4.375vw] md:text-[2vw] font-medium font-poppins text-[#4277FF]">
        Venue Rules
      </h2>
      <ul className="list-disc list-inside flex flex-col gap-[5vw] md:gap-[1.875vw]">
        {rules.map((rule, index) => (
          <li
            key={index}
            className="text-[#61646B] text-[3.75vw] md:text-[1.25vw]"
          >
            {rule}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TurfRules;
