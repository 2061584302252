import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { fetchAllUsers } from "../../firebase/userContoller";
import { Link } from "react-router-dom";

const Users = () => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [searchParameter, setSearchParameter] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await fetchAllUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filterUsers = () => {
      if (searchUser === "" || searchParameter === "") {
        return users;
      }

      return users.filter((user) => {
        return user.data[searchParameter]
          .toLowerCase()
          .includes(searchUser.toLowerCase());
      });
    };

    if (users && users.length > 0) {
      const result = filterUsers();
      setFilteredUsers(result);
    }
  }, [users, searchUser, searchParameter]);

  return (
    <div className="w-full h-screen overflow-y-scroll">
      <div className=" flex items-center gap-3">
        <button
          onClick={() => {
            setSearchUser("");
            setSearchParameter("");
          }}
          className=" bg-zinc-700 text-lg pt-2 pb-1 px-3 rounded-md text-white font-light"
        >
          View all
        </button>

        <div className=" flex px-2 border border-black py-1 rounded-md gap-2">
          <span className=" pt-1">
            <CiSearch size={20} />
          </span>
          <input
            onChange={(e) => {
              setSearchUser(e.target.value);
            }}
            placeholder="Search Order"
            className="pt-1 focus:outline-none w-[15vw]"
          />
        </div>
        <button
          onClick={() => {
            setSearchParameter("name");
          }}
          className={`text-lg pt-2 pb-1 px-3 rounded-md ${
            searchParameter === "name"
              ? "bg-zinc-700 text-white"
              : "border-[1px] border-zinc-700"
          } font-light`}
        >
          Search by Name
        </button>
        <button
          onClick={() => {
            setSearchParameter("email");
          }}
          className={`text-lg pt-2 pb-1 px-3 rounded-md ${
            searchParameter === "email"
              ? "bg-zinc-700 text-white"
              : "border-[1px] border-zinc-700"
          } font-light`}
        >
          Search by Email
        </button>
      </div>
      <div className="my-4">
        <p className=" font-light text-xl">
          Results: Found {filteredUsers?.length} users{" "}
          {!(searchParameter === "" || searchUser === "") &&
            `having that ${searchParameter}`}
        </p>
      </div>
      <div className="mt-4">
        {filteredUsers?.map((user) => {
          return (
            <div className="flex gap-[1vw] mt-4  py-2 px-4 rounded-lg border shadow items-center  bg-zinc-200">
              <div key={user.id} className="flex justify-between w-full">
                <div className="">
                  <p className=" text-lg  ">
                    {user.data.name} ({user.data.role})
                  </p>
                  <span className=" flex gap-2 font-light">
                    <p>{user.data.email}</p>/
                    <p>{user.data.contact_info.phone}</p>
                  </span>
                </div>
                <Link target="blank" to={`/seeUserProfile/${user.id}`}>
                  <button className="bg-gray-700 text-white px-4 py-2 rounded-lg">
                    Visit Profile
                  </button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Users;
