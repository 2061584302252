import React, { useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import FeaturedSection from "./FeaturedSection";
import SearchVenues from "./SearchVenues";
import YourTurfs from "./YourTurfs";
import Discover from "./Discover";
import AboutUs from "./AboutUs";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { fetchAllBookings, fetchAllTurfs } from "../../firebase/turfController";

const Homepage = ({ role, handleToggle }) => {
  const user = useSelector((state) => state.auth.user);
  const [turfs, setTurfs] = useState([]);

  useEffect(() => {
    const fetchTurfs = async () => {
      try {
        const fetchedTurfs = await fetchAllTurfs();
        setTurfs(fetchedTurfs);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchTurfs();
  }, []);

  return turfs.length ? (
    <div>
      <HeroSection handleToggle={handleToggle} role={role} />
      <FeaturedSection turfs={turfs} />

      {user?.role === "Turf Owner" ? (
        <YourTurfs turfs={turfs} />
      ) : (
        <SearchVenues turfs={turfs} />
      )}
      {user?.role === "Player" && <Discover />}
      <AboutUs />
      <Footer />
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Homepage;
