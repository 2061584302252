import React, { useEffect, useState } from "react";
import buyImg from "../../assets/turfDetails/Buy.png";
import { calculateSlots } from "./calculateSlots";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { bookingSchema } from "../../firebase/schemas";
import { bookTurf } from "../../firebase/turfController";
import { Link } from "react-router-dom";
import Calender from "./Calender";

const BookingGrid = ({
  availability,
  slot_time,
  availableTurfs,
  turfId,
  turfName,
  turfAddress,
  turfImage,
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTurf, setSelectedTurf] = useState(availableTurfs[0]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [slots, setSlots] = useState([]);
  const [turfs, setTurfs] = useState(availableTurfs);
  const [makePayment, setMakePayment] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const handleAddToCart = () => {
    if (!user?.uid) return toast.error("You are currently logged out!");
    if (selectedDate && selectedTurf && selectedSlot) {
      // Logic to add booking to cart
      // console.log(`Booking: ${selectedDate}, ${selectedTurf}, ${selectedSlot}`);
      const newBooking = {
        ...bookingSchema,
        bookingDate: selectedDate,
        start_time: selectedSlot.slot_time.split(" - ")[0],
        end_time: selectedSlot.slot_time.split(" - ")[1],
        turfId,
        turfName,
        turfAddress,
        turfImage,
        selectedTurf,
        userId: user.uid,
        amountToBePaid: selectedTurf.pricing,
      };
      bookTurf(turfId, newBooking);
      toast.success("Added to cart. Proceed to make payment!");
      setMakePayment(true);
    } else {
      toast.error("Please select a date, turf, and time slot.");
    }
  };

  useEffect(() => {
    const fetchSlots = async () => {
      if (availability && slot_time) {
        const controller = new AbortController(); // Create an AbortController instance
        const signal = controller.signal; // Get the signal to pass to calculateSlots

        try {
          const calculatedSlots = await calculateSlots(
            selectedTurf,
            selectedDate,
            turfId,
            availability.from,
            availability.to,
            slot_time,
            { signal }
          );

          if (!signal.aborted) {
            // Check if the request was not aborted
            setSlots(calculatedSlots); // Update the state only if fetch was successful
          }
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Error calculating slots:", error);
          }
        }

        return () => controller.abort(); // Clean up and abort on dependency change
      }
    };

    fetchSlots();
  }, [
    turfId,
    availability,
    slot_time,
    availableTurfs,
    selectedDate,
    selectedTurf,
  ]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setSelectedDate(today);
  }, []);

  return (
    <div className="flex flex-col gap-[6.25vw] md:gap-[2.5vw] py-[6.25vw] md:py-[1.875vw] md:px-[1vw] md:border border-[#AFB1B6] md:rounded-xl ">
      <h2 className="block md:hidden text-[4.4vw] font-medium font-poppins ml-[3.75vw] text-[rgb(66,119,255)]">
        Book Your Slots
      </h2>
      <div className="w-[95%] md:w-full">
        <Calender onSelectDay={setSelectedDate} />
      </div>

      <div className="ml-[2vw] md:ml-[3.75vw] flex flex-col gap-[5vw] md:gap-[1vw]">
        <h3 className="text-[3.75vw] md:text-[1.25vw] font-semibold text-[#4277FF]">
          Available Turfs
        </h3>
        <div className="flex flex-wrap gap-2">
          {turfs?.map((turf, index) => (
            <div
              key={index}
              className={`cursor-pointer py-[1.875vw] px-[3.75vw] md:py-[0.5vw] md:px-[1vw] text-[3.75vw] md:text-[1vw] border border-[#D9D9D9] rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] ${
                selectedTurf === turf
                  ? "bg-[#41B7A3] text-white"
                  : "bg-[#fff] text-[#61646B]"
              }`}
              onClick={() => setSelectedTurf(turf)}
            >
              {turf.turfNumber}
            </div>
          ))}
        </div>
      </div>

      <div className="ml-[2vw] md:ml-[3.75vw] flex flex-col gap-[5vw] md:gap-[1vw] max-w-[92.5vw] md:max-w-none">
        <h3 className="text-[3.75vw] md:text-[1.25vw] font-semibold text-[#4277FF]">
          Available Slots
        </h3>
        <div className="flex flex-wrap md:gap-[0.5vw]">
          {slots.map((slot, index) => (
            <div
              key={index}
              className={`cursor-pointer m-[0.5vw] py-[1.875vw] px-[3.75vw] md:py-[0.5vw] md:px-[1vw] text-[3.75vw] md:text-[1vw] rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] border border-[#D9D9D9] ${
                selectedSlot === slot
                  ? "bg-[#41B7A3] text-white"
                  : slot.available
                  ? "bg-white text-[#61646B]"
                  : "bg-[#E8E8E8] text-[#19191B]"
              }`}
              onClick={() =>
                slot.available
                  ? setSelectedSlot(slot)
                  : toast.error("Slot already booked!")
              }
            >
              {slot.slot_time}
            </div>
          ))}
        </div>
      </div>

      <div className="flex self-center gap-[1vw]">
        <div
          className="cursor-pointer w-fit py-[1.875vw] px-[3.75vw] md:py-[0.5vw] md:px-[1vw] bg-[#2561C4] text-[3.75vw] md:text-[1vw] text-white flex gap-[1vw] rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]"
          onClick={handleAddToCart}
        >
          <div>Book Now</div>
        </div>
        {makePayment && (
          <Link to="/bookingDetails">
            <div className="cursor-pointer w-fit py-[1.875vw] px-[3.75vw] md:py-[0.5vw] md:px-[1vw] bg-[#41B7A3] text-[3.75vw] md:text-[1vw] text-white flex gap-[1vw] rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]">
              <div>Make Payment</div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default BookingGrid;
