// CustomArrow.js
import React from "react";

export const NextArrow = ({ onClick }) => (
  <div
    className="select-none absolute top-1/2 right-2 transform -translate-y-1/2 z-10 text-black text-[5vw] md:text-[1.5vw] cursor-pointer rounded-full"
    onClick={onClick}
  >
    &#10095;
  </div>
);

export const PrevArrow = ({ onClick }) => (
  <div
    className="select-none absolute top-1/2 left-2 transform -translate-y-1/2 z-10 text-black text-[5vw] md:text-[1.5vw] cursor-pointer rounded-full"
    onClick={onClick}
  >
    &#10094;
  </div>
);
