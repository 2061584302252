import React, { useState } from "react";
import { PrevArrow } from "./CustomArrow";
import { NextArrow } from "./CustomArrow";

function ImageSlider({ images }) {
  const [translateX, settranslateX] = useState(0);
  const [prevX, setPrevX] = useState(1);

  const [vw, setVw] = useState();
  // Function to check and log the viewport width
  function checkViewportWidth() {
    if (vw !== window.innerWidth) {
      const viewportWidth = window.innerWidth;
      setVw(viewportWidth);
    }
  }

  // Initial check when the script runs
  checkViewportWidth();

  // Add event listener to check the viewport width on window resize
  window.addEventListener("resize", checkViewportWidth);

  return (
    <div className="flex flex-col justify-center items-center relative md:gap-[3.125vw] gap-[5vw]">
      {/* IMAGE AND ARROWS */}
      <div className="md:px-[5vw] px-[9vw] relative">
        {/* PREVIOUS ARROW */}
        <PrevArrow
          onClick={() => {
            if (translateX > 0) {
              setPrevX(translateX);
              settranslateX(translateX - 1);
            }
          }}
        />
        {/* IMAGES */}
        <div className="flex md:w-[40vw] md:h-[25vw] md:gap-[12.5vw] w-[78.125vw] h-[56.25vw] gap-[21.875vw] md:rounded-[1.25vw] rounded-[2.5vw] overflow-hidden">
          {images.map((image, index) => (
            <img
              src={image}
              className="w-full h-full flex-shrink-0 object-cover md:rounded-[1.25vw] rounded-[2.5vw]"
              alt="Turf Images"
              style={{
                transform: `translateX(${
                  vw >= 768 ? translateX * -50 : translateX * -100
                }vw)`,
                "z-index": `${index}`,
                transition: `transform ${
                  0.3 * Math.abs(translateX - prevX)
                }s ease-in-out`,
              }}
            />
          ))}
        </div>
        {/* NEXT ARROW */}
        <NextArrow
          onClick={() => {
            if (translateX < images.length - 1) {
              setPrevX(translateX);
              settranslateX(translateX + 1);
            }
          }}
        />
      </div>
      {/* BUTTONS */}
      <div className="flex md:gap-[1vw] gap-[1.5625vw]">
        {images.map((image, index) => (
          <div
            onClick={() => {
              setPrevX(translateX);
              settranslateX(index);
            }}
            className="md:w-[1vw] md:h-[1vw] w-[1.875vw] h-[1.875vw] rounded-full"
            style={{
              backgroundColor: index === translateX ? "#1355FF" : "#A1BBFF",
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;
