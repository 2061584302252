import React, { useState } from "react";
import img from "../../assets/loginpage/Slice 3.png";
import SignIn from "./SignIn";
import CreateUser from "./CreateUser";

const Login = ({ setUser, handleToggle, role }) => {
  const [newUser, setNewUser] = useState(false);

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="relative lg:flex-1 hidden lg:block">
        <div className="imgs">
          <img className="absolute" src={img} alt="" />
        </div>
        <div
          className="heading absolute"
          style={{ top: "752px", left: "41px" }}
        >
          <h1
            className="font-bebas text-medium-heading-bebas mb-4 mt-7"
            style={{ fontSize: "4rem", color: "#27346A" }}
          >
            WELCOME TO TURFBRELLA!
          </h1>
          <p
            className="font-poppins text-medium-heading-poppins"
            style={{ fontSize: "2.5rem", color: "#AF4653" }}
          >
            The Ultimate Sports Platform
          </p>
        </div>
      </div>
      <div
        className="login-frm flex-1 flex justify-center items-center py-[15vw] md:py-[5vw]"
        style={{ background: "#E7EEFF" }}
      >
        {newUser ? (
          <CreateUser
            setNewUser={setNewUser}
            handleToggle={handleToggle}
            role={role}
            className="p-2"
          />
        ) : (
          <SignIn
            setNewUser={setNewUser}
            handleToggle={handleToggle}
            role={role}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
