import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import profileImage from "../../assets/profilepage/profileImage.png";
import { useSelector } from "react-redux";
import { updateUserProfile } from "../../firebase/userContoller";
import { toast } from "react-toastify";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"; // Import Firebase Storage

const UserProfile = () => {
  const user = useSelector((state) => state.auth.user);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.contact_info.phone);
  const [address, setAddress] = useState(user.contact_info.address);
  const [gender, setGender] = useState(user.gender);
  const [profilePicture, setProfilePicture] = useState(null);
  const [newPic, setNewPic] = useState(null);

  if (!user.uid) return <Navigate to="/login" />;

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleUploadProfilePicture = () => {
    if (!profilePicture) return;

    const storage = getStorage(); // Initialize Firebase Storage
    const storageRef = ref(storage, `profile_pictures/${user.uid}`); // Create a reference

    const uploadTask = uploadBytesResumable(storageRef, profilePicture);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Optional: Track the progress of the upload if needed
      },
      (error) => {
        // Handle errors here
        toast.error("Failed to upload image.");
      },
      () => {
        // Get the uploaded image's URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateUserProfile(user.uid, {
            ...user,
            profile_picture: downloadURL,
          }).then(() => {
            toast.success("Profile picture updated successfully!");
          });
          setNewPic(downloadURL);
        });
      }
    );
  };

  return (
    <div className="flex flex-col font-poppins px-[20px] pt-10 sm:pt-24 pb-28 gap-8 items-center">
      <p className="text-[4.325vw] md:text-[1vw] text-yellow-600">
        {user?.admin_message}
      </p>
      <div className="flex flex-col items-center gap-2">
        <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-1 lg:mb-4 text-[#27346A]">
          {user.role} Profile
        </h2>
        <div className="w-24 h-24 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex items-center justify-center rounded-full overflow-hidden border border-gray-200">
          <img
            src={user?.profile_picture || newPic || profileImage}
            alt="profile picture"
            className="w-full h-full object-cover"
          />
        </div>
        <p className="sm:text-lg">{user.name}</p>

        {/* Profile Picture Upload */}
        <input
          type="file"
          accept="image/*"
          onChange={handleProfilePictureChange}
          className="mt-4"
        />
        <button
          onClick={handleUploadProfilePicture}
          className="bg-[#41B7A3] px-6 py-1 mx-auto mt-3 text-white rounded-full w-fit shadow-md shadow-black/25 sm:text-base"
        >
          Upload Profile Picture
        </button>
      </div>

      <Link to="/bookingDetails">
        <p className="text-[3.75vw] md:text-[1vw] text-[#4277FF]">
          Update booking details
        </p>
      </Link>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (window.confirm("Are you sure you want to update your profile?")) {
            updateUserProfile(user.uid, {
              ...user,
              name,
              email,
              contact_info: { ...user.contact_info, phone, address },
              gender,
            }).then(() => {
              toast.success("Updated Successfully!");
            });
          }
        }}
        className="flex flex-col gap-4 w-[85%] sm:w-[70%] lg:w-[40%]"
      >
        <span className="flex flex-col gap-2">
          <label htmlFor="name" className="text-sm sm:text-lg text-[#4277FF]">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border-[#AFB1B6] border-[0.75px] rounded-md px-2 py-2 text-black"
          />
        </span>

        <span className="flex flex-col gap-2">
          <label
            htmlFor="email"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="border-[#AFB1B6] border-[0.75px] rounded-md px-2 py-2 text-black"
          />
        </span>
        <span className="flex flex-col gap-2">
          <label
            htmlFor="number"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            Phone number
          </label>
          <input
            type="tel"
            name="number"
            id="number"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            className="border-[#AFB1B6] border-[0.75px] rounded-md px-2 py-2 text-black"
          />
        </span>
        <span className="flex flex-col gap-2">
          <label
            htmlFor="address"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            Address
          </label>
          <input
            type="text"
            name="address"
            id="address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            className="border-[#AFB1B6] border-[0.75px] rounded-md px-2 py-2 text-black"
          />
        </span>
        <span className="flex flex-col gap-2">
          <label
            htmlFor="gender"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            Gender
          </label>
          <select
            name="gender"
            id="gender"
            className="border-[#AFB1B6] border-[0.75px] py-2 px-2 rounded-md"
            value={gender}
            onChange={(e) => {
              setGender(e.target.value);
            }}
          >
            <option value="gender">Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            <option value="undisclosed">Rather not say</option>
          </select>
        </span>

        <button
          type="submit"
          className="bg-[#41B7A3] px-8 py-2 mx-auto mt-4 text-white rounded-full w-fit shadow-md shadow-black/25 sm:text-lg"
        >
          Update Profile
        </button>
      </form>

      {user.role === "Turf Owner" && (
        <div className="flex md:flex-row flex-col justify-center items-center gap-[5vw] md:gap-[1vw]">
          <Link to={`/listYourTurf/${user.uid}`}>
            <button className="bg-[#4277FF] px-8 py-2 mx-auto mt-4 text-white rounded-full w-fit shadow-md shadow-black/25 sm:text-lg">
              List Turf
            </button>
          </Link>
          <Link to={`/myDashboard`}>
            <button className="bg-[#4277FF] px-8 py-2 mx-auto mt-4 text-white rounded-full w-fit shadow-md shadow-black/25 sm:text-lg">
              See Bookings for Your Turfs
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
