import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import loginImg from "../assets/homepage/Login.png";
import bookingImg from "../assets/homepage/Document.png";
import profileImg from "../assets/homepage/Profile.png";
import homeImg from "../assets/homepage/Home.png";
import { logout } from "../firebase/authController";

const BottomNavBar = ({ userId, isAuthenticated }) => {
  const location = useLocation();

  return (
    <div className="md:hidden fixed bottom-0 max-w-[100vw] w-full bg-[#4277FF] shadow-lg shadow-[#6B7280] text-white flex justify-around py-[2.5vw] z-40">
      <div
        className={`flex flex-col items-center ${
          location.pathname === "/" ? "font-bold" : ""
        }`}
      >
        <NavLink
          to="/"
          className="flex flex-col items-center justify-center text-center"
        >
          <img
            src={homeImg}
            className="w-[6.25vw] h-[6.25vw] object-contain"
            alt="Home"
          />
          <p className="mt-[1.25vw] text-[4.375vw]">Home</p>
        </NavLink>
      </div>
      <div
        className={`flex flex-col items-center ${
          location.pathname === "/myBookings" ? "font-bold" : ""
        }`}
      >
        <NavLink
          to={userId ? "/myBookings" : "/login"}
          className="flex flex-col items-center justify-center text-center"
        >
          <img
            src={bookingImg}
            className="w-[6.25vw] h-[6.25vw] object-contain"
            alt="My Bookings"
          />
          <p className="mt-[1.25vw] text-[4.375vw]">My Bookings</p>
        </NavLink>
      </div>
      <div
        className={`flex flex-col items-center ${
          location.pathname === `/userProfile/${userId}` ? "font-bold" : ""
        }`}
      >
        <NavLink
          to={userId ? `/userProfile/${userId}` : "/login"}
          className="flex flex-col items-center justify-center text-center"
        >
          <img
            src={profileImg}
            className="w-[6.25vw] h-[6.25vw] object-contain"
            alt="Profile"
          />
          <p className="mt-[1.25vw] text-[4.375vw]">Profile</p>
        </NavLink>
      </div>
      <div
        className={`flex flex-col items-center ${
          location.pathname === "/login" ? "font-bold" : ""
        }`}
      >
        {userId ? (
          <NavLink
            to="/login"
            onClick={() => {
              logout();
            }}
            className="flex flex-col items-center justify-center text-center"
          >
            <img
              src={loginImg}
              className="w-[6.25vw] h-[6.25vw] object-contain"
              alt="Login"
            />
            <p className="mt-[1.25vw] text-[4.375vw]">Logout</p>
          </NavLink>
        ) : (
          <NavLink
            to="/login"
            className="flex flex-col items-center justify-center text-center"
          >
            <img
              src={loginImg}
              className="w-[6.25vw] h-[6.25vw] object-contain"
              alt="Login"
            />
            <p className="mt-[1.25vw] text-[4.375vw]">Login</p>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default BottomNavBar;
