import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer";
import JoinTheAction from "./JoinTheAction";
import image from "../../assets/homepage/image 41.png"; // Example image
import { useSelector } from "react-redux";
import { fetchUserBookings } from "../../firebase/userContoller";
import BookingCard from "./BookingCard";

const BookingDetails = () => {
  const user = useSelector((state) => state.auth.user);
  const [bookings, setBookings] = useState([]);
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (user.uid) {
      fetchUserBookings(user.uid)
        .then((bookings) => {
          // console.log("User bookings:", bookings);
          setBookings(bookings);
        })
        .catch((error) => {
          console.error("Failed to fetch user bookings:", error);
        });
    }
  }, [user]);

  if (!user) return <Navigate to="/login" />;

  return (
    <div>
      <div className="flex flex-col items-center p-6 sm:mt-16">
        <h1 className="text-3xl font-bold mb-6">Turf Booking Details</h1>
        <div className="flex flex-col gap-4">
          {bookings.map(
            (booking) =>
              booking.status === "ongoing" &&
              booking.bookingDate >= today && (
                <BookingCard bookingDetails={booking} />
              )
          )}
        </div>
        <div className="w-full">
          <JoinTheAction bookings={bookings} />
        </div>
      </div>
      <div className="hidden sm:block sm:mt-16">
        <Footer />
      </div>
    </div>
  );
};

export default BookingDetails;
