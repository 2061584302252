import React, { useEffect, useState } from "react";
import cricketBall from "../../assets/homepage/image 61.png";
import ball from "../../assets/homepage/image 63.png";
import tt from "../../assets/homepage/image 62.png";
import star from "../../assets/homepage/Star.png";
import { Link } from "react-router-dom";
import { fetchTurfById } from "../../firebase/turfController";
import { fetchUserProfile } from "../../firebase/userContoller";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { toast, ToastContainer } from "react-toastify";

const TurfCard = ({
  turfId,
  title,
  address,
  image,
  time,
  date,
  horizontal = false,
  isDiscover = false,
  editable = false,
  ratings,
  ownerId,
  verified,
}) => {
  const [turfDetails, setTurfDetails] = useState(null);

  useEffect(() => {
    // console.log(ownerId);
    const fetchUserById = async () => {
      const user = await fetchUserProfile(ownerId);
    };

    fetchUserById();
  }, [ownerId]);

  const requestVerification = async () => {
    if (verified) return toast.error("This turf is already marked verified!");
    try {
      // Reference to the user document in the 'users' collection
      const userRef = doc(db, "users", ownerId);

      // Update the user document by adding the 'admin_message' field
      await updateDoc(userRef, {
        admin_message: `Verification request for ${title}. Please mail documents to website.turfdogs@gmail.com`,
      });

      toast.success("Request sent!");

      console.log(`Admin message added to user ${ownerId}`);
    } catch (error) {
      console.error("Error updating user document:", error);
    }
  };

  const markVerified = async () => {
    const turfRef = doc(db, "turfDetails", turfId);
    //
    await updateDoc(turfRef, {
      verified: !verified,
    });

    const userRef = doc(db, "users", ownerId);
    await updateDoc(userRef, {
      admin_message: "",
    });

    toast.success("Turf Verified!");
  };

  return (
    <div
      className={`card ${horizontal ? "flex flex-row-reverse" : "block"} ${
        isDiscover ? "bg-white" : "bg-[#E7EEFF]"
      } overflow-hidden shadow-md shadow-[#6B7280] rounded-[3.625vw] md:rounded-[0.75vw] m-[2.5vw] md:m-[0.25vw]`}
      style={{
        width: "100%",
        maxWidth: "69.125vw",
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
      <div
        className={`card-image-container rounded-[1.25vw] md:rounded-[1vw] overflow-hidden ${
          horizontal
            ? "w-1/3 h-[37.5vw] md:h-[14.0625vw]"
            : "flex justify-center items-center h-[35vw] md:h-[12.5vw]"
        } p-[2.5vw] md:p-[0.5vw]`}
        style={{ overflow: "hidden" }}
      >
        <img
          src={image}
          alt={title}
          className={`card-image rounded-[1vw] ${
            horizontal
              ? "object-cover h-full w-full"
              : "object-cover w-full h-full"
          }`}
          style={{ objectFit: "cover" }}
        />
      </div>
      <div
        className={`card-content px-[2.5vw] md:px-[0.5vw] font-poppins ${
          horizontal ? "w-2/3" : ""
        } ${
          isDiscover
            ? "p-[2.5vw] md:p-[0.5vw]"
            : " p-[5vw] sm:p-[1vw] md:p-[1.5vw]"
        } ${
          isDiscover ? "bg-white" : "bg-[#E7EEFF]"
        } text-left rounded-[0.75vw]`}
      >
        <h2 className="card-title font-bold text-[4.325vw] md:text-[1.25vw] font-poppins">
          {title}
        </h2>
        <div className="flex justify-between mt-[2.5vw] md:mt-[0.5vw]">
          <div className="flex gap-[1.25vw] md:gap-[0.5vw]">
            <img
              src={cricketBall}
              alt="Cricket Ball"
              className="w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
            />
            <img
              src={ball}
              alt="Ball"
              className="w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
            />
            <img
              src={tt}
              alt="TT"
              className="w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
            />
          </div>
          <div className="flex items-center">
            <img
              src={star}
              alt="Star"
              className="mr-[0.25vw] w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
            />
            <p className="text-[3.75vw] md:text-[1vw]">
              {ratings[1] > 0 ? (ratings[0] / ratings[1]).toFixed(1) : 0}(
              {ratings[1]})
            </p>
          </div>
        </div>
        <div className="flex gap-[0.5vw] sm:gap-[0.75vw] items-center m-[2.5vw] md:m-[1vw]">
          <p className="text-[3.75vw] md:text-[1vw]">{date}</p>
          <p className="text-[3.75vw] md:text-[1vw]">{time}</p>
        </div>
        <p className="text-[3.75vw] md:text-[1vw] m-[0.5vw] sm:m-[1vw]">
          {address}
        </p>
        <div className="flex gap-[0.5vw] justify-between">
          <Link
            target="blank"
            to={!editable ? `/turfDetails/${turfId}` : `/editTurf/${turfId}`}
            className="w-full flex-1"
          >
            <button className="bg-gray-700 px-[1vw] py-[0.5vw] text-white rounded-md text-[1vw]">
              Check Turf
            </button>
          </Link>
          <button
            onClick={requestVerification}
            className="w-full flex-1 border border-gray-700 px-[1vw] py-[0.5vw] rounded-md text-[1vw]"
          >
            Request Verification
          </button>
        </div>
        {verified ? (
          <div
            onClick={markVerified}
            className="flex justify-center items-center bg-red-500 mt-[0.5vw] text-white rounded-md py-[0.5vw] text-[1vw]"
          >
            Mark unverified
          </div>
        ) : (
          <div
            onClick={markVerified}
            className="flex justify-center items-center bg-green-700 mt-[0.5vw] text-white rounded-md py-[0.5vw] text-[1vw]"
          >
            Mark verified
          </div>
        )}
      </div>
    </div>
  );
};

export default TurfCard;
