import React, { useState, useEffect } from "react";
import downarrow from "../../assets/turfDetails/Arrow - Down 2.png";
import { NextArrow, PrevArrow } from "./CustomArrow";
import DayCarousel from "./DayCarousel";

function Calender({ onSelectDay }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState(""); // Default selected value
  const [monthYearOptions, setMonthYearOptions] = useState([]);

  useEffect(() => {
    // Generate month-year options starting from the current month
    const generateMonthYearOptions = () => {
      const options = [];
      const currentDate = new Date();
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      for (let i = 0; i < 12; i++) {
        const futureDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + i
        );
        const month = monthNames[futureDate.getMonth()];
        const year = futureDate.getFullYear() - 2000;
        options.push(`${month} ${year}`);
      }

      setMonthYearOptions(options);
      setSelectedMonthYear(options[0]); // Set the default to the current month and year
    };

    generateMonthYearOptions();
  }, []);

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Handle selecting a month-year option
  const handleSelect = (option) => {
    setSelectedMonthYear(option);
    setShowDropdown(false);
  };

  return (
    <div className="flex flex-col gap-[1.5vw] items-center">
      {/* SELECT DAY */}
      <div className="relative w-fit">
        {/* SELECT DAY BUTTON */}
        <div
          onClick={toggleDropdown}
          className="w-fit px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.625vw] flex gap-[0.875vw] justify-center items-center bg-[#E7EEFF] rounded-full cursor-pointer"
        >
          <p className="text-[3.25vw] md:text-[1vw] font-poppins w-fit">
            {selectedMonthYear}
          </p>
          <img
            src={downarrow}
            className={`w-[3.625vw] h-[3.625vw] md:w-[1.25vw] md:h-[1.25vw] transform transition-transform ${
              showDropdown ? "rotate-180" : ""
            }`}
            alt="dropdown"
          />
        </div>

        {/* DROPDOWN */}
        {showDropdown && (
          <ul className="absolute mt-[0.5vw] w-full bg-white border border-gray-300 rounded-[2.5vw] md:rounded-[0.5vw] shadow-lg max-h-[200px] overflow-auto z-10">
            {monthYearOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)}
                className="px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] cursor-pointer hover:bg-gray-100"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* DISPLAY THE WEEK */}
      <DayCarousel
        month={selectedMonthYear.split(" ")[0]}
        year={selectedMonthYear.split(" ")[1]}
        onSelectDay={onSelectDay}
      />
    </div>
  );
}

export default Calender;
