import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";

/**
 * Upload files to Firebase Storage and return their download URLs.
 * @param {File[]} files - Array of File objects to upload.
 * @return {Promise<string[]>} - Promise that resolves to an array of download URLs.
 */
const uploadFiles = async (files) => {
  try {
    const uploadPromises = files.map(async (file) => {
      const fileRef = ref(storage, `uploads/${file.name}`); // Create a reference to the file
      await uploadBytes(fileRef, file); // Upload the file
      const url = await getDownloadURL(fileRef); // Get the download URL
      return url;
    });

    const urls = await Promise.all(uploadPromises); // Wait for all uploads to complete
    return urls;
  } catch (error) {
    console.error("Error uploading files:", error.message);
    throw new Error("File upload failed");
  }
};

export default uploadFiles;
