import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import profileImage from "../../assets/profilepage/profileImage.png";
import { useSelector } from "react-redux";
import {
  fetchUserProfile,
  updateUserProfile,
} from "../../firebase/userContoller";
import { toast } from "react-toastify";

const SeeUserProfile = () => {
  const userId = useParams().userId;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [profilePicture, setProfilePicture] = useState("");

  useEffect(() => {
    if (userId) {
      fetchUserProfile(userId).then((user) => {
        setName(user.name);
        setEmail(user.email);
        setPhone(user.phone);
        setAddress(user.address);
        setGender(user.gender);
        setRole(user.role);
        setProfilePicture(user.profile_picture);
      });
    }
  }, [userId]);

  return (
    <div className="flex flex-col font-poppins px-[20px] pt-10 sm:pt-24 pb-28 gap-8 items-center">
      <div className="flex flex-col items-center gap-2">
        <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-1 lg:mb-4 text-[#27346A]">
          {role} Profile
        </h2>
        <img
          src={profilePicture || profileImage}
          alt="profile picture"
          className="w-24 sm:w-32 lg:w-40"
        />
        <p className="sm:text-lg">{name}</p>
      </div>

      <form
        action=""
        className="flex flex-col gap-4 w-[85%] sm:w-[70%] lg:w-[40%]"
      >
        <span className="flex flex-col gap-2">
          <label
            htmlFor="name"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            disabled
            className="border-[#AFB1B6] border-[0.75px] rounded-md px-2 py-2 text-black"
          />
        </span>
        <span className="flex flex-col gap-2">
          <label
            htmlFor="email"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            disabled
            className="border-[#AFB1B6] border-[0.75px] rounded-md px-2 py-2 text-black"
          />
        </span>
        <span className="flex flex-col gap-2">
          <label
            htmlFor="number"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            phone number
          </label>
          <input
            type="tel"
            name="number"
            id="number"
            value={phone}
            disabled
            className="border-[#AFB1B6] border-[0.75px] rounded-md px-2 py-2 text-black"
          />
        </span>
        <span className="flex flex-col gap-2">
          <label
            htmlFor="address"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            address
          </label>
          <select
            name="address"
            id="address"
            className="border-[#AFB1B6] border-[0.75px] py-2 px-2 rounded-md"
            value={address}
            disabled
          >
            <option value="address">Address</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Hyderabad">Hyderabad</option>
          </select>
        </span>
        <span className="flex flex-col gap-2">
          <label
            htmlFor="gender"
            className="text-sm sm:text-lg text-[#4277FF] font-"
          >
            Gender
          </label>
          <select
            name="gender"
            id="gender"
            className="border-[#AFB1B6] border-[0.75px] py-2 px-2 rounded-md"
            value={gender}
            disabled
          >
            <option value="gender">Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            <option value="undisclosed">Rather not say</option>
          </select>
        </span>
      </form>
    </div>
  );
};

export default SeeUserProfile;
