import React from "react";
import Footer from "../../components/Footer";

const FAQPage = () => {
  return (
    <div>
      <div className="max-w-4xl mx-auto p-6 mb-[20vw] md:mb-0">
        <h1 className="text-2xl font-bold mb-8 text-center">
          Frequently Asked Questions
        </h1>

        {/* General Questions */}
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">General Questions</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              How do I create an account?
            </h3>
            <p>
              Click on "Sign Up" and fill out the registration form with your
              details to create an account.
            </p>
          </div>
        </section>

        {/* Booking */}
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">Booking</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              How do I book a turf field?
            </h3>
            <p>
              Search for available fields, select your preferred date and time,
              and follow the booking process to confirm your booking.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              Can I book multiple fields at once?
            </h3>
            <p>
              Yes, you can book multiple fields at the same time, subject to
              availability.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              What is the booking cancellation policy?
            </h3>
            <p>
              Please refer to our cancellation policy, which varies depending on
              the facility and booking type.
            </p>
          </div>
        </section>

        {/* Payment */}
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">Payment</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              What payment methods are accepted?
            </h3>
            <p>We accept credit/debit cards, UPI, and bank transfers.</p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              Is my payment information secure?
            </h3>
            <p>
              Absolutely! Our platform uses reputed payment integration like
              razorpay to ensure secure transactions.
            </p>
          </div>
        </section>

        {/* Facilities */}
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">Facilities</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              What facilities are available?
            </h3>
            <p>Our platform offers only football turf bookings for now.</p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              Can I create my own events?
            </h3>
            <p>
              Yes, you can create events by going to the "Events" section,
              clicking on "Create Event," and providing the necessary details
              like date, time, location, and participants.
            </p>
          </div>
        </section>

        {/* Refunds and Cancellations */}
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">Refunds and Cancellations</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              Can I get a refund if I cancel my booking?
            </h3>
            <p>
              Refunds are subject to our cancellation policy and facility terms.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              How do I cancel my booking?
            </h3>
            <p>
              Log in to your account, go to your booking dashboard, and click on
              "Cancel Booking".
            </p>
          </div>
        </section>

        {/* Miscallaneous */}
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">Miscellaneous</h2>
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              How do I contact the facility or support team?
            </h3>
            <p>
              You can contact us through our website, email, or phone number
              provided on the platform.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FAQPage;
