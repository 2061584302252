const TurfDisplay = ({ turfs }) => {
  return (
    <div className="container max-w-[90vw] md:max-w-none flex flex-col gap-[3vw] md:gap-[1vw]">
      <div className="grid grid-cols-4 justify-items-center md:gap-[1vw] font-semibold py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[#61646B] border border-[#D9D9D9] bg-gray-200 rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]">
        <div className="text-[3.5vw] md:text-[1vw]">Turf Number</div>
        <div className="text-[3.5vw] md:text-[1vw]">Size</div>
        <div className="text-[3.5vw] md:text-[1vw]">Surface Type</div>
        <div className="text-[3.5vw] md:text-[1vw]">Pricing</div>
      </div>

      {turfs.map((turf, index) => (
        <div
          key={index}
          className="grid grid-cols-4 justify-items-center md:gap-[1vw] py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[#61646B] border border-[#D9D9D9] bg-white rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]"
        >
          <div className="text-[3.5vw] md:text-[1vw]">{turf.turfNumber}</div>
          <div className="text-[3.5vw] md:text-[1vw]">{turf.size}</div>
          <div className="text-[3.5vw] md:text-[1vw]">{turf.surfaceType}</div>
          <div className="text-[3.5vw] md:text-[1vw]">₹ {turf.pricing}</div>
        </div>
      ))}
    </div>
  );
};

export default TurfDisplay;
