import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, db } from "./firebase";
import { useDispatch } from "react-redux";
import { setUser } from "../app/features/authSlice";

const useAuthState = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userLogged) => {
      if (userLogged) {
        try {
          const q = query(
            collection(db, "users"),
            where("uid", "==", userLogged.uid)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach((document) => {
              const userData = document.data();

              // Convert Firestore timestamp to a serializable format
              if (userData.timestamp) {
                userData.timestamp = {
                  seconds: userData.timestamp.seconds,
                  nanoseconds: userData.timestamp.nanoseconds,
                  toDate: new Date(
                    userData.timestamp.seconds * 1000 +
                      userData.timestamp.nanoseconds / 1000000
                  ).toISOString(),
                };
              }
              dispatch(setUser(userData));
            });
          } else {
            console.log("No such user found in Firestore.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      } else {
        dispatch(setUser(null));
      }

      setLoading(false); // Set loading to false after checking auth state
    });

    return () => unsubscribe();
  }, [dispatch]);

  return loading; // Return loading state to control UI rendering
};

export default useAuthState;
