import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { fetchAllTurfs } from "../../firebase/turfController";
import TurfCard from "../components/TurfCard";

const Turfs = () => {
  const [filteredTurfs, setFilteredTurfs] = useState([]);
  const [searchTurf, setSearchTurf] = useState("");
  const [searchParameter, setSearchParameter] = useState("");
  const [turfs, setTurfs] = useState([]);

  useEffect(() => {
    const fetchTurfs = async () => {
      try {
        const fetchedTurfs = await fetchAllTurfs();
        setTurfs(fetchedTurfs);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchTurfs();
  }, []);

  useEffect(() => {
    const filterTurfs = () => {
      if (searchTurf === "" || searchParameter === "") {
        return turfs;
      }

      return turfs.filter((turf) => {
        return turf[searchParameter]
          .toLowerCase()
          .includes(searchTurf.toLowerCase());
      });
    };

    if (turfs && turfs.length > 0) {
      const result = filterTurfs();
      setFilteredTurfs(result);
    }
  }, [turfs, searchTurf, searchParameter]);

  return (
    <div className="w-full h-screen overflow-y-scroll">
      <div className=" flex items-center gap-3">
        <button
          onClick={() => {
            setSearchTurf("");
            setSearchParameter("");
          }}
          className=" bg-zinc-700 text-lg pt-2 pb-1 px-3 rounded-md text-white font-light"
        >
          View all
        </button>

        <div className=" flex px-2 border border-black py-1 rounded-md gap-2">
          <span className=" pt-1">
            <CiSearch size={20} />
          </span>
          <input
            onChange={(e) => {
              setSearchTurf(e.target.value);
            }}
            placeholder="Search Turf"
            className="pt-1 focus:outline-none w-[15vw]"
          />
        </div>
        <button
          onClick={() => {
            setSearchParameter("name");
          }}
          className={`text-lg pt-2 pb-1 px-3 rounded-md ${
            searchParameter === "name"
              ? "bg-zinc-700 text-white"
              : "border-[1px] border-zinc-700"
          } font-light`}
        >
          Search by Name
        </button>
        <button
          onClick={() => {
            setSearchParameter("email");
          }}
          className={`text-lg pt-2 pb-1 px-3 rounded-md ${
            searchParameter === "email"
              ? "bg-zinc-700 text-white"
              : "border-[1px] border-zinc-700"
          } font-light`}
        >
          Search by Email
        </button>
      </div>
      <div className="my-4">
        <p className=" font-light text-xl">
          Results: Found {filteredTurfs?.length} Turfs{" "}
          {!(searchParameter === "" || searchTurf === "") &&
            `having that ${searchParameter}`}
        </p>
      </div>
      <div className="mt-4 grid grid-cols-3 gap-8">
        {filteredTurfs?.map((card, index) => {
          return (
            <TurfCard
              key={index}
              turfId={card.turf_id}
              title={card.name}
              address={card.address}
              image={card.images[0]}
              ratings={card.ratings}
              ownerId={card.owner_id}
              verified={card.verified}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Turfs;
