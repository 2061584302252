import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firebase";

export const fetchAllUsers = async () => {
  try {
    const usersCollectionRef = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollectionRef);

    const users = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    return users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Failed to fetch users. Please try again later.");
  }
};

// Fetch user profile by ID
export const fetchUserProfile = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() };
    } else {
      console.log("User not found.");
    }
  } catch (error) {
    console.error("Error fetching user profile:", error.message);
  }
};

// Update user profile by ID
export const updateUserProfile = async (userId, updatedData) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      ...updatedData,
      updatedAt: serverTimestamp(),
    });
    console.log("User profile updated successfully.");
  } catch (error) {
    console.error("Error updating user profile:", error.message);
  }
};

// Add booking to user profile
export const addUserBooking = async (userId, bookingDetails) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      bookings: arrayUnion(bookingDetails),
      updated_at: serverTimestamp(),
    });
    console.log("Booking added to user profile.");
  } catch (error) {
    console.error("Error adding booking to user profile:", error.message);
  }
};

// Fetch user teams
export const fetchUserTeams = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.teams || [];
    } else {
      console.log("User not found.");
    }
  } catch (error) {
    console.error("Error fetching user teams:", error.message);
  }
};

// Add team to user profile
export const addUserTeam = async (userId, teamDetails) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      teams: arrayUnion(teamDetails),
      updated_at: serverTimestamp(),
    });
    console.log("Team added to user profile.");
  } catch (error) {
    console.error("Error adding team to user profile:", error.message);
  }
};

// Fetch user notifications
export const fetchUserNotifications = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.notifications || [];
    } else {
      console.log("User not found.");
    }
  } catch (error) {
    console.error("Error fetching user notifications:", error.message);
  }
};

// Delete user profile
export const deleteUserProfile = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await deleteDoc(userDocRef);
    console.log("User profile deleted successfully.");
  } catch (error) {
    console.error("Error deleting user profile:", error.message);
  }
};

// Fetch user's favorite turfs
export const fetchUserFavoriteTurfs = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.favorite_turfs || [];
    } else {
      console.log("User not found.");
    }
  } catch (error) {
    console.error("Error fetching user's favorite turfs:", error.message);
  }
};

// Add favorite turf to user profile
export const addUserFavoriteTurf = async (userId, turfId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      favorite_turfs: arrayUnion(turfId),
      updated_at: serverTimestamp(),
    });
    console.log("Turf added to favorites.");
  } catch (error) {
    console.error("Error adding turf to favorites:", error.message);
  }
};

// Remove favorite turf from user profile
export const removeUserFavoriteTurf = async (userId, turfId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      favorite_turfs: arrayRemove(turfId),
      updated_at: serverTimestamp(),
    });
    console.log("Turf removed from favorites.");
  } catch (error) {
    console.error("Error removing turf from favorites:", error.message);
  }
};

// Fetch user's event participation
export const fetchUserEventParticipation = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.events || [];
    } else {
      console.log("User not found.");
    }
  } catch (error) {
    console.error("Error fetching user's event participation:", error.message);
  }
};

// Update user preferences
export const updateUserPreferences = async (userId, preferences) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      preferences: preferences,
      updated_at: serverTimestamp(),
    });
    console.log("User preferences updated successfully.");
  } catch (error) {
    console.error("Error updating user preferences:", error.message);
  }
};

// Add user feedback
export const addUserFeedback = async (userId, feedback) => {
  try {
    const feedbackRef = collection(db, "feedback");
    await addDoc(feedbackRef, {
      user_id: userId,
      feedback: feedback,
      created_at: serverTimestamp(),
    });
    console.log("User feedback submitted successfully.");
  } catch (error) {
    console.error("Error submitting user feedback:", error.message);
  }
};

export const fetchUserBookings = async (userId) => {
  try {
    // Define a reference to the bookings collection
    const bookingsRef = collection(db, "bookings");

    // Create a query to fetch bookings where the userId matches the given user ID
    const q = query(bookingsRef, where("userId", "==", userId));

    // Execute the query and get the snapshot of results
    const querySnapshot = await getDocs(q);

    // Process the results and return an array of booking documents
    const bookings = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return bookings;
  } catch (error) {
    console.error("Error fetching user bookings:", error.message);
    throw error;
  }
};
