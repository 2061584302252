import React from "react";
import cricketBall from "../../assets/homepage/image 61.png";
import ball from "../../assets/homepage/image 63.png";
import tt from "../../assets/homepage/image 62.png";
import star from "../../assets/homepage/Star.png";
import { Link } from "react-router-dom";

const BookingCard = ({
  bookingId,
  title,
  address,
  image,
  time,
  date,
  selectedTurf,
  turfOwnerPayOut,
}) => {
  return (
    <Link to={`/seeBookingDetails/${bookingId}`}>
      <div className="flex flex-col-reverse md:flex-row gap-[2vw] w-[86.5vw] md:w-[69.25vw] md:h-[19vw] bg-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[3.125vw] md:rounded-[1.875vw] md:pl-[4vw] md:p-[2vw] p-[3.125vw]">
        {/* Details */}
        <div className="flex-1 flex flex-col gap-[3.125vw] md:gap-[1vw]">
          {turfOwnerPayOut && <p className="text-green-500">Turf Owner paid</p>}
          <div className="flex justify-between items-center">
            <p className="font-poppins font-normal md:font-medium text-[4.325vw] md:text-[2.25vw] leading-[6.25vw] md:leading-[4vw] tracking-[0.08px] md:tracking-[0.24px]">
              {title}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="font-poppins text-[3.75vw] md:text-[1.5vw] leading-[5vw] md:leading-[3vw] tracking-[0.2px] md:tracking-[0.6px]">
              {time}, {selectedTurf?.turfNumber}, {date}
            </p>
            <div className="flex gap-[1.25vw] md:gap-[0.625vw]">
              <div className="w-[2.5vw] h-[2.5vw] md:w-[1.5vw] md:h-[1.5vw] flex-shrink-0">
                <img
                  className="w-full h-full object-contain"
                  src={cricketBall}
                  alt=""
                />
              </div>
              <div className="w-[2.5vw] h-[2.5vw] md:w-[1.5vw] md:h-[1.5vw] flex-shrink-0">
                <img
                  className="w-full h-full object-contain"
                  src={ball}
                  alt=""
                />
              </div>
              <div className="w-[2.5vw] h-[2.5vw] md:w-[1.5vw] md:h-[1.5vw] flex-shrink-0">
                <img className="w-full h-full object-contain" src={tt} alt="" />
              </div>
            </div>
          </div>
          <div>
            <p className="font-poppins text-[3.75vw] md:text-[1.25vw] leading-[5vw] md:leading-[2.5vw] tracking-[0.2px] md:tracking-[0.6px] text-[#61646B]">
              {address}
            </p>
          </div>
        </div>
        {/* Image */}
        <div className="w-[80.25vw] h-[40.125vw] md:w-[19.125vw] md:h-[13.25vw] rounded-[0.75vw] overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={image}
            alt="Turf Image"
          />
        </div>
      </div>
    </Link>
  );
};

export default BookingCard;
