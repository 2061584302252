// src/features/turfSlice.js
import { createSlice } from "@reduxjs/toolkit";

const turfSlice = createSlice({
  name: "turf",
  initialState: {
    turfs: [],
    selectedTurf: null,
  },
  reducers: {
    setTurfs: (state, action) => {
      state.turfs = action.payload;
    },
    setSelectedTurf: (state, action) => {
      state.selectedTurf = action.payload;
    },
    clearSelectedTurf: (state) => {
      state.selectedTurf = null;
    },
  },
});

export const { setTurfs, setSelectedTurf, clearSelectedTurf } =
  turfSlice.actions;
export default turfSlice.reducer;
