import React from "react";

const AboutUs = () => {
  return (
    <div className="mt-[10vw] md:my-[10vw] font-normal text-[4.325vw] md:text-[1.25vw] leading-[7.5vw] md:leading-[2vw] tracking-[0.08vw] md:tracking-[0.6px] px-[5vw] md:px-[11.5vw]">
      About Us: Our vision at Turfbrella is to revolutionize the way sports
      enthusiasts book and participate in sports facilities, making it easier,
      more convenient, and more enjoyable for players of all skill levels to
      find and book the perfect sports facility for their needs. The mission of
      Turfbrella is to provide a user-friendly platform for players to easily
      discover and book sports facilities by having our own sports community,
      that not only makes the booking process easier but also allows players to
      connect with each other for friendly matches or even team formation.
    </div>
  );
};

export default AboutUs;
