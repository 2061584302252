export const playerSchema = {
  player_id: "",
  name: "",
  username: "",
  email: "",
  password: "",
  dob: "",
  type: "",
  profile_picture: "",
  team_id: "",
  location: {
    city: "",
    state: "",
    country: "",
    geo_coordinates: {
      latitude: "",
      longitude: "",
    },
  },
  gender: "",
  bookings: [],
  contact_info: {
    phone: "",
    address: "",
  },
  social_links: {
    facebook: "",
    twitter: "",
    instagram: "",
  },
};

export const turfSchema = {
  owner_id: "",
  name: "",
  location: "",
  geo_coordinates: {
    latitude: "",
    longitude: "",
  },
  sizes: "",
  surface_type: "",
  amenities: [],
  price_per_slot_time: "",
  availability: [
    {
      date: "",
      start_time: "",
      end_time: "",
    },
  ],
  ratings: [0, 0],
  images: [],
  status: "",
  type_of_booking: [],
  venue_rules: "",
  contact_info: {
    phone: "",
    email: "",
  },
  booking_history: [],
  owner_details: {
    owner_id: "",
    name: "",
    contact_info: {
      phone: "",
      email: "",
    },
  },
  discounts: [],
  accessibility_features: [],
  event_types: [],
  security_features: [],
  cancellation_policy: "",
  verified: false,
  verification_documents: [],
  booking_history: [],
};

export const bookingSchema = {
  turfName: "",
  turfAddress: "",
  turfId: "",
  userId: "",
  bookingDate: "",
  start_time: "",
  end_time: "",
  status: "ongoing",
  amountPaid: 0,
  amountToBePaid: 0,
  whatsappLink: "",
  turfOwnerPayOut: false,
};
