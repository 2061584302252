import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/footer/Group 18325.png";
import backImg from "../assets/footer/Footer.png";
import arrowImg from "../assets/footer/image 48.png";
import instagram from "../assets/footer/image 49.png";
import youtube from "../assets/footer/image 50.png";
import twitter from "../assets/footer/image 54.png";
import linkedin from "../assets/footer/image 55.png";
import email from "../assets/footer/image 56.png";
import { db } from "../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux";
import { logout } from "../firebase/authController";

const Footer = () => {
  const [websiteContent, setWebsiteContent] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    // Fetch website content from Firestore
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "websiteContent")); // Fetch from 'websiteContent' collection
        const contentData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWebsiteContent(contentData[0]);
      } catch (err) {
        console.error("Error fetching website content: ", err);
      }
    };

    fetchData(); // Call the function to fetch data from Firestore
  }, []);

  return (
    <div
      className="border-2"
      style={{ ...styles.container, backgroundImage: `url(${backImg})` }}
    >
      <div className="flex flex-col lg:flex-row justify-around grow p-4">
        <div className="flex justify-center lg:justify-start mb-6 lg:mb-0">
          <img src={logo} alt="Logo" className="w-32 h-32 lg:w-40 lg:h-40" />
        </div>
        <div className="font-poppins text-center lg:text-center lg:text-2xl text-[#27346A]">
          {/* <div className="flex font-poppins gap-3 text-base text-black w-48 h-12 rounded-3xl bg-[#E7EEFF] justify-center items-center shadow-lg py-3 px-5 mx-auto">
            <p className="">Get the App</p>
            <img src={arrowImg} alt="Arrow" />
          </div> */}
          {/* Hide navigation links on mobile view but keep them centered */}
          <div className="py-4 hidden lg:flex flex-col items-center">
            <NavLink to="/" className="py-2">
              Home
            </NavLink>
            <NavLink to={user?.uid ? "/myBookings" : "/login"} className="py-2">
              My Bookings
            </NavLink>
            <NavLink
              to={user?.uid ? `/userProfile/${user?.uid}` : "/login"}
              className="py-2"
            >
              Profile
            </NavLink>
            {user?.uid ? (
              <NavLink
                className="text-[1.5vw] font-medium font-poppins leading-[160%] tracking-[0.4px]"
                onClick={() => {
                  logout();
                }}
                to="/login"
              >
                Logout
              </NavLink>
            ) : (
              <NavLink
                className="text-[1.5vw] font-medium font-poppins leading-[160%] tracking-[0.4px]"
                to="/login"
              >
                Login
              </NavLink>
            )}
          </div>
        </div>
        <div className="text-center lg:text-left py-4 sm:py-0">
          <div className="flex justify-center flex-wrap lg:justify-start gap-6 mb-6">
            {websiteContent?.instagram && (
              <a
                href={websiteContent.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  className="flex-shrink-0 w-[10vw] h-[10vw] md:w-[3vw] md:h-[3vw]"
                />
              </a>
            )}
            {websiteContent?.youtube && (
              <a
                href={websiteContent?.youtube}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={youtube}
                  alt="Youtube"
                  className="flex-shrink-0 w-[10vw] h-[10vw] md:w-[3vw] md:h-[3vw]"
                />
              </a>
            )}
            {websiteContent.twitter && (
              <a
                href={websiteContent.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitter}
                  alt="Twitter"
                  className="flex-shrink-0 w-[10vw] h-[10vw] md:w-[3vw] md:h-[3vw]"
                />
              </a>
            )}
            {websiteContent.linkedin && (
              <a
                href={websiteContent.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedin}
                  alt="Linkedin"
                  className="flex-shrink-0 w-[10vw] h-[10vw] md:w-[3vw] md:h-[3vw]"
                />
              </a>
            )}
            {websiteContent?.email && (
              <a
                href={`mailto:${websiteContent.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={email}
                  alt="Email"
                  className="flex-shrink-0 w-[10vw] h-[10vw] md:w-[3vw] md:h-[3vw]"
                />
              </a>
            )}
          </div>

          <div className="my-6 font-poppins">
            <div className="text-xl py-4">Need Help? Contact Us</div>
            <div className="text-lg flex flex-col lg:flex-row gap-4">
              <Link to="/frequentlyasked">
                <div className="px-5 py-2 shadow-xl bg-[#E7EEFF] rounded-3xl">
                  FAQs
                </div>
              </Link>
              <Link to="/termsandconditions">
                <div className="px-5 py-2 shadow-xl bg-[#E7EEFF] rounded-3xl">
                  Terms and Conditions
                </div>
              </Link>
              <Link to="/policies">
                <div className="px-5 py-2 shadow-xl bg-[#E7EEFF] rounded-3xl">
                  Policies
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default Footer;
