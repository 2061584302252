import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { turfSchema } from "../../firebase/schemas";
import { fetchTurfById, updateTurfById } from "../../firebase/turfController";
import uploadFiles from "../../firebase/uploadFIles";
import LocationPicker from "./LocationPicker";
import TurfGrid from "./TurfGrid";
import TurfRules from "./TurfRules";
import { toast } from "react-toastify";

function EditTurf() {
  const user = useSelector((state) => state.auth.user);
  const turfId = useParams().turfId;
  const navigate = useNavigate();
  const [selectedSports, setSelectedSports] = useState("");
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedSlotTime, setSelectedSlotTime] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [availabilityFrom, setAvailabilityFrom] = useState("");
  const [availabilityTo, setAvailabilityTo] = useState("");
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [coordinates, setCoordinates] = useState([19.076, 72.8777]);
  const [turfs, setTurfs] = useState([]);
  const [rules, setRules] = useState([]);

  const toggleAmenity = (amenity) => {
    setSelectedAmenities((prev) =>
      prev.includes(amenity)
        ? prev.filter((item) => item !== amenity)
        : [...prev, amenity]
    );
  };

  const toggleSports = (sport) => {
    setSelectedSports((prev) =>
      prev.includes(sport)
        ? prev.filter((item) => item !== sport)
        : [...prev, sport]
    );
  };

  function handleListTurf(e) {
    e.preventDefault();
    if (!urls.length) {
      alert("Make sure you upload images before submitting!");
      return;
    }
    const updatedInfo = {
      ...turfSchema,
      name: name,
      location: location,
      address: address,
      sports: selectedSports,
      availability: {
        from: availabilityFrom,
        to: availabilityTo,
      },
      venue_rules: rules,
      amenities: selectedAmenities,
      slot_time: selectedSlotTime,
      available_turfs: turfs,
      images: urls,
      geo_coordinates: { latitude: coordinates[0], longitude: coordinates[1] },
      owner_id: user.uid,
      contact_info: user.contact_info,
      owner_details: {
        owner_id: user.uid,
        name: user.name,
        contact_info: user.contact_info,
      },
    };

    updateTurfById(turfId, updatedInfo).then(() => {
      toast.success(
        "Thanks for listing your turf with us! You verification will be completed soon."
      );
      navigate("/userProfile");
    });
  }

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const handleUpload = async () => {
    try {
      const fileUrls = await uploadFiles(files);
      setUrls([...urls, ...fileUrls]);
      // console.log("File URLs:", fileUrls);
      toast.success(
        "Files uploaded successfully! Proceed by submitting your turf for verification."
      );
    } catch (error) {
      console.error("Error uploading files:", error.message);
    }
  };

  useEffect(() => {
    if (turfId) {
      fetchTurfById(turfId).then((fetchedTurf) => {
        // set the fetched values
        setName(fetchedTurf.name);
        setLocation(fetchedTurf.location);
        setAddress(fetchedTurf.address);
        setCoordinates([
          fetchedTurf.geo_coordinates.latitude,
          fetchedTurf.geo_coordinates.longitude,
        ]);
        setTurfs(fetchedTurf.available_turfs);
        setSelectedSports(fetchedTurf.sports);
        setSelectedAmenities(fetchedTurf.amenities);
        setAvailabilityFrom(fetchedTurf.availability.from);
        setAvailabilityTo(fetchedTurf.availability.to);
        setSelectedSlotTime(fetchedTurf.slot_time);
        setRules(fetchedTurf.venue_rules);
        setTurfs(fetchedTurf.available_turfs);
        setUrls(fetchedTurf.images);
      });
    }
  }, [turfId]);

  if (!user && !(user.role === "Turf Owner")) return <Navigate to="/login" />;

  return (
    <div className="min-h-screen flex items-center justify-center py-[3vw]">
      <div className="container flex flex-col gap-[3vw] mx-auto px-[3.75vw] mb-[20vw] md:mb-0">
        {/* Heading */}
        <h1 className="text-[5vw] md:text-[2.25vw] font-bold text-[#27346A]">
          List Your Turf With Us!
        </h1>

        {/* Form Container */}
        <div className="bg-white rounded-[0.5vw] p-[2vw] drop-shadow-[0_0.5px_2px_rgba(0,0,0,0.25)]">
          <form
            onSubmit={handleListTurf}
            className="flex flex-col gap-[7.5vw] md:gap-[1.5vw] space-y-[1.5vw]"
          >
            {/* Turf Name */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Turf Name
              </label>
              <input
                name="turfname"
                type="text"
                className="block w-full px-[1.25vw] py-[0.75vw] text-[3.75vw] md:text-[1vw] border border-gray-[#AFB1B6] rounded-[0.875vw] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your turf's name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

            {/* Location */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Select Your Location
              </label>
              <input
                name="turflocation"
                type="text"
                className="block w-full px-[1.25vw] py-[0.75vw] text-[3.75vw] md:text-[1vw] border border-gray-[#AFB1B6] rounded-[0.875vw] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your turf's location"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </div>

            {/* Address */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                List Your Address
              </label>
              <textarea
                name="turfaddress"
                className="block w-full px-[1.25vw] py-[0.75vw] text-[3.75vw] md:text-[1vw] border border-gray-[#AFB1B6] rounded-[0.875vw] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your address"
                rows="3"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              ></textarea>
            </div>

            {/* Geolocation */}
            <div className="w-4/6 flex flex-col gap-[2.5vw] md:gap-[0.5vw] z-0">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Select A Nearby Map Location
              </label>
              <div className="h-[25vw] overflow-hidden">
                <LocationPicker setCoordinates={setCoordinates} />
              </div>
              {coordinates && (
                <p className="text-[3.75vw] md:text-[1vw]">
                  Selected Coordinates: Latitude: {coordinates[0]}, Longitude:{" "}
                  {coordinates[1]}
                </p>
              )}
            </div>

            {/* Sports Options */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Sports which can be played on turf
              </label>
              <div className="mt-[0.5vw] flex space-x-[2.5vw] md:space-x-[0.5vw] flex-wrap">
                {["Football"].map((sport) => (
                  <div
                    key={sport}
                    onClick={() => toggleSports(sport)}
                    className="cursor-pointer border px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] rounded-3xl text-[3.75vw] md:text-[1vw] text-center bg-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] my-[0.5vw]"
                    style={{
                      backgroundColor: selectedSports.includes(sport)
                        ? "#4277FF"
                        : "#FFFFFF",
                      color: selectedSports.includes(sport)
                        ? "#FFFFFF"
                        : "#61646B",
                    }}
                  >
                    {sport}
                  </div>
                ))}
              </div>
            </div>

            {/* Turfs */}
            {turfs.length && <TurfGrid turfs={turfs} setTurfs={setTurfs} />}

            {/* Facility Amenities */}
            <div>
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Facility Amenities
              </label>
              <div className="mt-[0.5vw] flex space-x-[2.5vw] md:space-x-[0.5vw] flex-wrap">
                {[
                  "Seating Area",
                  "Changing Area",
                  "Parking",
                  "Lighting",
                  "Others",
                ].map((amenity) => (
                  <div
                    key={amenity}
                    onClick={() => toggleAmenity(amenity)}
                    className="cursor-pointer border px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] rounded-3xl text-[3.75vw] md:text-[1vw] text-center bg-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] my-[0.5vw]"
                    style={{
                      backgroundColor: selectedAmenities.includes(amenity)
                        ? "#4277FF"
                        : "#FFFFFF",
                      color: selectedAmenities.includes(amenity)
                        ? "#FFFFFF"
                        : "#61646B",
                    }}
                  >
                    {amenity}
                  </div>
                ))}
              </div>
            </div>

            {/* Availability */}
            <div>
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Availability
              </label>
              <div className="grid grid-cols-2 gap-[1vw]">
                <input
                  type="time"
                  name="turfavailability_from"
                  className="text-[3.75vw] md:text-[1vw] mt-[0.25vw] block w-full px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="From"
                  value={availabilityFrom}
                  onChange={(e) => setAvailabilityFrom(e.target.value)}
                />
                <input
                  type="time"
                  name="turfavailability_to"
                  className="text-[3.75vw] md:text-[1vw] mt-[0.25vw] block w-full px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="To"
                  value={availabilityTo}
                  onChange={(e) => setAvailabilityTo(e.target.value)}
                />
              </div>

              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF] mt-[1vw]">
                Slot Times
              </label>
              <div className="mt-[0.5vw] flex space-x-[3.75vw] md:space-x-[0.5vw]">
                {["Hourly", "1.5hrs", "2hrs"].map((slot) => (
                  <div
                    key={slot}
                    onClick={() => setSelectedSlotTime(slot)}
                    className="cursor-pointer border px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] rounded-3xl text-[3.75vw] md:text-[1vw] text-center bg-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] my-[0.5vw]"
                    style={{
                      backgroundColor:
                        selectedSlotTime === slot ? "#4277FF" : "#FFFFFF",
                      color: selectedSlotTime === slot ? "#FFFFFF" : "#61646B",
                    }}
                  >
                    {slot}
                  </div>
                ))}
              </div>
            </div>

            {/* Rules and Regulations */}
            {rules.length && (
              <TurfRules turfRules={rules} setTurfRules={setRules} />
            )}

            {/* Upload Photos and Videos */}
            <div className="flex flex-col justify-start gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Upload Photos and Videos of Your Turf
              </label>
              <div className="max-w-full grid gap-[5vw] md:gap-[2vw] grid-cols-3 md:grid-cols-4">
                {urls.map((image) => (
                  <div className="flex flex-col items-center gap-[2.5vw] md:gap-[1vw]">
                    <div className="w-[25vw] h-[25vw] md:w-[18.75vw] md:h-[12.5vw]">
                      <img src={image} className="w-full h-full object-cover" />
                    </div>
                    <p
                      className="text-[3.75vw] md:text-[1vw] text-red-500 cursor-pointer"
                      onClick={() => {
                        const newUrls = urls.filter((url) => url !== image);
                        setUrls(newUrls);
                        toast.error("Image deleted!");
                      }}
                    >
                      Delete
                    </p>
                  </div>
                ))}
              </div>
              <input
                type="file"
                required
                className="mt-[0.25vw] text-[3.75vw] md:text-[1vw] block w-full px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                multiple
                onChange={handleFileChange}
              />
              <button
                type="submit"
                className="bg-[#4277FF] px-[6.25vw] py-[2.5vw] md:px-[2vw] md:py-[0.5vw] mx-auto mt-[1vw] text-white text-[3.75vw] md:text-[1vw] rounded-full w-fit shadow-md shadow-black/25"
                onClick={(e) => {
                  e.preventDefault();
                  handleUpload();
                }}
              >
                Upload Files
              </button>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-[#71C9BA] px-[6.25vw] py-[2.5vw] md:px-[2vw] md:py-[0.5vw] mx-auto mt-[1vw] text-white text-[4.325vw] md:text-[1.25vw] rounded-full w-fit shadow-md shadow-black/2"
              >
                Save Turf
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditTurf;
