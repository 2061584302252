import React from "react";
import logo from "../assets/homepage/image 2.png";

const Sidebar = ({ page, setPage }) => {
  const pages = [
    "Users",
    "Turfs",
    "Bookings",
    "Website Content",
    // "Turf Verification",
  ];
  return (
    <div className="h-full col-span-1 bg-black text-white flex flex-col">
      {/* Top Bar */}
      <div className="h-[70px] flex items-center justify-start text-xl font-bold">
        {/* Logo and Brand Name */}
        <div className="w-[40px] h-[40px] overflow-hidden">
          <img src={logo} className="w-full h-full object-contain" alt="Logo" />
        </div>
        TURFBRELLA
      </div>
      {/* Links */}
      <div className="flex-1 flex flex-col">
        {pages.map((el) => (
          <a
            onClick={() => {
              setPage(el);
            }}
            className="cursor-pointer text-white hover:bg-gray-700 p-4 transition-all duration-200"
          >
            {el}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
