import React, { useState } from "react";
import filterImg from "../../assets/homepage/Filter.png";

const CustomSelect = ({ onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Filter");

  const options = [
    {
      label: "Ratings: High to Low",
      value: "Ratings: High to Low",
    },
    {
      label: "Pricing: Low to High",
      value: "Pricing: Low to High",
    },
    {
      label: "Pricing: High to Low",
      value: "Pricing: High to Low",
    },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    onChange(option.value);
  };

  return (
    <div className="relative inline-block text-right bg-white">
      <button
        className="rounded-[0.375vw] p-[0.5vw] flex items-center gap-[0.5vw]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={filterImg}
          alt="Filter"
          className="w-[5vw] h-[7.5vw] md:w-[1vw] md:h-[1.5vw]"
        />
        <span>{selectedOption}</span>
      </button>

      {isOpen && (
        <ul className="absolute z-50 bg-white rounded-md p-[2.5vw] md:p-[0.5vw] mt-[2.5vw] md:mt-[0.5vw] w-[45vw] md:w-[12vw] drop-shadow-[0_0_1px_rgba(0,0,0,0.25)] right-0">
          <li
            className="flex items-center justify-end gap-[0.5vw] p-[2.5vw] md:p-[0.5vw] cursor-pointer hover:bg-gray-100"
            onClick={() => handleOptionClick({ label: "Filter", value: "" })}
          >
            <span className="text-[3.75vw] md:text-[1vw] text-black font-semibold">
              Clear
            </span>
          </li>
          {options.map((option, index) => (
            <li
              key={index}
              className="p-[2.5vw] md:p-[0.5vw] text-[3.75vw] md:text-[1vw] cursor-pointer hover:bg-gray-100 text-black"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
