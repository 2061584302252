import React, { useState } from "react";
import { toast } from "react-toastify";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const PaymentGateway = ({ amount, bookingId }) => {
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handlePayment = async () => {
    setPaymentLoading(true);
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Ensure this env var is correctly configured
        key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
        amount: parseInt(amount * 100), // Amount in paise
        currency: "INR",
        order_receipt: "order_rcptid_" + "Tahir", // Customize as needed
        name: "Turfbrella",
        description: "for testing purpose",
        handler: async (response) => {
          toast.success("Payment Successful!");
          toast(
            "See 'My Bookings' to confirm your payment. Use the 'Contact Us' section to raise a query."
          );
          const paymentId = response.razorpay_payment_id;
          console.log("Payment ID:", paymentId);

          // Update booking status to 'paid' in Firestore
          const bookingRef = doc(db, "bookings", bookingId); // Assuming 'bookings' is the collection
          await updateDoc(bookingRef, {
            status: "paid",
            amountPaid: amount,
            paymentId: paymentId,
          });

          console.log(`Booking ${bookingId} status updated to 'paid'`);
        },
        theme: {
          color: "#3399cc",
        },
      };

      const pay = new window.Razorpay(options);
      pay.open();

      pay.on("payment.failed", (response) => {
        toast.error("Payment failed. Please try again.");
        console.error("Payment failed:", response.error);
      });
    } catch (error) {
      toast.error(
        "An error occurred while initiating the payment. Please try again."
      );
      console.error("Error in handlePayment:", error);
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <div className="payment-gateway mt-4">
      <button
        onClick={handlePayment}
        className="w-fit  py-[1.25vw] px-[2.5vw] md:py-[0.5vw] md:px-[1vw] bg-[#2561C4] text-white flex text-[3.75vw] md:text-[1vw] rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]"
        disabled={paymentLoading}
      >
        {paymentLoading ? "Processing..." : "Pay Now"}
      </button>
    </div>
  );
};

export default PaymentGateway;
