import React from "react";
import cricketBall from "../../assets/homepage/image 61.png";
import ball from "../../assets/homepage/image 63.png";
import tt from "../../assets/homepage/image 62.png";
import star from "../../assets/homepage/Star.png";
import { Link } from "react-router-dom";

const Card = ({
  turfId,
  title,
  address,
  image,
  time,
  date,
  horizontal = false,
  isDiscover = false,
  editable,
  ratings,
}) => {
  return (
    <Link to={!editable ? `/turfDetails/${turfId}` : `/editTurf/${turfId}`}>
      <div
        className={`card ${horizontal ? "flex flex-row-reverse" : "block"} ${
          isDiscover ? "bg-white" : "bg-[#E7EEFF]"
        } overflow-hidden shadow-md shadow-[#6B7280] rounded-[3.625vw] md:rounded-[0.75vw] m-[2.5vw] md:m-[0.25vw]`}
        style={{
          width: "100%",
          maxWidth: "80vw",
        }}
      >
        <div
          className={`card-image-container rounded-[1.25vw] md:rounded-[1vw] overflow-hidden ${
            horizontal
              ? "w-1/3 h-[37.5vw] md:h-[14.0625vw]"
              : "flex justify-center items-center h-[35vw] md:h-[12.5vw]"
          } p-[2.5vw] md:p-[0.5vw]`}
          style={{ overflow: "hidden" }}
        >
          <img
            src={image}
            alt={title}
            className={`card-image rounded-[1vw] ${
              horizontal
                ? "object-cover h-full w-full"
                : "object-cover w-full h-full"
            }`}
            style={{ objectFit: "cover" }}
          />
        </div>
        <div
          className={`card-content px-[2.5vw] md:px-[0.5vw] font-poppins ${
            horizontal ? "w-2/3" : "h-full md:h-[13.25vw]"
          } ${
            isDiscover
              ? "p-[2.5vw] md:p-[0.5vw]"
              : " p-[5vw] sm:p-[1vw] md:p-[1.5vw]"
          } ${
            isDiscover ? "bg-white" : "bg-[#E7EEFF]"
          } text-left rounded-[0.75vw]`}
        >
          <h2 className="card-title font-bold text-[4.325vw] md:text-[1.25vw] font-poppins">
            {title}
          </h2>
          <div className="flex justify-between mt-[2.5vw] md:mt-[0.5vw]">
            <div className="flex gap-[1.25vw] md:gap-[0.5vw]">
              <img
                src={cricketBall}
                alt="Cricket Ball"
                className="w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
              />
              <img
                src={ball}
                alt="Ball"
                className="w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
              />
              <img
                src={tt}
                alt="TT"
                className="w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
              />
            </div>
            <div className="flex items-center">
              <img
                src={star}
                alt="Star"
                className="mr-[0.25vw] w-[5vw] h-[5vw] sm:w-[1.5vw] sm:h-[1.5vw] md:w-[2vw] md:h-[2vw]"
              />
              <p className="text-[3.75vw] md:text-[1vw]">
                {ratings[1] > 0 ? (ratings[0] / ratings[1]).toFixed(1) : 0}(
                {ratings[1]})
              </p>
            </div>
          </div>
          <div className="flex gap-[0.5vw] sm:gap-[0.75vw] items-center m-[2.5vw] md:m-[1vw]">
            <p className="text-[3.75vw] md:text-[1vw]">{date}</p>
            <p className="text-[3.75vw] md:text-[1vw]">{time}</p>
          </div>
          <p className="text-[3.75vw] md:text-[1vw] m-[0.5vw] sm:m-[1vw]">
            {address}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
