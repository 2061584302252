import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchBookingById } from "../../firebase/turfController";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const BookingDetailsAdmin = () => {
  const { bookingId } = useParams(); // Extract bookingId from URL params
  const [booking, setBooking] = useState(null); // Booking data state
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    // Fetch booking details when the component mounts
    const getBookingDetails = async () => {
      try {
        const bookingData = await fetchBookingById(bookingId);
        setBooking(bookingData);
      } catch (error) {
        setError("Failed to fetch booking details.");
      } finally {
        setLoading(false);
      }
    };

    getBookingDetails();
  }, [bookingId]);

  if (loading) {
    return <p>Loading booking details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const payTurfOwner = async () => {
    // Ask for user confirmation using native confirm dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to pay the Turf Owner?"
    );

    if (isConfirmed) {
      try {
        // Reference to the booking document in Firestore
        const bookingRef = doc(db, "bookings", bookingId);

        // Update the booking document to mark the payout as processed
        await updateDoc(bookingRef, { turfOwnerPayOut: true });

        // Log success message
        console.log(`Payout status updated for booking ${bookingId}`);
        alert("Payout successful. The Turf Owner has been paid.");
      } catch (error) {
        console.error("Error updating payout status:", error);
        alert("An error occurred while processing the payout.");
      }
    } else {
      console.log("Payout cancelled by the user.");
    }
  };

  return (
    <div className="container mx-auto p-8">
      <Link to="/v0/admin">
        <p className="absolute top-[1vw] left-[2vw] text-[1vw] text-blue-600 underline">
          Back
        </p>
      </Link>
      <h1 className="text-2xl font-bold mb-4">Booking Details</h1>
      <div className="mt-10 flex flex-col">
        <h3 className="text-xl">Turf Details</h3>
        <div className="mt-4 w-full">
          <table className="border border-black w-[70%]">
            <tr className="bg-zinc-400">
              <td className="pl-2 pt-2 border-black border w-[20%]">Turf Id</td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.turfId}
              </td>
            </tr>
            <tr className=" ">
              <td className="pl-2 pr-4 pt-2 border-black border bg-zinc-300">
                Turf Name
              </td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.turfName}
              </td>
            </tr>
            <tr className=" ">
              <td className="pl-2 pr-4 pt-2 border-black border bg-zinc-300">
                Address
              </td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.turfAddress}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="mt-10 flex flex-col">
        <h3 className="text-xl">Slot Booking Details</h3>
        <div className="mt-4 w-full">
          <table className="border border-black w-[70%]">
            <tr className="bg-zinc-400">
              <td className="pl-2 pt-2 border-black border w-[20%]">Date</td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.bookingDate}
              </td>
            </tr>
            <tr className=" ">
              <td className="pl-2 pr-4 pt-2 border-black border bg-zinc-300">
                Turf
              </td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.selectedTurf.turfNumber}
              </td>
            </tr>
            <tr className=" ">
              <td className="pl-2 pr-4 pt-2 border-black border bg-zinc-300">
                Timing
              </td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.start_time} - {booking.end_time}
              </td>
            </tr>
            <tr className=" ">
              <td className="pl-2 pr-4 pt-2 border-black border bg-zinc-300">
                User Profile
              </td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                <Link to={`/seeUserProfile/${booking.userId}`}>
                  <p className="text-blue-600 underline">Visit Profile</p>
                </Link>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="mt-10 flex flex-col">
        <h3 className="text-xl">Payment Details</h3>
        <div className="mt-4 w-full">
          <table className="border border-black w-[70%]">
            <tr className="bg-zinc-400">
              <td className="pl-2 pt-2 border-black border w-[20%]">
                Payment Id
              </td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.paymentId}
              </td>
            </tr>
            <tr className=" ">
              <td className="pl-2 pr-4 pt-2 border-black border bg-zinc-300">
                Amount Paid
              </td>
              <td className="pl-2 pr-4 pt-2 border-black border">
                {booking.amountPaid}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <button
        className="mt-10 bg-[#454545] text-white py-2 px-3 rounded-lg w-fit"
        onClick={payTurfOwner}
      >
        Pay Turf Owner
      </button>
    </div>
  );
};

export default BookingDetailsAdmin;
