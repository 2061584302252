import React, { useState } from "react";
import star from "../../assets/homepage/Star.png";
import PaymentGateway from "./PaymentGateway";

const BookingCard = ({ bookingDetails }) => {
  // console.log(bookingDetails);

  return (
    <div className="flex justify-between shadow-xl bg-[#F5FAFF] rounded-xl w-[93.75vw] md:w-[76.25vw] p-[2.5vw] md:p-[1vw]">
      <div className="w-full sm:p-[2vw] p-[2.5vw]">
        <div className="flex items-center justify-between flex-wrap">
          <h2 className="font-semibold text-[5.75vw] md:text-[1.875vw]">
            {bookingDetails.turfName}
          </h2>
          <p className="text-[3.75vw] md:text-[1vw]">
            Selected Turf: {bookingDetails.selectedTurf.turfNumber}
          </p>
          {/* <div className="flex items-center">
            <img
              src={star}
              alt="Star"
              className="mr-1 w-[5vw] h-[5vw] md:w-[2vw] md:h-[2vw]"
            />
            <p className="text-[3.75vw] md:text-[1vw]">
              {bookingDetails?.turfRatings[1] > 0
                ? (
                    bookingDetails?.turfRatings[0] /
                    bookingDetails?.turfRatings[1]
                  ).toFixed(1)
                : 0}
              ({bookingDetails?.turfRatings[1]})
            </p>
          </div> */}
        </div>

        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex sm:block gap-[2.5vw] md:gap-[1.25vw] items-center m-[2.5vw] md:m-[1vw] text-black font-medium">
            <p className="text-[3.75vw] md:text-[1.5vw]">
              {bookingDetails.bookingDate}
            </p>
            <p className="text-[3.75vw] md:text-[1.5vw]">
              {bookingDetails.start_time} - {bookingDetails.end_time}
            </p>
          </div>
          <div className="payment">
            <PaymentGateway
              amount={bookingDetails.selectedTurf.pricing}
              bookingId={bookingDetails.id}
            />
          </div>
        </div>
        <p className="text-[3.75vw] md:text-[1vw] m-[2.5vw] md:m-[1vw] text-[#61646B]">
          {bookingDetails.turfAddress}
        </p>
      </div>
      <div className="flex justify-center items-center rounded ">
        <img
          src={bookingDetails.turfImage}
          alt=""
          className=" h-[25vw] md:h-[12.5vw] rounded md:rounded-[1vw]"
        />
      </div>
    </div>
  );
};

export default BookingCard;
