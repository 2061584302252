import React, { useState } from "react";
import locationImg from "../assets/homepage/Location.png";

const LocationSelector = () => {
  const [city, setCity] = useState(localStorage.getItem("city") || "");
  const [selectedCity, setSelectedCity] = useState(
    localStorage.getItem("city") || ""
  );

  const handleCityChange = (event) => {
    event.preventDefault();
    setSelectedCity(city);
    localStorage.setItem("city", city);
    const cityUpdatedEvent = new Event("cityUpdated");
    window.dispatchEvent(cityUpdatedEvent);
  };

  return (
    <div className="flex items-center justify-center gap-[0.5vw] max-w-28 md:max-w-52">
      <img
        src={locationImg}
        alt="Location icon"
        className="h-[8.75vw] md:h-[1.75vw] w-fit"
      />
      <form onSubmit={handleCityChange}>
        <input
          id="city-select"
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          placeholder="Type city name"
          className="bg-[#4277FF] md:bg-[#E7EEFF] focus:outline-none focus:border-none focus:box-shadow-none text-[4.325vw] md:text-[1.5vw] max-w-28 md:max-w-52 font-medium font-poppins leading-[160%] tracking-[0.4px] text-white md:text-[#27346A]"
        />
        <button className="hidden" type="submit"></button>
      </form>
    </div>
  );
};

export default LocationSelector;
