import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { turfSchema } from "../../firebase/schemas";
import { addTurf } from "../../firebase/turfController";
import uploadFiles from "../../firebase/uploadFIles";
import LocationPicker from "./LocationPicker";
import TurfGrid from "./TurfGrid";
import TurfRules from "./TurfRules";
import { toast } from "react-toastify";

function ListYourTurf() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [selectedSports, setSelectedSports] = useState("");
  const [selectedTurfSize, setSelectedTurfSize] = useState("");
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedSlotTime, setSelectedSlotTime] = useState("");
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [coordinates, setCoordinates] = useState([19.076, 72.8777]);
  const [turfs, setTurfs] = useState(null);
  const [rules, setRules] = useState(null);

  const toggleAmenity = (amenity) => {
    setSelectedAmenities((prev) =>
      prev.includes(amenity)
        ? prev.filter((item) => item !== amenity)
        : [...prev, amenity]
    );
  };

  const toggleSports = (sport) => {
    setSelectedSports((prev) =>
      prev.includes(sport)
        ? prev.filter((item) => item !== sport)
        : [...prev, sport]
    );
  };

  function handleListTurf(e) {
    e.preventDefault();
    if (!urls.length) {
      alert("Make sure you upload files before submitting!");
      return;
    }
    if (urls.length < 3) {
      alert("You need to upload 3 or more images!");
      return;
    }
    const newTurf = {
      ...turfSchema,
      name: e.target.turfname.value,
      location: e.target.turflocation.value,
      address: e.target.turfaddress.value,
      sports: selectedSports,
      availability: {
        from: e.target.turfavailability_from.value,
        to: e.target.turfavailability_to.value,
      },
      venue_rules: rules,
      amenities: selectedAmenities,
      slot_time: selectedSlotTime,
      owner_id: user.uid,
      available_turfs: turfs,
      contact_info: user.contact_info,
      owner_details: {
        owner_id: user.uid,
        name: user.name,
        contact_info: user.contact_info,
      },
      images: urls,
      geo_coordinates: { latitude: coordinates[0], longitude: coordinates[1] },
    };

    addTurf(newTurf).then(() => {
      toast.success(
        "Thanks for listing your turf with us! You verification will be completed soon."
      );
      navigate(`/userProfile/${user.uid}`);
    });
  }

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const handleUpload = async () => {
    try {
      const fileUrls = await uploadFiles(files);
      setUrls(fileUrls);
      // console.log("File URLs:", fileUrls);
      toast.success(
        "Files uploaded successfully! Proceed by submitting your turf for verification."
      );
    } catch (error) {
      console.error("Error uploading files:", error.message);
    }
  };

  if (!user && !(user.role === "Turf Owner")) return <Navigate to="/login" />;

  return (
    <div className="min-h-screen flex items-center justify-center py-[3vw]">
      <div className="container flex flex-col gap-[3vw] mx-auto px-[3.75vw] mb-[20vw] md:mb-0">
        {/* Heading */}
        <h1 className="text-[5vw] md:text-[2.25vw] font-bold text-[#27346A]">
          List Your Turf With Us!
        </h1>

        {/* Form Container */}
        <div className="bg-white rounded-[0.5vw] p-[2vw] drop-shadow-[0_0.5px_2px_rgba(0,0,0,0.25)]">
          <form
            onSubmit={handleListTurf}
            className="flex flex-col gap-[7.5vw] md:gap-[1.5vw] space-y-[1.5vw]"
          >
            {/* Turf Name */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Turf Name
              </label>
              <input
                name="turfname"
                type="text"
                className="block w-full px-[1.25vw] py-[0.75vw] text-[3.75vw] md:text-[1vw] border border-gray-[#AFB1B6] rounded-[0.875vw] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your turf's name"
              />
              {}
            </div>

            {/* Location */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Select Your Location
              </label>
              <input
                name="turflocation"
                type="text"
                className="block w-full px-[1.25vw] py-[0.75vw] text-[3.75vw] md:text-[1vw] border border-gray-[#AFB1B6] rounded-[0.875vw] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your turf's location"
              />
            </div>

            {/* Address */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                List Your Address
              </label>
              <textarea
                name="turfaddress"
                className="block w-full px-[1.25vw] py-[0.75vw] text-[3.75vw] md:text-[1vw] border border-gray-[#AFB1B6] rounded-[0.875vw] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter your address"
                rows="3"
              ></textarea>
            </div>

            {/* Geolocation */}
            <div className="w-4/6 flex flex-col gap-[2.5vw] md:gap-[0.5vw] z-0">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Select A Nearby Map Location
              </label>
              <div className="h-[25vw] overflow-hidden">
                <LocationPicker setCoordinates={setCoordinates} />
              </div>
              {coordinates && (
                <p className="text-[3.75vw] md:text-[1vw]">
                  Selected Coordinates: Latitude: {coordinates[0]}, Longitude:{" "}
                  {coordinates[1]}
                </p>
              )}
            </div>

            {/* Sports Options */}
            <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Sports which can be played on turf
              </label>
              <div className="mt-[0.5vw] flex space-x-[2.5vw] md:space-x-[0.5vw] flex-wrap">
                {["Football"].map((sport) => (
                  <div
                    key={sport}
                    onClick={() => toggleSports(sport)}
                    className="cursor-pointer border px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] rounded-3xl text-[3.75vw] md:text-[1vw] text-center bg-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] my-[0.5vw]"
                    style={{
                      backgroundColor: selectedSports.includes(sport)
                        ? "#4277FF"
                        : "#FFFFFF",
                      color: selectedSports.includes(sport)
                        ? "#FFFFFF"
                        : "#61646B",
                    }}
                  >
                    {sport}
                  </div>
                ))}
              </div>
            </div>

            {/* Turfs */}
            <TurfGrid setTurfs={setTurfs} />

            {/* Facility Amenities */}
            <div>
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Facility Amenities
              </label>
              <div className="mt-[0.5vw] flex space-x-[2.5vw] md:space-x-[0.5vw] flex-wrap">
                {[
                  "Seating Area",
                  "Changing Area",
                  "Parking",
                  "Lighting",
                  "Others",
                ].map((amenity) => (
                  <div
                    key={amenity}
                    onClick={() => toggleAmenity(amenity)}
                    className="cursor-pointer border px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] rounded-3xl text-[3.75vw] md:text-[1vw] text-center bg-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] my-[0.5vw]"
                    style={{
                      backgroundColor: selectedAmenities.includes(amenity)
                        ? "#4277FF"
                        : "#FFFFFF",
                      color: selectedAmenities.includes(amenity)
                        ? "#FFFFFF"
                        : "#61646B",
                    }}
                  >
                    {amenity}
                  </div>
                ))}
              </div>
            </div>

            {/* Availability */}
            <div>
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Availability
              </label>
              <div className="grid grid-cols-2 gap-[1vw]">
                <input
                  type="time"
                  name="turfavailability_from"
                  className="mt-[0.25vw] block w-full px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="From"
                />
                <input
                  type="time"
                  name="turfavailability_to"
                  className="mt-[0.25vw] block w-full px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="To"
                />
              </div>

              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF] mt-[1vw]">
                Slot Times
              </label>
              <div className="mt-[0.5vw] flex space-x-[3.75vw] md:space-x-[0.5vw]">
                {["Hourly", "1.5hrs", "2hrs"].map((slot) => (
                  <div
                    key={slot}
                    onClick={() => setSelectedSlotTime(slot)}
                    className="cursor-pointer border px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] rounded-3xl text-[3.75vw] md:text-[1vw] text-center bg-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)] my-[0.5vw]"
                    style={{
                      backgroundColor:
                        selectedSlotTime === slot ? "#4277FF" : "#FFFFFF",
                      color: selectedSlotTime === slot ? "#FFFFFF" : "#61646B",
                    }}
                  >
                    {slot}
                  </div>
                ))}
              </div>
            </div>

            {/* Rules and Regulations */}
            <TurfRules setTurfRules={setRules} />

            {/* Upload Photos and Videos */}
            <div className="flex flex-col justify-start gap-[0.5vw]">
              <label className="block text-[3.75vw] md:text-[1vw] font-medium text-[#4277FF]">
                Upload Photos and Videos of Your Turf
              </label>
              <div className="max-w-full grid gap-[5vw] md:gap-[2vw] grid-cols-3 md:grid-cols-4">
                {urls.map((image) => (
                  <div className="flex flex-col items-center gap-[2.5vw] md:gap-[1vw]">
                    <div className="w-[25vw] h-[25vw] md:w-[18.75vw] md:h-[12.5vw]">
                      <img src={image} className="w-full h-full object-cover" />
                    </div>
                    <p
                      className="text-[3.75vw] md:text-[1vw] text-red-500 cursor-pointer"
                      onClick={() => {
                        const newUrls = urls.filter((url) => url !== image);
                        setUrls(newUrls);
                        toast.error("Image deleted!");
                      }}
                    >
                      Delete
                    </p>
                  </div>
                ))}
              </div>
              <input
                type="file"
                required
                className="mt-[0.25vw] text-[3.75vw] md:text-[1vw] block w-full px-[3.75vw] py-[2.5vw] md:px-[1vw] md:py-[0.5vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                multiple
                onChange={handleFileChange}
              />
              <button
                type="submit"
                className="bg-[#4277FF] px-[6.25vw] py-[2.5vw] md:px-[2vw] md:py-[0.5vw] mx-auto mt-[1vw] text-white text-[3.75vw] md:text-[1vw] rounded-full w-fit shadow-md shadow-black/25"
                onClick={(e) => {
                  e.preventDefault();
                  handleUpload();
                }}
              >
                Upload Files
              </button>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-[#71C9BA] px-[6.25vw] py-[2.5vw] md:px-[2vw] md:py-[0.5vw] mx-auto mt-[1vw] text-white text-[4.325vw] md:text-[1.25vw] rounded-full w-fit shadow-md shadow-black/2"
              >
                Submit for Verification
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ListYourTurf;
