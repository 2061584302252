import React, { useEffect, useState } from "react";

const TurfRules = ({ turfRules, setTurfRules }) => {
  const [rules, setRules] = useState(turfRules || []);
  const [newRule, setNewRule] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editRule, setEditRule] = useState("");

  const handleAddRule = () => {
    if (newRule.trim() === "") return;
    setRules([...rules, newRule]);
    setNewRule("");
  };

  const handleEditRule = (index) => {
    setEditIndex(index);
    setEditRule(rules[index]);
  };

  const handleSaveEdit = () => {
    if (editRule.trim() === "") return;
    const updatedRules = rules.map((rule, index) =>
      index === editIndex ? editRule : rule
    );
    setRules(updatedRules);
    setEditIndex(null);
    setEditRule("");
  };

  const handleDeleteRule = (index) => {
    const updatedRules = rules.filter((_, idx) => idx !== index);
    setRules(updatedRules);
  };

  useEffect(() => {
    setTurfRules(rules);
  }, [rules]);

  return (
    <div className="space-y-[1vw]">
      <div className="mb-[1vw]">
        <label className="block text-[3.75vw] md:text-[1vw] font-medium text-gray-700">
          Add New Rule
        </label>
        <input
          type="text"
          value={newRule}
          onChange={(e) => setNewRule(e.target.value)}
          className="mt-[0.25vw] block text-[3.75vw] md:text-[1vw] w-full px-[1vw] py-[0.5vw] border border-gray-300 rounded-[0.375vw] shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            handleAddRule();
          }}
          className="bg-[#4277FF] px-[3.75vw] py-[2.5vw] md:px-[2vw] md:py-[0.5vw] mx-auto mt-[1vw] text-white rounded-full w-fit shadow-md shadow-black/25 text-[3.75vw] md:text-[1.125vw]"
        >
          Add Rule
        </button>
      </div>

      {rules.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-[1.5vw] py-[0.75vw] text-left text-[3.75vw] md:text-[1vw] font-medium text-gray-500 uppercase tracking-wider">
                  Rules
                </th>
                <th className="px-[1.5vw] py-[0.75vw]"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rules.map((rule, index) => (
                <tr key={index}>
                  <td className="px-[1.5vw] py-[1vw] whitespace-nowrap">
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editRule}
                        onChange={(e) => setEditRule(e.target.value)}
                        className="block w-full border border-gray-300 rounded-[0.375vw] shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-[3.75vw] md:text-[1vw]"
                      />
                    ) : (
                      <p className="text-[3.75vw] md:text-[1vw]">{rule}</p>
                    )}
                  </td>
                  <td className="px-[1.5vw] py-[1vw] whitespace-nowrap text-right">
                    {editIndex === index ? (
                      <>
                        <button
                          onClick={handleSaveEdit}
                          className="text-green-600 hover:text-green-900"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setEditIndex(null)}
                          className="text-gray-600 hover:text-gray-900 ml-[1vw]"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => handleEditRule(index)}
                          className="text-indigo-600 hover:text-indigo-900 text-[3.75vw] md:text-[1vw]"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteRule(index)}
                          className="text-red-600 hover:text-red-900 ml-[1vw] text-[3.75vw] md:text-[1vw]"
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TurfRules;
