import React, { useEffect, useState } from "react";
import DiscoverCard from "../Home/DiscoverCard";
import Footer from "../../components/Footer";
import image from "../../assets/homepage/image 41.png"; // Example image
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchUserBookings } from "../../firebase/userContoller";

const MyBookings = () => {
  const user = useSelector((state) => state.auth.user);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    if (user.uid) {
      fetchUserBookings(user.uid)
        .then((bookings) => {
          // console.log("User bookings:", bookings);
          setBookings(bookings);
        })
        .catch((error) => {
          console.error("Failed to fetch user bookings:", error);
        });
    }
  }, [user]);

  if (!user) return <Navigate to="/login" />;

  return (
    <div className="flex flex-col items-center font-poppins">
      <div className="border-b-2 w-full flex flex-col items-center sm:py-16">
        <h1 className="font-poppins font-semibold text-2xl m-6 sm:m-10 text-[#27346A]">
          View Receipt
        </h1>
        <div className="flex justify-center">
          <div className="flex flex-col justify-center p-4 gap-8">
            {bookings.map((booking, index) => {
              const bookingDate = new Date(booking.bookingDate);
              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              if (booking.status !== "ongoing" && bookingDate >= currentDate)
                return (
                  <DiscoverCard
                    key={index}
                    bookingId={booking.id}
                    turfId={booking.turfId}
                    title={booking.turfName}
                    address={booking.turfAddress}
                    image={booking.turfImage}
                    time={`${booking.start_time} - ${booking.end_time}`}
                    date={booking.bookingDate}
                    selectedTurf={booking.selectedTurf}
                    horizontal={true}
                    isDiscover={true}
                    turfRated={booking.rated}
                  />
                );
            })}
          </div>
        </div>
      </div>
      <div className="border-b-2 w-full flex flex-col items-center sm:py-16">
        <h1 className="font-poppins font-semibold text-2xl m-6 text-[#27346A]">
          Previous Bookings
        </h1>
        <div className="flex justify-center">
          <div className="flex flex-col justify-center p-4 gap-8">
            {bookings.map((booking, index) => {
              const bookingDate = new Date(booking.bookingDate);
              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              if (booking.status !== "ongoing" && bookingDate <= currentDate) {
                return (
                  <DiscoverCard
                    key={index}
                    bookingId={booking.id}
                    turfId={booking.turfId}
                    title={booking.turfName}
                    address={booking.turfAddress}
                    image={booking.turfImage}
                    time={`${booking.start_time} - ${booking.end_time}`}
                    date={booking.bookingDate}
                    ratings={booking.turfRatings}
                    selectedTurf={booking.selectedTurf}
                    horizontal={true}
                    isDiscover={true}
                    isprevious={true}
                    turfRated={booking.rated}
                  />
                );
              }

              return null;
            })}
          </div>
        </div>
        <div className="my-6 mb-20 mx-4 font-poppins block sm:hidden ">
          <div className="text-base py-4">Need Help? Contact Us</div>
          <div className="text-xs flex lg:flex-row gap-4">
            <Link to="/frequentlyasked">
              <div className="px-5 py-2 shadow-xl bg-[#E7EEFF] rounded-3xl">
                FAQs
              </div>
            </Link>

            <Link to="/termsandconditions">
              <div className="px-5 py-2 shadow-xl bg-[#E7EEFF] rounded-3xl">
                Terms and Conditions
              </div>
            </Link>
            <Link to="/policies">
              <div className="px-5 py-2 shadow-xl bg-[#E7EEFF] rounded-3xl">
                Policies
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full hidden sm:block">
        <Footer />
      </div>
    </div>
  );
};

export default MyBookings;
