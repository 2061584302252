import React, { useEffect, useState } from "react";
import DateSelector from "./DateSelector";
import filter from "../../assets/homepage/Filter.png";
import searchIcon from "../../assets/homepage/Search.png";
import img1 from "../../assets/homepage/shape_110.png";
import img2 from "../../assets/homepage/shape_04.png";
import Cardslider from "./Cardslider";
import CustomSelect from "./CustomSelect";
import { useSelector } from "react-redux";
import CardGrid from "./CardGrid";
import { calculateSlots } from "../TurfDetails/calculateSlots";
import { toast } from "react-toastify";

const MAX_TOASTS = 1;
let toastCount = 0;

const SearchVenues = ({ turfs }) => {
  const user = useSelector((state) => state.auth.user);
  const [filteredTurfs, setFilteredTurfs] = useState(turfs);
  const [filter, setFilter] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [seeAll, setSeeAll] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const notify = () => {
    if (toastCount < MAX_TOASTS) {
      toast.error(`Couldn't find any turfs. Showing all turfs.`, {
        onOpen: () => toastCount++,
        onClose: () => toastCount--,
      });
      setFilteredTurfs(turfs);
    } else {
      console.log("Max toast limit reached!");
    }
  };

  useEffect(() => {
    if (turfs.length) {
      let sortedTurfs = [...turfs];

      // Apply sorting based on the selected option
      if (filter === "Ratings: High to Low") {
        sortedTurfs = sortedTurfs.sort(
          (a, b) => b.ratings[0] / b.ratings[1] - a.ratings[0] / a.ratings[1]
        );
      } else if (filter === "Pricing: Low to High") {
        sortedTurfs = sortedTurfs.sort(
          (a, b) => a.available_turfs[0].pricing - b.available_turfs[0].pricing
        );
      } else if (filter === "Pricing: High to Low") {
        sortedTurfs = sortedTurfs.sort(
          (a, b) => b.available_turfs[0].pricing - a.available_turfs[0].pricing
        );
      }

      setFilteredTurfs(sortedTurfs);
    }
  }, [turfs, user, filter]);

  useEffect(() => {
    if (searchValue === "") {
      // If searchValue is empty, show all turfs
      setFilteredTurfs(turfs);
    } else {
      // Filter the turfs based on the search value
      const filtered = turfs.filter((turf) =>
        turf.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      setFilteredTurfs(filtered); // Update state with filtered turfs

      // If no turfs match the search value, call the notify function
      if (filtered.length === 0) {
        notify();
      }
    }
  }, [searchValue, turfs]);

  const handleSelectChange = (selectedValue) => {
    setFilter(selectedValue);
    // console.log("Selected Filter:", selectedValue);
  };

  // console.log(startDate);

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const availableTurfs = await Promise.all(
          turfs.map(async (filteredTurf) => {
            try {
              // Loop through all available turfs
              for (const turf of filteredTurf.available_turfs) {
                const calculatedSlots = await calculateSlots(
                  turf,
                  startDate,
                  filteredTurf.turf_id,
                  filteredTurf.availability.from,
                  filteredTurf.availability.to,
                  filteredTurf.slot_time
                );
                const availableSlots = calculatedSlots.filter(
                  (slot) => slot.available
                );

                // If any of the available turfs have available slots, return the filteredTurf
                if (availableSlots.length) {
                  return filteredTurf;
                }
              }
              return null; // No available slots in any of the available turfs
            } catch (error) {
              console.error("Error calculating slots:", error);
              return null;
            }
          })
        );

        // Filter out the null values (those turfs with no available slots)
        const validTurfs = availableTurfs.filter((turf) => turf !== null);
        setFilteredTurfs(validTurfs);
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    };

    fetchSlots();
  }, [turfs, startDate]);

  return (
    <div className="relative z-10 p-[2vw] top-0 max-w-full md:mx-[4vw] md:mb-[4vw] mb-[20vw]">
      {/* Images positioned at corners */}
      <div className="hidden md:block absolute top-[3.125vw] mr-[-4vw] right-[1.25vw] w-[3.375vw] h-[5.875vw]">
        <img src={img1} alt="" className="w-full h-full object-contain" />
      </div>
      <div className="hidden md:block absolute bottom-[-3vw] left-[4vw] w-[7vw] h-[4vw]">
        <img src={img2} alt="" className="w-full h-full object-contain" />
      </div>
      <p className="font-poppins font-semibold text-[5vw] md:text-[2vw] text-[#27346A] my-[1vw] leading-[160%] tracking-[0.6px]">
        Search Turfs by Availability
      </p>
      <div className="flex flex-col md:flex-row justify-between items-center mr-[2vw] px-[2vw] py-[1vw] text-[#1355FF]">
        <div className="flex flex-col md:flex-row gap-[4.325vw] md:gap-[1vw] mb-[1vw] md:mb-0">
          <div className="z-50">
            <DateSelector startDate={startDate} setStartDate={setStartDate} />
          </div>
          <div className="search-bar px-[2.5vw] md:px-[0.5vw] flex items-center rounded-lg overflow-hidden bg-[#E7EEFF]">
            <img
              src={searchIcon}
              alt="Search"
              className="search-icon w-[7.5vw] h-[7.5vw] md:w-[1vw] md:h-[1vw]"
            />
            <input
              type="text"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="Search..."
              className="w-full py-[2.5vw] px-[5vw] md:py-[0.5vw] md:px-[1vw] text-[4.325vw] md:text-[1vw] rounded-[2.5vw] border-2 border-solid border-[#e7eeff] md:rounded-[0.5vw] bg-[#e7eeff] text-[#27346a] outline-none focus:z-[100] z-30"
            />
          </div>
        </div>
        <div className="self-end">
          <CustomSelect onChange={handleSelectChange} />
        </div>
      </div>
      {seeAll ? (
        <div className="my-[0.5vw] px-[2vw]">
          {filteredTurfs.length ? (
            <CardGrid turfs={filteredTurfs} />
          ) : (
            toast.error(
              `No available turfs on ${startDate}. Showing all turfs.`
            ) && setFilteredTurfs(turfs)
          )}
        </div>
      ) : (
        <div className="my-[0.5vw]">
          {filteredTurfs.length ? (
            <Cardslider turfs={filteredTurfs} />
          ) : (
            notify()
          )}
        </div>
      )}
      {filteredTurfs.length ? (
        <div className="flex px-2 my-4 sm:px-8 md:px-16 py-2 sm:py-4 text-[#1355FF] justify-center cursor-pointer">
          <div
            onClick={() => {
              setSeeAll(!seeAll);
            }}
          >
            {seeAll ? "See Less" : "See All"}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default SearchVenues;
