import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateSelector.css";

const DateSelector = ({ startDate, setStartDate }) => {
  return (
    <div className="date-selector z-30 font-poppins text-[1vw] mt-[5vw] md:mt-0">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date.toISOString().split("T")[0])}
        dateFormat="MMMM d, yyyy"
        className="w-full md:w-[80%] py-[2.5vw] px-[5vw] md:py-[0.5vw] md:px-[1vw] text-[4.325vw] md:text-[1vw] rounded-[2.5vw] border-2 border-solid border-[#e7eeff] md:rounded-[0.5vw] bg-[#e7eeff] text-[#27346a] outline-none focus:border-[#27346a] focus:z-[100] z-30 cursor-pointer"
        popperClassName="custom-datepicker-popper"
      />
    </div>
  );
};

export default DateSelector;
