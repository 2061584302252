import React, { useState } from "react";
import "./RoleToggleButton.css";

const RoleToggleButton = ({ role, handleToggle }) => {
  return (
    <div
      className={`role-toggle-container ${
        role === "Turf Owner" ? "role-toggled" : ""
      }`}
      onClick={handleToggle}
    >
      <div
        className={`role-toggle-button ${
          role === "Turf Owner" ? "role-toggled" : ""
        }`}
      ></div>
    </div>
  );
};

export default RoleToggleButton;
