import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  deleteUser,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "./firebase";
import { toast } from "react-toastify";

// ----------> EMAIL & PASSWORD <---------- //
// SIGN UP
export const signUpWithEmail = async (email, password, userData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // Store user data in Firestore
    await createUserInFirestore(user.uid, userData);
    // console.log("User signed up and stored in Firestore");
  } catch (error) {
    console.error("Error signing up with email and password:", error.message);
  }
};

// SIGN IN
export const signInWithEmail = async (email, password) => {
  try {
    // Step 1: Sign in the user with email and password
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // Step 2: Fetch the user's data from Firestore using the UID
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      // console.log("User data fetched successfully:", userData);

      // Step 3: Return the user data
      return { uid: user.uid, ...userData };
    } else {
      console.error("No such user data found in Firestore.");
      return null;
    }
  } catch (error) {
    console.error("Error signing in with email and password:", error.message);
    throw error;
  }
};

// ----------> GOOGLE <---------- //
// SIGN IN
export const signInWithGoogle = async (userSchema, role) => {
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Check if it's a new user
    if (result._tokenResponse.isNewUser) {
      const userData = {
        ...userSchema,
        name: user.displayName,
        email: user.email,
        profile_picture: user.photoURL,
        role: role,
      };
      await createUserInFirestore(user.uid, userData); // Assume this creates user data in Firestore
    }

    // Fetch the user's data from Firestore
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      // Return user data in the same format as signInWithEmail
      return { uid: user.uid, ...userData };
    } else {
      // User not found in Firestore, delete from Firebase Authentication
      console.error(
        "User not found in Firestore. Deleting user from Firebase Authentication."
      );

      await deleteUser(user); // Deletes the user from Firebase Authentication
      toast.error("User does not exist.");
      return null;
    }
  } catch (error) {
    console.error("Error signing in with Google:", error.message);

    // Check if the user was created but something failed afterward, and delete them
    if (auth.currentUser) {
      await deleteUser(auth.currentUser);
      toast.error("User does not exist.");
    }

    throw error;
  }
};

// ----------> APPLE <---------- //
// SIGN IN
export const signInWithApple = async (userSchema) => {
  const provider = new OAuthProvider("apple.com");

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Check if it's a new user
    if (result._tokenResponse.isNewUser) {
      const userData = {
        name: user.displayName,
        email: user.email,
      };
      await createUserInFirestore(user.uid, userData);
    }

    // console.log("User signed in with Apple:", user);
  } catch (error) {
    console.error("Error signing in with Apple:", error.message);
  }
};

// Add user in users collection in Firestore
export const createUserInFirestore = async (uid, userData) => {
  try {
    await setDoc(doc(db, "users", uid), {
      uid: uid,
      ...userData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    // console.log("User data stored in Firestore");
  } catch (error) {
    console.error("Error storing user data in Firestore:", error.message);
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    console.log("User signed out successfully");
  } catch (error) {
    console.error("Error signing out:", error.message);
  }
};
