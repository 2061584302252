import React, { useState } from "react";
import "./JoinTheAction.css";
import { db } from "../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const JoinTheAction = ({ bookings }) => {
  const [showFields, setShowFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [whatsappLink, setWhatsappLink] = useState("");
  const [selectedBooking, setSelectedBooking] = useState("");
  const today = new Date().toISOString().split("T")[0];

  const handleSelectChange = (e) => {
    setSelectedBooking(e.target.value);
  };

  const updateBookingStatus = async (bookingId, whatsappLink) => {
    try {
      // Reference to the booking document
      const bookingRef = doc(db, "bookings", bookingId);

      // Fetch the booking document
      const bookingSnap = await getDoc(bookingRef);

      // Check if the document exists
      if (bookingSnap.exists()) {
        // Update the status to 'recruiting' and add the whatsappLink
        await updateDoc(bookingRef, {
          status: "recruiting",
          whatsappLink: whatsappLink,
        });

        // console.log("Booking updated successfully!");
        toast.success("Booking updated successfully!");
      } else {
        console.log("No such document found!");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const saveBookingChanges = () => {
    if (!whatsappLink) {
      toast.error("WhatsApp link required!");
    } else if (!selectedBooking) {
      toast.error("Select a booking!");
    } else {
      updateBookingStatus(selectedBooking, whatsappLink);
    }
  };

  return (
    <div className="py-8 sm:p-12 sm:mx-16 mb-[20vw] md:mb-0">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold text-[#4277FF] ">Join The Action!</h2>
        <div
          className={`relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease`}
        >
          <input
            type="checkbox"
            name="toggle"
            id="toggle"
            checked={showFields}
            onChange={() => setShowFields(!showFields)}
            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
          />
          <label
            htmlFor="toggle"
            className={`${
              showFields ? "toggle-label" : ""
            } block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer`}
          ></label>
        </div>
      </div>
      <p className="mb-4">
        Need more players? List your game here and find teammates instantly!
      </p>
      {showFields && (
        <div className="space-y-4">
          <div>
            <select
              className="p-2 border border-gray-300 rounded-md"
              value={selectedBooking}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                Select a booking
              </option>
              {bookings.map(
                (booking) =>
                  booking.status === "paid" &&
                  booking.bookingDate >= today && (
                    <option key={booking.id} value={booking.id}>
                      {`${booking.turfName} - ${booking.bookingDate} - ${booking.start_time}`}
                    </option>
                  )
              )}
            </select>
          </div>
          {/* <div>
            <label className="block text-left font-semibold text-[#4277FF]">
              Number of Players Needed
            </label>
            <input
              type="number"
              value={numPlayers}
              onChange={(e) => setNumPlayers(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div> */}
          <div>
            <label className="block text-left font-semibold text-[#4277FF]">
              Link to Whatsapp Group
            </label>
            <input
              type="text"
              value={whatsappLink}
              onChange={(e) => setWhatsappLink(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <button
            className={`bg-[#4277FF] text-white py-2 px-4 rounded mt-4 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
            onClick={saveBookingChanges}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      )}
    </div>
  );
};

export default JoinTheAction;
