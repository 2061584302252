import React, { useEffect, useState } from "react";
import { fetchAllBookings, fetchAllTurfs } from "../../firebase/turfController";
import { useSelector } from "react-redux";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const MyDashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [turfs, setTurfs] = useState([]);
  const [bookingsForMyTurfs, setBookingsForMyTurfs] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Page state
  const bookingsPerPage = 5; // Number of bookings to show per page
  const user = useSelector((state) => state.auth.user);
  const [sortByDate, setSortByDate] = useState(""); // Date sort state
  const [filterByStatus, setFilterByStatus] = useState(""); // Status filter state

  // Fetch turfs and bookings data
  useEffect(() => {
    fetchAllBookings().then((bookings) => {
      const filteredBookings = bookings.filter(
        (booking) => booking.status !== "ongoing"
      );
      setBookings(filteredBookings);
    });
    fetchAllTurfs().then((turfs) => {
      const myTurfs = turfs.filter(
        (turf) => turf.owner_details.owner_id === user.uid
      );
      setTurfs(myTurfs);
    });
  }, [user]);

  // Filter bookings based on the turfs owned by the user
  useEffect(() => {
    if (turfs.length && bookings.length) {
      const filteredBookings = bookings.filter((booking) =>
        turfs.some((turf) => turf.turf_id === booking.turfId)
      );
      setBookingsForMyTurfs(filteredBookings);
    }
  }, [turfs, bookings]);

  // Sorting and filtering logic
  useEffect(() => {
    let sortedFilteredBookings = [...bookingsForMyTurfs];

    // Sort by Date
    if (sortByDate === "newest") {
      sortedFilteredBookings.sort(
        (a, b) => new Date(b.bookingDate) - new Date(a.bookingDate)
      );
    } else if (sortByDate === "oldest") {
      sortedFilteredBookings.sort(
        (a, b) => new Date(a.bookingDate) - new Date(b.bookingDate)
      );
    }

    // Filter by Status
    if (filterByStatus) {
      sortedFilteredBookings = sortedFilteredBookings.filter((booking) => {
        if (filterByStatus === "paid") {
          return new Date() < new Date(booking.bookingDate);
        } else if (filterByStatus === "completed") {
          return booking.completed;
        } else {
          return (
            new Date() > new Date(booking.bookingDate) && !booking.completed
          );
        }
      });
    }

    setFilteredBookings(sortedFilteredBookings);
    setCurrentPage(1); // Reset to first page on sort/filter change
  }, [sortByDate, filterByStatus, bookingsForMyTurfs]);

  // Calculate the current bookings to display based on pagination
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = filteredBookings.slice(
    indexOfFirstBooking,
    indexOfLastBooking
  );

  // Function to handle the Next button
  const nextPage = () => {
    if (currentPage < Math.ceil(filteredBookings.length / bookingsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle the Prev button
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  async function updateBookingStatus(bookingId, status) {
    try {
      // Reference to the document in 'bookings' collection
      const bookingRef = doc(db, "bookings", bookingId);

      // Fetch the document
      const bookingSnap = await getDoc(bookingRef);

      // Check if the document exists
      if (bookingSnap.exists()) {
        // Update the 'completed' field
        await updateDoc(bookingRef, {
          completed: status ? false : true,
        });
        console.log(`Booking ${bookingId} updated successfully.`);
      } else {
        console.log("No such document found!");
      }
      window.location.reload();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  return (
    <div className="md:max-w-[80vw] mx-auto px-[2.5vw] py-[10vw] md:p-[2vw] mt-[2vw] bg-white rounded-lg shadow-md">
      {/* Heading */}
      <h1 className="text-center text-[5vw] md:text-[2vw] mb-[1.5vw] font-poppins font-semibold text-[#27346A]">
        Dashboard
      </h1>

      {/* Sub-heading */}
      <h2 className="text-[4.325vw] md:text-[1.25vw] mb-[5vw] md:mb-[1vw] font-poppins font-medium text-[#4277FF]">
        My Orders
      </h2>

      <p className="md:text-[1vw] text-[3.25vw] mb-[5vw] md:mb-[1vw]">
        Note: Click on the booking status to mark it 'Completed' or 'Expired'
      </p>

      {/* Sort and Filter Group */}
      <div className="flex space-x-[1vw] mb-[7.5vw] md:mb-[1.5vw]">
        <select
          className=" px-[1vw] py-[0.5vw] border rounded-[0.5vw] bg-gray-100 focus:outline-none text-[3.25vw] md:text-[1vw]"
          onChange={(e) => setSortByDate(e.target.value)}
        >
          <option value="">Date</option>
          <option value="newest">Newest First</option>
          <option value="oldest">Oldest First</option>
        </select>

        <select
          className="px-[1vw] py-[0.5vw] border rounded-[0.5vw] bg-gray-100 focus:outline-none text-[3.25vw] md:text-[1vw]"
          onChange={(e) => setFilterByStatus(e.target.value)}
        >
          <option value="">Status</option>
          <option value="paid">Paid</option>
          <option value="completed">Completed</option>
          <option value="expired">Expired</option>
        </select>
      </div>

      {/* Table */}
      <div className="overflow-scroll">
        <table className="min-w-full bg-white border border-gray-300 drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[0.5vw]">
          <thead>
            <tr className="bg-gray-100 drop-shadow-[0px_2px_8px_rgba(0,0,0,0.25)]">
              <th className="py-[0.75vw] px-[1.5vw] text-[3.25vw] md:text-[1.5vw] text-left border-b text-[#61646B] font-poppins font-semibold">
                User ID
              </th>
              <th className="py-[0.75vw] px-[1.5vw] text-[3.25vw] md:text-[1.5vw] text-left border-b text-[#61646B] font-poppins font-semibold">
                Date
              </th>
              <th className="py-[0.75vw] px-[1.5vw] text-[3.25vw] md:text-[1.5vw] text-left border-b text-[#61646B] font-poppins font-semibold">
                Timing
              </th>
              <th className="py-[0.75vw] px-[1.5vw] text-[3.25vw] md:text-[1.5vw] text-left border-b text-[#61646B] font-poppins font-semibold">
                Venue
              </th>
              <th className="py-[0.75vw] px-[1.5vw] text-[3.25vw] md:text-[1.5vw] text-left border-b text-[#61646B] font-poppins font-semibold">
                Turf
              </th>
              <th className="py-[0.75vw] px-[1.5vw] text-[3.25vw] md:text-[1.5vw] text-left border-b text-[#61646B] font-poppins font-semibold">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {currentBookings.length > 0 ? (
              currentBookings.map((booking) => (
                <tr key={booking.bookingId}>
                  <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                    {booking.userId.slice(0, 5) + "..."}
                  </td>
                  <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                    {booking.bookingDate}
                  </td>
                  <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                    {booking.start_time}-{booking.end_time}
                  </td>
                  <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                    {booking.turfName}
                  </td>
                  <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                    {booking.selectedTurf.turfNumber}
                  </td>
                  {/* <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                    <span
                      className={`w-fit whitespace-nowrap inline-block px-[2.5vw] py-[1.25vw] md:px-[0.75vw] md:py-[0.25vw] rounded-full text-[2.5vw] md:text-[0.75vw] font-semibold ${
                        booking.status === "paid"
                          ? "bg-[#00B69B]/20 text-[#00B69B]"
                          : booking.status === "recruiting"
                          ? "bg-[#6226EF]/20 text-[#6226EF]"
                          : "bg-[#FF7391]/20 text-[#FF7391]"
                      }`}
                    >
                      {booking.status.toLocaleUpperCase()}
                    </span>
                  </td> */}
                  {booking.completed ? (
                    <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                      <span
                        id={booking.booking_id}
                        onClick={() => {
                          updateBookingStatus(
                            booking.booking_id,
                            booking.completed
                          );
                        }}
                        className={`cursor-pointer w-fit whitespace-nowrap inline-block px-[2.5vw] py-[1.25vw] md:px-[0.75vw] md:py-[0.25vw] rounded-full text-[2.5vw] md:text-[0.75vw] font-semibold text-[#00B69B] bg-[#00B69B]/20`}
                      >
                        COMPLETED
                      </span>
                    </td>
                  ) : new Date() < new Date(booking.bookingDate) ? (
                    <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                      <span
                        className={`cursor-pointer w-fit whitespace-nowrap inline-block px-[2.5vw] py-[1.25vw] md:px-[0.75vw] md:py-[0.25vw] rounded-full text-[2.5vw] md:text-[0.75vw] font-semibold text-[#6226EF] bg-[#6226EF]/20`}
                      >
                        PAID
                      </span>
                    </td>
                  ) : (
                    <td className="w-fit whitespace-nowrap py-[0.75vw] px-[1.5vw] border-b text-[#61646B] font-poppins font-normal text-[3.25vw] md:text-[1vw]">
                      <span
                        id={booking.booking_id}
                        onClick={() => {
                          updateBookingStatus(
                            booking.booking_id,
                            booking.completed
                          );
                        }}
                        className={`cursor-pointer w-fit whitespace-nowrap inline-block px-[2.5vw] py-[1.25vw] md:px-[0.75vw] md:py-[0.25vw] rounded-full text-[2.5vw] md:text-[0.75vw] font-semibold text-[#FF7391] bg-[#FF7391]/20`}
                      >
                        EXPIRED
                      </span>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="py-[5vw] px-[7.5vw] md:py-[0.75vw] md:px-[1.5vw] text-[3.25vw] md:text-[1.5vw] border-b text-center text-gray-500"
                >
                  No bookings found for your turfs.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-[7.5vw] md:mt-[1.5vw]">
        <span className="text-gray-600 text-[4.325vw] md:text-[0.875vw]">
          Showing {(currentPage - 1) * 5 + 1}-
          {Math.min(currentPage * 5, filteredBookings.length)} of{" "}
          {filteredBookings.length}
        </span>
        <div className="border border-[#D5D5D5] rounded-[2.5vw] md:rounded-[0.5vw]">
          <button
            className={`border-r border-[#D5D5D5] px-[2.5vw] py-[1.25vw] md:px-[0.5vw] md:py-[0.25vw] text-[4.325vw] md:text-[1.25vw] text-[#818588]/60 ${
              currentPage === 1 ? "cursor-not-allowed" : ""
            }`}
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <button
            className={`px-[2.5vw] py-[1.25vw] md:px-[0.5vw] md:py-[0.25vw] text-[4.325vw] md:text-[1.25vw] text-[#202224] ${
              currentPage >=
              Math.ceil(filteredBookings.length / bookingsPerPage)
                ? "cursor-not-allowed"
                : ""
            }`}
            onClick={nextPage}
            disabled={
              currentPage >=
              Math.ceil(filteredBookings.length / bookingsPerPage)
            }
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;
