import { useState } from "react";
import logo from "../../assets/homepage/image 2.png";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
const AdminLogin = ({ setIsAdmin }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const loginUser = async (e) => {
    e.preventDefault();
    if (
      email === process.env.REACT_APP_ADMIN_EMAIL &&
      password === process.env.REACT_APP_ADMIN_PASSWORD
    ) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredentials) => {
          localStorage.setItem("isAdmin", "true");
        })
        .catch((error) => {
          console.log(error);
        });

      localStorage.setItem("isAdmin", "true");
      setIsAdmin(true);
    } else {
      console.log(
        email === process.env.REACT_APP_ADMIN_EMAIL,
        password === process.env.REACT_APP_ADMIN_PASSWORD
      );

      alert("Check credentials!");
    }
  };

  return (
    <div className=" flex justify-center items-center flex-col  h-screen gap-8">
      <img src={logo} className="w-40" />
      <div className=" flex flex-col gap-4 ">
        <form action="" onSubmit={loginUser} className=" mt-2">
          <div className=" bg-gray-100 flex flex-col items-center px-4 pt-8 gap-4 rounded-xl border  shadow pb-3 w-[22vw] ">
            <span className=" flex flex-col w-full">
              <p className=" font-light text-[1.5vw]">Username</p>
              <input
                type="text"
                name="username"
                id="username"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className=" bg-white border border-black rounded-md py-1 text-xl pl-1  font-light"
              />
            </span>
            <span className=" flex flex-col w-full">
              <p className=" font-light text-[1.5vw]">Password</p>
              <input
                type="password"
                name="password"
                value={password}
                id="password"
                className=" bg-white border border-black rounded-md py-1 text-xl pl-1 "
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </span>
            <button
              className=" bg-stone-700 text-white pb-1 pt-2 w-fit px-16 my-4  rounded-md text-xl font-light"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
