import React, { useEffect, useState } from "react";
import Cardslider from "./Cardslider";
import img1 from "../../assets/homepage/shape_110.png";
import img2 from "../../assets/homepage/shape_04.png";
import plus from "../../assets/homepage/Plus.png";
import { useSelector } from "react-redux";
import CustomSelect from "./CustomSelect";
import { Link } from "react-router-dom";

const YourTurfs = ({ turfs }) => {
  const user = useSelector((state) => state.auth.user);
  const [filteredTurfs, setFilteredTurfs] = useState(turfs);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (turfs.length) {
      let sortedTurfs = turfs.filter(
        (turf) => turf.owner_details.owner_id === user.uid
      );

      // Apply sorting based on the selected option
      if (filter === "Ratings: High to Low") {
        sortedTurfs = sortedTurfs.sort(
          (a, b) => b.ratings[0] / b.ratings[1] - a.ratings[0] / a.ratings[1]
        );
      } else if (filter === "Pricing: Low to High") {
        sortedTurfs = sortedTurfs.sort((a, b) => a.price - b.price);
      } else if (filter === "Pricing: High to Low") {
        sortedTurfs = sortedTurfs.sort((a, b) => b.price - a.price);
      }

      setFilteredTurfs(sortedTurfs);
    }
  }, [turfs, user, filter]);

  const handleSelectChange = (selectedValue) => {
    setFilter(selectedValue);
    // console.log("Selected Filter:", selectedValue);
  };

  return (
    <div className="relative z-10 p-[2vw] top-0 max-w-full md:mx-[4vw] md:mb-[4vw] mb-[20vw]">
      {/* Images positioned at corners */}
      <div className="hidden md:block absolute top-[3.125vw] mr-[-4vw] right-[1.25vw] w-[3.375vw] h-[5.875vw]">
        <img src={img1} alt="" className="w-full h-full object-contain" />
      </div>
      <div className="hidden md:block absolute bottom-[-3vw] left-[4vw] w-[7vw] h-[4vw]">
        <img src={img2} alt="" className="w-full h-full object-contain" />
      </div>
      <p className="font-poppins font-semibold text-[5vw] md:text-[2vw] text-[#27346A] my-[1vw] leading-[160%] tracking-[0.6px]">
        Your Turfs
      </p>
      <div className="flex flex-col md:flex-row gap-[5vw] md:justify-between md:items-center mr-[2vw] px-[2vw] py-[1vw]">
        <Link
          to={`/listYourTurf/${user.uid}`}
          className="self-start md:self-auto underline underline-offset-4 md:no-underline"
        >
          <div className="md:bg-[#E7EEFF] py-[2.5vw] md:py-[0.5vw] md:px-[1vw] rounded-full cursor-pointer flex gap-[2.5vw] md:gap-[1vw] items-center justify-center">
            <p className="text-[3.75vw] leading-[6.25vw] tracking-[0.08px] md:text-[1vw] md:leading-[2.25vw] md:tracking-[0.45px]">
              Add a Turf Listing Here
            </p>
            <div className="w-[5vw] h-[5vw] md:w-[1.5vw] md:h-[1.5vw]">
              <img className="w-full h-full object-contain" src={plus} alt="" />
            </div>
          </div>
        </Link>
        <div className="text-[#1355FF]">
          <CustomSelect onChange={handleSelectChange} />
        </div>
      </div>
      <div className="my-[0.5vw]">
        {filteredTurfs.length ? (
          <Cardslider turfs={filteredTurfs} editable={true} />
        ) : (
          <p className="p-[2vw]">
            You don't have any turfs listed. Check the 'List Turf' feature on
            the profile page.
          </p>
        )}
      </div>
    </div>
  );
};

export default YourTurfs;
