import React from "react";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import BookingCard from "../components/BookingCard";
import { CiSearch } from "react-icons/ci";

const Bookings = () => {
  const [fetchedBookings, setFetchedBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [searchBooking, setSearchBooking] = useState("");
  const [searchParameter, setSearchParameter] = useState("turfName");
  const [showUnpaid, setShowUnpaid] = useState(false);

  const fetchAllBookings = async () => {
    try {
      const bookingsRef = collection(db, "bookings");
      const bookingsSnapshot = await getDocs(bookingsRef);

      const bookingsList = bookingsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setFetchedBookings(bookingsList); // Update state with fetched bookings
      console.log("Bookings fetched successfully:", bookingsList);
    } catch (error) {
      console.error("Error fetching bookings:", error.message);
    }
  };

  useEffect(() => {
    fetchAllBookings();
  }, []);

  useEffect(() => {
    const filterTurfs = () => {
      if (searchBooking === "" || searchParameter === "") {
        return fetchedBookings;
      }

      return fetchedBookings.filter((fetchedBooking) => {
        return fetchedBooking[searchParameter]
          .toLowerCase()
          .includes(searchBooking.toLowerCase());
      });
    };

    if (fetchedBookings && fetchedBookings.length > 0) {
      const result = filterTurfs();
      setFilteredBookings(result);
    }
  }, [fetchedBookings, searchBooking, searchParameter]);

  return (
    <div className="w-full h-screen overflow-y-scroll">
      <div className=" flex items-center gap-3">
        <button
          onClick={() => {
            setSearchBooking("");
            setSearchParameter("");
          }}
          className=" bg-zinc-700 text-lg pt-2 pb-1 px-3 rounded-md text-white font-light"
        >
          View all
        </button>

        <div className=" flex px-2 border border-black py-1 rounded-md gap-2">
          <span className=" pt-1">
            <CiSearch size={20} />
          </span>
          <input
            onChange={(e) => {
              setSearchBooking(e.target.value);
            }}
            placeholder="Search Booking"
            className="pt-1 focus:outline-none w-[15vw]"
          />
        </div>
        <button
          onClick={() => {
            setSearchParameter("turfName");
          }}
          className={`text-lg pt-2 pb-1 px-3 rounded-md ${
            searchParameter === "turfName"
              ? "bg-zinc-700 text-white"
              : "border-[1px] border-zinc-700"
          } font-light`}
        >
          Search by Name
        </button>
        <button
          onClick={() => setShowUnpaid(!showUnpaid)}
          className={`text-lg pt-2 pb-1 px-3 rounded-md ${
            showUnpaid
              ? "bg-zinc-700 text-white"
              : "border-[1px] border-zinc-700"
          } font-light`}
        >
          Show Unpaid
        </button>
      </div>
      <div className="my-4">
        <p className=" font-light text-xl">
          Results: Found {filteredBookings?.length} Bookings{" "}
          {!(searchParameter === "" || searchBooking === "") && `for that turf`}
        </p>
      </div>
      <div className="flex flex-col gap-[40px] items-center pb-[64px]">
        {showUnpaid
          ? filteredBookings
              .filter((card) => !card.turfOwnerPayOut) // Show unpaid bookings (turfOwnerPayOut === false)
              .map((card, index) => (
                <BookingCard
                  key={index}
                  bookingId={card.id}
                  turfId={card.turfId}
                  title={card.turfName}
                  address={card.turfAddress}
                  image={card.turfImage}
                  time={`${card.start_time} - ${card.end_time}`}
                  date={card.bookingDate}
                  ratings={card.turfRatings}
                  selectedTurf={card.selectedTurf}
                  horizontal={true}
                  isDiscover={true}
                  turfOwnerPayOut={card.turfOwnerPayOut}
                />
              ))
          : filteredBookings.map((card, index) => (
              <BookingCard
                key={index}
                bookingId={card.id}
                turfId={card.turfId}
                title={card.turfName}
                address={card.turfAddress}
                image={card.turfImage}
                time={`${card.start_time} - ${card.end_time}`}
                date={card.bookingDate}
                ratings={card.turfRatings}
                selectedTurf={card.selectedTurf}
                horizontal={true}
                isDiscover={true}
                turfOwnerPayOut={card.turfOwnerPayOut}
              />
            ))}
      </div>
    </div>
  );
};

export default Bookings;
