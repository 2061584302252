import React, { useEffect, useState } from "react";
import TurfRules from "./TurfRules";
import ImageSlider from "./ImageSlider";
import Details from "./Details";
import Location from "./Location";
import BookingGrid from "./BookingGrid";
import image from "../../assets/homepage/image 41.png";
import star from "../../assets/homepage/Star.png";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom";
import { fetchTurfById } from "../../firebase/turfController";
import Amenities from "./Amenities";
import TurfDisplay from "./TurfDisplay";

const TurfDetails = () => {
  const [turfDetails, setTurfDetails] = useState(null);
  const [error, setError] = useState(null);
  const turfId = useParams().turfid;

  useEffect(() => {
    const getTurfDetails = async () => {
      try {
        const turf = await fetchTurfById(turfId);
        setTurfDetails(turf);
      } catch (err) {
        console.error("Error fetching turf details:", err.message);
        setError("Failed to fetch turf details. Please try again later.");
      }
    };

    getTurfDetails();
  }, [turfId]);

  if (error) {
    return <div className="text-center text-red-500 mt-4">{error}</div>;
  }

  if (!turfDetails) {
    return <div className="text-center mt-4">Loading turf details...</div>;
  }

  return (
    <div>
      <div className="mb-[20vw] md:mb-0  md:p-[1.875vw] my-[6.25vw] md:my-[3.125vw] flex flex-col  md:gap-[1.875vw]">
        {/* TOP SECTION {IMAGES || LOCATION} */}
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="md:col-span-7 justify-self-center md:justify-self-start">
            <ImageSlider images={turfDetails.images} />
          </div>
          {/* MAP VISIBLE FOR LAPTOP SCREENS */}
          <div className="hidden md:flex justify-center md:col-span-5 justify-self-end">
            <Location
              address={turfDetails.address}
              geo_coordinates={turfDetails.geo_coordinates}
            />
          </div>
        </div>
        {/* MID SECTION {DETAILS || BOOKING} */}
        <div className="grid grid-cols-1 md:grid-cols-12">
          {/* TURF DETAILS */}
          <div className="col-span-7 px-[4vw] flex flex-col gap-[6.25vw] md:gap-[1.875vw] justify-self-start">
            <div className="w-[88vw] md:w-[37.5vw] grid grid-cols-2 gap-[5vw] md:gap-[1vw]">
              <p className="text-[4.375vw] md:text-[1.5vw] font-medium font-poppins">
                {turfDetails.name}
              </p>
              <p className="text-[4.375vw] md:text-[1.25vw] font-poppins justify-self-end">
                {turfDetails.availability.from} - {turfDetails.availability.to}
              </p>
              <p className="text-[4.375vw] md:text-[1.25vw] font-poppins">
                {turfDetails.slot_time} Slots
              </p>
              <p className="text-[4.375vw] md:text-[1.25vw] font-poppins flex items-center justify-self-end">
                <img
                  src={star}
                  alt="Star"
                  className="w-[5vw] h-[5vw] mr-[3.125vw] md:w-[1.5vw] md:h-[1.5vw] md:mr-[0.25vw]"
                />
                {turfDetails.ratings[1] > 0
                  ? (turfDetails.ratings[0] / turfDetails.ratings[1]).toFixed(1)
                  : 0}
                ({turfDetails.ratings[1]})
              </p>
            </div>
            {/* MAP VISIBLE ON MOBILE SCREENS */}
            <div className="block md:hidden max-w-[92.5vw]">
              <Location
                address={turfDetails.address}
                geo_coordinates={turfDetails.geo_coordinates}
              />
            </div>
            <div className="mt-4 flex flex-col gap-[6.25vw] md:gap-[1.875vw]">
              <div className="col-span-1 flex flex-col gap-[5vw] md:gap-[1.875vw]">
                <p className="font-poppins font-medium text-[4.375vw] md:text-[1.5vw] text-[#4277FF] tracking-[0.4px] leading-[1.5vw]">
                  Facility Amenities
                </p>
                <div className="flex flex-wrap gap-[3.125vw] md:gap-[1.875vw]">
                  {turfDetails.amenities.length > 0 ? (
                    turfDetails.amenities.map((amenity, index) => (
                      <div
                        key={index}
                        className="w-fit py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[3.75vw] md:text-[1vw] text-[#61646B] border border-[#D9D9D9] bg-white rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]"
                      >
                        {amenity}
                      </div>
                    ))
                  ) : (
                    <p className="w-fit py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[3.75vw] md:text-[1vw] text-[#61646B] border border-[#D9D9D9] bg-white rounded-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.25)]">
                      None Listed
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-1 flex flex-col gap-[5vw] md:gap-[1.875vw]">
                <p className="font-poppins font-medium text-[4.375vw] md:text-[1.5vw] text-[#4277FF] tracking-[0.4px] leading-[1.5vw]">
                  Listed Turfs
                </p>
                <div className="flex flex-wrap gap-[3.125vw] md:gap-[1.875vw]">
                  <TurfDisplay turfs={turfDetails.available_turfs} />
                </div>
              </div>
            </div>
          </div>
          {/* BOOKING GRID */}
          <div className="col-span-5 justify-self-end mt-[6.25vw] md:mt-0">
            <BookingGrid
              turfName={turfDetails.name}
              turfImage={turfDetails.images[0]}
              turfAddress={turfDetails.address}
              availability={turfDetails.availability}
              slot_time={turfDetails.slot_time}
              availableTurfs={turfDetails.available_turfs}
              turfId={turfId}
            />
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <TurfRules rules={turfDetails.venue_rules} />
          </div>
        </div>
      </div>
      <div className="hidden sm:block md:mt-[4vw]">
        <Footer />
      </div>
    </div>
  );
};

export default TurfDetails;
