import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const WebsiteContent = () => {
  const [modal, setModal] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [changes, setChanges] = useState({});
  const [newField, setNewField] = useState({ key: "", value: "" });

  const fetchCompanyInfo = async () => {
    try {
      const infoRef = doc(db, "websiteContent", "IHwnw9MQy91xJ3SoCRMZ");
      const infoDoc = await getDoc(infoRef);
      if (infoDoc.exists()) {
        setCompanyInfo(infoDoc.data());
        setChanges(infoDoc.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCompanyInfo = async () => {
    try {
      const infoRef = doc(db, "websiteContent", "IHwnw9MQy91xJ3SoCRMZ");
      await setDoc(infoRef, changes); // Completely overwrite Firestore document with changes
      fetchCompanyInfo(); // Fetch updated data
      setModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  // Handle adding new key-value pair fields
  const handleAddNewField = () => {
    if (newField.key && newField.value) {
      setChanges({ ...changes, [newField.key]: newField.value });
      setNewField({ key: "", value: "" }); // Reset new field inputs
    }
  };

  return (
    <>
      <table className="mt-10 border border-black font-light text-lg">
        <thead>
          <tr className=" bg-zinc-600 text-white">
            <th className="w-[200px] text-left px-3 pr-5 pt-2 border border-black font-light">
              Key
            </th>
            <th className="w-[200px] text-left px-3 pr-5 pt-2 border border-black font-light">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(companyInfo)
            .sort() // Sort keys alphabetically
            .map((key) => (
              <tr key={key} className="border-black border">
                <td className="px-3 pr-5 border-black border bg-zinc-400">
                  {key}
                </td>
                <td className="px-3 pr-5 pt-2">{companyInfo[key]}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <button
        className="bg-zinc-800 text-white font-light mt-8 px-4 pt-3 pb-2 rounded-md"
        onClick={() => setModal(!modal)}
      >
        Edit Information
      </button>

      {modal && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-10"></div>
          <div className="fixed inset-0 flex justify-center items-center z-20">
            <div className="bg-gray-200 p-6 rounded shadow-lg flex flex-col gap-2 w-[30%] h-[80vh] overflow-y-scroll">
              {/* Existing fields */}
              {Object.keys(changes)
                .sort() // Sort keys alphabetically in modal
                .map((key) => (
                  <div key={key} className="flex flex-col gap-1 font-light">
                    <div className="flex items-center">
                      <span className="mr-2">{key}:</span>
                      <input
                        className="bg-white rounded-md pl-2 focus:outline-none text-lg flex-grow"
                        onChange={(e) =>
                          setChanges({ ...changes, [key]: e.target.value })
                        }
                        value={changes[key]}
                      />
                      {/* Delete button for the field */}
                      <button
                        className="ml-2 bg-red-500 text-white p-1 rounded"
                        onClick={() => {
                          const updatedChanges = { ...changes };
                          delete updatedChanges[key]; // Remove the field
                          setChanges(updatedChanges);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}

              {/* Add new field */}
              <div className="flex flex-col gap-1 font-light mt-4">
                <span>Add New Field:</span>
                <input
                  type="text"
                  className="bg-white rounded-md pl-2 focus:outline-none text-lg"
                  placeholder="Field name"
                  value={newField.key}
                  onChange={(e) =>
                    setNewField({ ...newField, key: e.target.value })
                  }
                />
                <input
                  type="text"
                  className="bg-white rounded-md pl-2 focus:outline-none text-lg mt-2"
                  placeholder="Field value"
                  value={newField.value}
                  onChange={(e) =>
                    setNewField({ ...newField, value: e.target.value })
                  }
                />
                <button
                  className="mt-2 bg-slate-800 text-white py-1 rounded-md"
                  onClick={handleAddNewField}
                >
                  Add Field
                </button>
              </div>

              {/* Buttons */}
              <div className="w-full flex justify-between items-center mt-4">
                <button
                  className="w-[30%] m-auto border border-slate-800 font-light py-2 rounded-md"
                  onClick={() => setModal(false)}
                >
                  Close
                </button>
                <button
                  className="w-[30%] m-auto bg-slate-800 text-white font-light py-2 rounded-md"
                  onClick={updateCompanyInfo}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WebsiteContent;
