import React, { useEffect, useState } from "react";
import filterImg from "../../assets/homepage/Filter.png";
import image from "../../assets/homepage/image 41.png"; // Example image
import img1 from "../../assets/homepage/shape_61.png";
import img2 from "../../assets/homepage/ic_sharp-sports-baseball.png";
import img3 from "../../assets/homepage/shape_13.png";
import CustomSelect from "./CustomSelect";
import { useSelector } from "react-redux";
import DiscoverCard from "./DiscoverCard";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { fetchTurfById } from "../../firebase/turfController";

const Discover = () => {
  const [bookings, setBookings] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("");
  const [seeAll, setSeeAll] = useState(false);

  // Function to fetch all bookings and append turf details
  useEffect(() => {
    const fetchBookingsAndTurfDetails = async () => {
      try {
        // Step 1: Fetch all bookings
        const bookingsSnapshot = await getDocs(collection(db, "bookings"));
        const bookingsData = bookingsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          bookingId: doc.id,
        }));

        // Step 2: Fetch turf details for each booking and append them
        const bookingsWithTurfDetails = await Promise.all(
          bookingsData.map(async (booking) => {
            // Fetch turf details using booking.turfId
            const turfDetails = await fetchTurfById(booking.turfId);
            // Append turf details to the booking object
            return { ...booking, turfDetails };
          })
        );

        // Step 3: Update state with the updated bookings
        setBookings(bookingsWithTurfDetails);
      } catch (err) {
        console.error("Error fetching bookings and turf details:", err.message);
        setError("Failed to fetch bookings or turf details.");
      }
    };

    fetchBookingsAndTurfDetails();
  }, []); // Empty dependency array to run only once when the component mounts

  const handleSelectChange = (selectedValue) => {
    setFilter(selectedValue);
    // console.log("Selected Filter:", selectedValue);
  };

  if (error) return <p>{error}</p>;
  if (bookings.length === 0) return <p>Loading...</p>;

  return (
    <div className="relative z-10 p-[2vw] top-0 max-w-full md:mx-[4vw] md:mb-[4vw] mb-[10vw]">
      {/* Images positioned at corners */}
      <div className="hidden md:block absolute top-0 right-[-10vw] w-[19vw] h-[7.625vw]">
        <img src={img1} alt="" className="w-full h-full object-contain" />
      </div>
      <div className="hidden md:block absolute bottom-0 mr-[-4vw] right-0 w-[4vw] h-[5.5vw]">
        <img src={img3} alt="" className="w-full h-full object-contain" />
      </div>
      <div className="hidden md:block absolute bottom-[-5vw] left-[-4vw] w-[16.5vw] h-[16.5vw]">
        <img src={img2} alt="" className="w-full h-full object-contain" />
      </div>
      <p className="font-poppins font-semibold text-[5vw] md:text-[2vw] text-[#27346A] my-[1vw] leading-[160%] tracking-[0.6px]">
        Be the Game Changer!
      </p>
      <p className="font-poppins text-[4.325vw] md:text-[1.5vw] font-medium leading-[5vw] md:leading-[3vw] tracking-[0.2px] md:tracking-[0.6px] text-[#61646b]">
        Ready to play but need more teammates? Discover Bookings looking for
        players like you!
      </p>
      <div className="flex justify-between items-center mr-[2vw] px-[2vw] py-[1vw] text-[#1355FF]">
        <div className="flex items-center gap-[1vw]">
          <div
            className="mb-[0.25vw] md:mb-0 text-[4.325vw] md:text-[1vw] cursor-pointer z-50"
            onClick={() => {
              setSeeAll(!seeAll);
            }}
          >
            {seeAll ? "See Less" : "See All"}
          </div>
        </div>
        <div className="">
          <CustomSelect onChange={handleSelectChange} />
        </div>
      </div>
      <div className="flex flex-col items-center gap-[1.875vw]">
        {seeAll
          ? bookings
              .filter(
                (booking) =>
                  booking.status === "recruiting" &&
                  new Date(booking.bookingDate) >= new Date()
              )
              ?.map((card, index) => (
                <DiscoverCard
                  key={index}
                  bookingId={card.bookingId}
                  turfId={card.turfDetails.turf_id}
                  title={card.turfDetails.name}
                  address={card.turfDetails.address}
                  image={card.turfDetails.images[0]}
                  time={`${card.start_time} - ${card.end_time}`}
                  date={card.bookingDate}
                  ratings={card.turfDetails.ratings}
                  selectedTurf={card.selectedTurf}
                  horizontal={true}
                  isDiscover={true}
                />
              ))
          : bookings
              .filter(
                (booking) =>
                  booking.status === "recruiting" &&
                  new Date(booking.bookingDate) >= new Date()
              )
              ?.map((card, index) => (
                <DiscoverCard
                  key={index}
                  bookingId={card.bookingId}
                  turfId={card.turfDetails.turf_id}
                  title={card.turfDetails.name}
                  address={card.turfDetails.address}
                  image={card.turfDetails.images[0]}
                  time={`${card.start_time} - ${card.end_time}`}
                  date={card.bookingDate}
                  ratings={card.turfDetails.ratings}
                  selectedTurf={card.selectedTurf}
                  horizontal={true}
                  isDiscover={true}
                />
              ))}
      </div>
    </div>
  );
};

export default Discover;
