import React, { useEffect, useState } from "react";
import TurfRules from "./TurfRules";
import ImageSlider from "./ImageSlider";
import Details from "./Details";
import Location from "./Location";
import Footer from "../../components/Footer";
import image from "../../assets/homepage/image 41.png";
import imgwb from "../../assets/turfDetails/image 65.png";
import star from "../../assets/homepage/Star.png";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBookingById, fetchTurfById } from "../../firebase/turfController";

const CommunityJoin = () => {
  const { bookingId } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [turfDetails, setTurfDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBookingAndTurfDetails = async () => {
      try {
        // Step 1: Fetch the booking details using the bookingId
        const booking = await fetchBookingById(bookingId);
        setBookingDetails(booking);

        // Step 2: Fetch the turf details using the booking's turfId
        const turf = await fetchTurfById(booking.turfId);
        setTurfDetails(turf);
      } catch (err) {
        console.error("Error fetching booking or turf details:", err.message);
        setError(
          "Failed to fetch booking or turf details. Please try again later."
        );
      }
    };

    getBookingAndTurfDetails();
  }, [bookingId]);

  const handleJoinWhatsApp = () => {
    window.open(bookingDetails.whatsappLink, "_blank");
  };

  if (error) return <p>{error}</p>;
  if (!bookingDetails || !turfDetails)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <p>Loading...</p>
      </div>
    );

  return (
    <>
      <div className="mb-[20vw] md:mb-0 mt-[6.25vw] md:mt-[3.125vw] mx-[1.875vw] flex flex-col gap-[9.375vw] md:gap-[3.125vw]">
        {/* TOP SECTION {IMAGES || LOCATION} */}
        <div className="flex gap-[3.125vw]">
          <div className="flex-1">
            <ImageSlider images={turfDetails.images} />
          </div>
          {/* MAP VISIBLE FOR LAPTOP SCREENS */}
          <div className="hidden md:block flex-1">
            <Location
              address={turfDetails.address}
              geo_coordinates={turfDetails.geo_coordinates}
            />
          </div>
        </div>
        <div className="flex flex-col mx-[4vw]">
          <p className="text-[5vw] md:text-[2vw] font-poppins font-medium tracking-[0.6px] leading-[160%]">
            {turfDetails.name}
          </p>
        </div>
        <div className="w-[88vw] md:w-[37.5vw] grid grid-cols-2 gap-[5vw] md:gap-[1vw] mx-[4vw]">
          <p className="text-[4.375vw] md:text-[1.5vw] font-poppins">
            {bookingDetails.start_time} - {bookingDetails.end_time}
          </p>
          <p className="text-[4.375vw] md:text-[1.5vw] font-poppins justify-self-end">
            {turfDetails.slot_time} Slots
          </p>
          <p className="text-[4.375vw] md:text-[1.5vw] font-poppins">
            ₹ {bookingDetails.selectedTurf.pricing}
          </p>
          <p className="text-[4.375vw] md:text-[1.5vw] font-poppins flex items-center justify-self-end">
            <img
              src={star}
              alt="Star"
              className="w-[5vw] h-[5vw] mr-[3.125vw] md:w-[1.5vw] md:h-[1.5vw] md:mr-[0.25vw]"
            />
            {turfDetails.ratings[1] > 0
              ? (turfDetails.ratings[0] / turfDetails.ratings[1]).toFixed(1)
              : 0}
            ({turfDetails.ratings[1]})
          </p>
        </div>
        {/* MAP VISIBLE ON MOBILE SCREENS */}
        <div className="block md:hidden">
          <Location
            address={turfDetails.address}
            geo_coordinates={turfDetails.geo_coordinates}
          />
        </div>
        {/* GAME TYPE || FACILITY AMENITITES || SELECTED TURF */}
        <div className="grid grid-cols-1 md:grid-cols-3 mx-[4vw] my-[2vw] gap-[9.375vw]">
          <div className="col-span-1 flex flex-col gap-[3.125vw] md:gap-[1.875vw]">
            <p className="font-poppins font-medium text-[4.375vw] md:text-[1.5vw] text-[#4277FF] tracking-[0.4px] leading-[1.5vw]">
              Game Type
            </p>
            <p className="w-fit py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[3.75vw] md:text-[1.25vw] text-[#61646B] border border-[#D9D9D9] bg-white rounded-full drop-shadow-[0_6px_6px_rgba(0,0,0,0.25)]">
              {bookingDetails.gameType || "Unknown"}
            </p>
          </div>
          <div className="col-span-1 flex flex-col gap-[3.125vw] md:gap-[1.875vw]">
            <p className="font-poppins font-medium text-[4.375vw] md:text-[1.5vw] text-[#4277FF] tracking-[0.4px] leading-[1.5vw]">
              Facility Amenities
            </p>
            <div className="flex flex-wrap gap-[3.125vw] md:gap-[1.875vw]">
              {turfDetails.amenities.length > 0 ? (
                turfDetails.amenities.map((amenity, index) => (
                  <div
                    key={index}
                    className="w-fit py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[3.75vw] md:text-[1.25vw] text-[#61646B] border border-[#D9D9D9] bg-white rounded-full drop-shadow-[0_6px_6px_rgba(0,0,0,0.25)]"
                  >
                    {amenity}
                  </div>
                ))
              ) : (
                <p className="w-fit py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[3.75vw] md:text-[1.25vw] text-[#61646B] border border-[#D9D9D9] bg-white rounded-full drop-shadow-[0_6px_6px_rgba(0,0,0,0.25)]">
                  "None Listed"
                </p>
              )}
            </div>
          </div>
          <div className="col-span-1 flex flex-col gap-[3.125vw] md:gap-[1.875vw]">
            <p className="font-poppins font-medium text-[4.375vw] md:text-[1.5vw] text-[#4277FF] tracking-[0.4px] leading-[1.5vw]">
              Surface Type
            </p>
            <p className="w-fit py-[1.875vw] px-[3.125vw] md:p-[0.625vw] text-[3.75vw] md:text-[1.25vw] text-[#61646B] border border-[#D9D9D9] bg-white rounded-full drop-shadow-[0_6px_6px_rgba(0,0,0,0.25)]">
              {bookingDetails.selectedTurf.surfaceType} -{" "}
              {bookingDetails.selectedTurf.size}
            </p>
          </div>
        </div>
        <div className="cursor-pointer self-center w-[55.625vw] h-[9.375vw] md:w-[23.8125vw] md:h-[4.1875vw] bg-[#2561C4] rounded-full drop-shadow-[0_9px_9px_rgba(0,0,0,0.25)] flex gap-[3.125vw] md:gap-[0.625vw] justify-center items-center">
          <p
            onClick={handleJoinWhatsApp}
            className="text-[3.75vw] md:text-[1.5vw] font-poppins text-white"
          >
            Request to Join
          </p>
          <img
            src={imgwb}
            className="w-[3.75vw] h-[3.75vw] md:w-[1.625vw] md:h-[1.625vw]"
            alt="icon"
          />
        </div>
        <TurfRules rules={turfDetails.venue_rules} />
      </div>
      <div className="hidden sm:block md:mt-[4vw]">
        <Footer />
      </div>
    </>
  );
};

export default CommunityJoin;
