import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./output.css";
import Login from "./pages/Login/Login";
import Homepage from "./pages/Home/Homepage";
import BookingDetails from "./pages/Bookings/BookingDetails";
import MyBookings from "./pages/MyBookings/MyBookings";
import TurfDetails from "./pages/TurfDetails/TurfDetails";
import UserProfile from "./pages/Profile/UserProfile";
import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";
import CommunityJoin from "./pages/TurfDetails/CommunityJoin";
import ListYourTurf from "./pages/ListTurf/ListYourTurf";
import ScrollToTop from "./ScrollToTop";
import Admin from "./admin/Admin";
import useAuthState from "./firebase/useAuthState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditTurf from "./pages/ListTurf/EditTurf";
import SeeUserProfile from "./admin/pages/SeeUserProfile";
import FAQPage from "./pages/Other/FAQPage";
import AdminLogin from "../src/admin/pages/AdminLogin";
import TermsAndConditions from "./pages/Other/TermsAndConditions";
import Policy from "./pages/Other/Policy";
import BookingDetailsAdmin from "./admin/pages/BookingDetailsAdmin";
import MyDashboard from "./pages/TurfOwnerDashboard/MyDashboard";

function App() {
  const [role, setRole] = useState("Player");
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("isAdmin")) || false
  );
  const loading = useAuthState();

  const ProtectedRoute = ({ element }) => {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
        />
        <Navbar />
        {element}
      </>
    );
  };

  const handleToggle = () => {
    setRole(role === "Player" ? "Turf Owner" : "Player");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<Login handleToggle={handleToggle} role={role} />}
        />
        <Route
          path="/"
          element={
            <ProtectedRoute
              element={<Homepage role={role} handleToggle={handleToggle} />}
            />
          }
        />
        <Route
          path="/bookingDetails"
          element={<ProtectedRoute element={<BookingDetails />} />}
        />
        <Route
          path="/myBookings"
          element={<ProtectedRoute element={<MyBookings />} />}
        />
        <Route
          path="/turfDetails/:turfid"
          element={<ProtectedRoute element={<TurfDetails />} />}
        />
        <Route
          path="/userProfile/:userId"
          element={<ProtectedRoute element={<UserProfile />} />}
        />
        <Route
          path="/communityJoin/:bookingId"
          element={<ProtectedRoute element={<CommunityJoin />} />}
        />
        <Route
          path="/listYourTurf/:userId"
          element={<ProtectedRoute element={<ListYourTurf />} />}
        />
        <Route
          path="/editTurf/:turfId"
          element={<ProtectedRoute element={<EditTurf />} />}
        />
        <Route
          path="/frequentlyasked"
          element={<ProtectedRoute element={<FAQPage />} />}
        />
        <Route
          path="/termsandconditions"
          element={<ProtectedRoute element={<TermsAndConditions />} />}
        />
        <Route
          path="/policies"
          element={<ProtectedRoute element={<Policy />} />}
        />
        <Route
          path="/myDashboard"
          element={<ProtectedRoute element={<MyDashboard />} />}
        />

        {!isAdmin && (
          <Route
            path="/v0/admin"
            element={<AdminLogin setIsAdmin={setIsAdmin} />}
          />
        )}

        {/* Admin routes */}
        {isAdmin && (
          <Route path="/v0/admin" element={<Admin setIsAdmin={setIsAdmin} />} />
        )}
        {isAdmin && (
          <Route path="/seeUserProfile/:userId" element={<SeeUserProfile />} />
        )}
        {isAdmin && (
          <Route
            path="/seeBookingDetails/:bookingId"
            element={<BookingDetailsAdmin />}
          />
        )}
      </Routes>
      <ScrollToTop />
    </Router>
  );
}

export default App;
