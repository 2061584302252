import React from "react";
import img from "../../assets/turfDetails/image 45.png";
import MapEmbed from "./MapEmbed";
import "./Location.css";

const Location = ({ address, geo_coordinates }) => {
  return (
    <div className="flex flex-row md:flex-col gap-[1.25vw] px-[3.75vw] py-[1.875vw] rounded-[1vw] border border-[#AFB1B6]">
      <div className="w-[62.5vw] md:w-fit flex flex-col gap-[1.25vw]">
        <h2 className="text-[4.375] md:text-[2vw] font-poppins font-medium text-[#4277FF]">
          Location
        </h2>
        <p className="text-[3.75vw] md:text-[1.25vw] font-poppins">{address}</p>
      </div>

      <div className="w-[31.25vw] h-[31.25vw] md:w-[30vw] md:h-[14vw] overflow-hidden rounded-[1.25vw]">
        <MapEmbed
          coordinates={[geo_coordinates.latitude, geo_coordinates.longitude]}
        />
      </div>
    </div>
  );
};

export default Location;
