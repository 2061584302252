import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Template from "./Template";

const Admin = ({ setIsAdmin }) => {
  const [page, setPage] = useState("Users");
  return (
    <div className="grid grid-cols-5 h-screen">
      <Sidebar page={page} setPage={setPage} />
      <Template setIsAdmin={setIsAdmin} page={page} />
    </div>
  );
};

export default Admin;
