import React, { useEffect, useState } from "react";
import Cardslider from "./Cardslider";
import img1 from "../../assets/homepage/shape_51.png";
import img2 from "../../assets/homepage/shape_20.png";
import img3 from "../../assets/homepage/shape_119.png";
import filterImg from "../../assets/homepage/Filter.png";
import CustomSelect from "./CustomSelect";
import { useSelector } from "react-redux";
import CardGrid from "./CardGrid";
import { toast } from "react-toastify";

const FeaturedSection = ({ turfs }) => {
  const user = useSelector((state) => state.auth.user);
  const [filteredTurfs, setFilteredTurfs] = useState(turfs);
  const [filter, setFilter] = useState("");
  const [seeAll, setSeeAll] = useState(false);
  const [city, setCity] = useState(localStorage.getItem("city"));

  window.addEventListener("cityUpdated", () => {
    const updatedCity = localStorage.getItem("city");
    setCity(updatedCity);
  });

  useEffect(() => {
    if (turfs.length) {
      let sortedTurfs = [...turfs];

      if (filter === "Ratings: High to Low") {
        sortedTurfs = sortedTurfs.sort(
          (a, b) => b.ratings[0] / b.ratings[1] - a.ratings[0] / a.ratings[1]
        );
      } else if (filter === "Pricing: Low to High") {
        sortedTurfs = sortedTurfs.sort(
          (a, b) => a.available_turfs[0].pricing - b.available_turfs[0].pricing
        );
      } else if (filter === "Pricing: High to Low") {
        sortedTurfs = sortedTurfs.sort(
          (a, b) => b.available_turfs[0].pricing - a.available_turfs[0].pricing
        );
      }

      setFilteredTurfs(sortedTurfs);
    }
  }, [turfs, user, filter]);

  const handleSelectChange = (selectedValue) => {
    setFilter(selectedValue);
  };

  useEffect(() => {
    if (turfs && city) {
      const newFilteredTurfs = turfs.filter((turf) =>
        turf.address.toLowerCase().includes(city.toLowerCase())
      );
      setFilteredTurfs(newFilteredTurfs);
    }
  }, [city]);

  return (
    <div className="relative z-10 p-[2vw] top-0 max-w-full md:mx-[4vw] md:mb-[4vw] mb-[20vw]">
      {/* Images positioned at corners */}
      <div className="hidden md:block absolute top-[3.125vw] mr-[-4vw] right-[1.25vw] w-[3.375vw] h-[5.875vw]">
        <img src={img1} alt="" className="w-full h-full object-contain" />
      </div>
      <div className="hidden md:block absolute top-[11vw] mr-[-4vw] right-[1.25vw] w-[0.625vw] h-[3vw]">
        <img src={img3} alt="" className="w-full h-full object-contain" />
      </div>
      <div className="hidden md:block absolute bottom-[-3vw] left-[4vw] w-[7vw] h-[4vw]">
        <img src={img2} alt="" className="w-full h-full object-contain" />
      </div>
      <p className="font-poppins font-semibold text-[5vw] md:text-[2vw] text-[#27346A] my-[1vw] leading-[160%] tracking-[0.6px]">
        Featured Turfs
      </p>
      <div className="flex justify-between items-center mr-[2vw] px-[2vw] py-[1vw] text-[#1355FF]">
        <div className="flex items-center gap-[1vw]">
          <div
            className="mb-[0.25vw] md:mb-0 text-[4.325vw] md:text-[1vw] cursor-pointer"
            onClick={() => {
              setSeeAll(!seeAll);
            }}
          >
            {seeAll ? "See Less" : "See All"}
          </div>
        </div>
        <div className="">
          <CustomSelect onChange={handleSelectChange} />
        </div>
      </div>
      {seeAll ? (
        <div className="my-[0.5vw] px-[2vw]">
          {filteredTurfs.length ? (
            <CardGrid turfs={filteredTurfs} />
          ) : (
            toast.error(`No featured turfs in ${city}. Showing all turfs.`) &&
            setFilteredTurfs(turfs)
          )}
        </div>
      ) : (
        <div className="my-[0.5vw]">
          {filteredTurfs.length ? (
            <Cardslider turfs={filteredTurfs} />
          ) : (
            toast.error(`No featured turfs in ${city}. Showing all turfs.`) &&
            setFilteredTurfs(turfs)
          )}
        </div>
      )}
    </div>
  );
};

export default FeaturedSection;
