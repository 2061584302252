import React from "react";
import bgImg from "../../assets/homepage/Section-1.png";
import polkaDots from "../../assets/homepage/hero-section-polka-dots.png";
import RoleToggleButton from "../../components/RoleToggleButton";

const HeroSection = ({ handleToggle, role }) => {
  return (
    <div className="relative mt-[2vw] h-fit">
      <div className="hidden md:block w-[15vw] h-[17.5vw] md:w-[7vw] md:h-[9.25vw] absolute m-[2.5vw] right-0 bottom-0">
        <img
          src={polkaDots}
          alt="design"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="w-[65.55vw] h-[63.35vw] md:h-[48.75vw] md:mt-[5vw] ml-[-10vw] md:ml-[-5vw] object-contain">
        <img
          src={bgImg}
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="absolute top-[-10vw] md:top-[-5vw] left-0 w-full h-full flex flex-col items-end justify-center text-right px-[4vw] z-10">
        <h1 className="font-bebas text-[6.25vw] md:text-[4.5vw] text-[#FF5F00]">
          Unleash Your Inner Champion!
        </h1>
        <p className="font-poppins md:font-bold text-[3.325vw] md:text-[3vw] py-[0.5vw]">
          Book, Play, and Conquer the
        </p>
        <p className="font-poppins sm:font-bold text-[3vw] py-[0.5vw]">
          Turf with{" "}
          <span className="text-[#27346A] font-semibold">Turfbrella!</span>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
