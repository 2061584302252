import React from "react";
import Card from "./Card"; // Import the Card component

const CardGrid = ({ turfs }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[2vw] py-[1vw] justify-items-center">
      {turfs.map((card, index) => (
        <Card
          key={index}
          turfId={card.turf_id}
          title={card.name}
          address={card.address}
          image={card.images[0]}
          ratings={card.ratings}
        />
      ))}
    </div>
  );
};

export default CardGrid;
