import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "./Card";
import "./CardSlider.css";
import { NextArrow, PrevArrow } from "./CustomArrow";
import image from "../../assets/homepage/image 41.png";

const CustomDots = ({ dots }) => {
  return (
    <ul className="slick-dots">
      {dots.map((dot, index) => (
        <li key={index} className={dot.props.className}>
          {dot.props.children}
        </li>
      ))}
    </ul>
  );
};

const CardSlider = ({ turfs, editable = false }) => {
  const [settings, setSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <CustomDots dots={dots} />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const updateSettings = () => {
      if (window.innerWidth <= 480) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          nextArrow: null,
          prevArrow: null,
        }));
      } else {
        setSettings((prevSettings) => ({
          ...prevSettings,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        }));
      }
    };

    updateSettings();
    window.addEventListener("resize", updateSettings);
    return () => window.removeEventListener("resize", updateSettings);
  }, []);

  return (
    <div className="">
      {Array.isArray(turfs) && turfs.length > 0 ? (
        <Slider {...settings} className="card-slider">
          {turfs.map((card, index) => (
            <Card
              key={index}
              turfId={card.turf_id}
              title={card.name}
              address={card.address}
              image={card.images[0]}
              ratings={card.ratings}
              editable={editable}
            />
          ))}
        </Slider>
      ) : (
        <p>No turfs available.</p>
      )}
    </div>
  );
};

export default CardSlider;
