import React from "react";
import Footer from "../../components/Footer";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="container max-w-4xl mx-auto p-6 mb-[20vw] font-poppins text-justify md:mb-0">
        <h1 className="text-2xl font-bold text-center mb-8">
          Terms and Conditions
        </h1>

        <section>
          <h2 className="text-xl font-semibold mb-4">Acceptance of Terms</h2>
          <p>
            By accessing or using the Turfbrella website, mobile application, or
            any services provided by Turfbrella (collectively referred to as the
            "Service"), you agree to be bound by these Terms and Conditions. If
            you do not agree to these terms, please do not use our Service.
            These terms apply to all users, including casual visitors,
            registered users, and paying customers.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            Service Description
          </h2>
          <p>
            Turfbrella is an online platform that allows users to book sports
            turf facilities for various activities. Additionally, we provide a
            feature that enables users to connect with other willing
            participants when there is a shortage of players for their booked
            activities. Our service is intended to facilitate sports and
            recreational activities and foster community engagement.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">User Registration</h2>
          <p>
            To fully utilize our Service, users must create an account. During
            registration, you agree to provide accurate, current, and complete
            information. You are responsible for maintaining the confidentiality
            of your account credentials and for all activities that occur under
            your account. Turfbrella reserves the right to refuse registration,
            cancel accounts, or remove or edit content at our sole discretion.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            Booking and Payments
          </h2>
          <p>
            All bookings are subject to facility availability and confirmation.
            The prices for bookings are listed on our platform at the time of
            booking. Payments are processed through secure third-party payment
            providers. By making a payment, you agree to the terms and
            conditions of these payment providers. Turfbrella reserves the right
            to change prices at any time, but the changes will not affect
            bookings already confirmed. Cancellations and refunds are subject to
            our separate Cancellation Policy, which is incorporated into these
            Terms and Conditions by reference.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">User Conduct</h2>
          <p>
            Users of Turfbrella agree to use the Service in compliance with all
            applicable laws and regulations. Users should refrain from abusive,
            offensive, or harassing behavior. Users should not use the Service
            for any commercial purposes without explicit permission from
            Turfbrella. Users should not attempt to gain unauthorized access to
            any part of the Service or interfere with its functionality.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            Participant Matching
          </h2>
          <p>
            Turfbrella provides a platform for users to connect with other
            participants but does not guarantee the availability or suitability
            of other players. Users connecting through our platform do so at
            their own risk. Turfbrella is not responsible for verifying the
            identity, skills, or intentions of users. We encourage users to
            exercise caution and good judgment when meeting or playing with
            individuals connected with our Service. Turfbrella is not liable for
            any disputes, injuries, or issues arising from interactions between
            users.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">Liability</h2>
          <p>
            Participation in sports activities involves inherent risks. Users
            engage in activities at their own risk. Turfbrella is not liable for
            any physical injuries, property damage, or losses incurred during
            the use of booked facilities or participation in activities
            organized through our platform. We do not endorse or validate the
            safety or condition of the sports facilities listed on our platform.
            Users are advised to assess the suitability and safety of facilities
            themselves. Turfbrella is not responsible for cancellations,
            facility changes, or any actions of the sports facility providers.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            Privacy and Data Protection
          </h2>
          <p>
            Turfbrella collects and processes personal data as described in our
            Privacy Policy, which is incorporated into these Terms and
            Conditions by reference. By using our Service, you consent to the
            collection, use, and sharing of your information as outlined in the
            Privacy Policy. Users have the right to access, correct, or delete
            their personal information held by Turfbrella, subject to certain
            limitations.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            Intellectual Property
          </h2>
          <p>
            All content on the Turfbrella website, including text, graphics,
            logos, images, and software, is the property of Turfbrella or its
            content suppliers and is protected by copyright and other
            intellectual property laws. Users may not copy, modify, distribute,
            sell, or lease any part of our Service or included software, nor may
            they reverse engineer or attempt to extract the source code of that
            software, unless they have our written permission or applicable laws
            prohibit these restrictions.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">Termination</h2>
          <p>
            Turfbrella reserves the right to suspend or terminate user accounts,
            with or without notice, for violations of these Terms and
            Conditions, or for any other reason at our sole discretion. Users
            may terminate their account at any time by contacting our support
            team. Upon termination, users lose access to our Service, and we may
            delete all user data associated with the account.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">Changes to Terms</h2>
          <p>
            Turfbrella may modify these Terms and Conditions at any time. We
            will provide notice of significant changes through our website or
            via email to registered users. Continued use of the Service after
            any changes constitutes acceptance of the new Terms and Conditions.
            It is the user's responsibility to review these terms periodically
            for updates.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">Governing Law</h2>
          <p>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of the Government of India. Any disputes
            arising under or in connection with these terms shall be subject to
            the exclusive authority of the courts located in Mumbai,
            Maharashtra.
          </p>
        </section>

        <h2 className="text-2xl font-bold mt-8 text-center mb-4">
          Venue Owners
        </h2>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            1. Registration and Listing
          </h2>
          <p>
            Venue owners must provide accurate and up-to-date information about
            their facilities. This includes, but is not limited to: precise
            location and address, detailed description of available sports
            facilities, full list of amenities, high-quality photos of the
            venue, operating hours, and pricing structure. Turfbrella reserves
            the right to verify all submitted information, and listings may be
            removed if information is inaccurate or misleading.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            2. Venue Management
          </h2>
          <p>
            Venue owners are responsible for keeping their availability calendar
            up-to-date, updating pricing, responding to booking requests, and
            honoring bookings. Any unexpected closures or changes affecting
            existing bookings must be communicated to Turfbrella immediately.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            3. Pricing and Payments
          </h2>
          <p>
            Venue owners control their pricing and may offer discounts.
            Turfbrella charges a commission on each booking, with payment
            disbursed to owners regularly. Owners must provide accurate banking
            information and adhere to the commission terms outlined in the Venue
            Agreement.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            4. Cancellations and Refunds
          </h2>
          <p>
            Venue owners must follow the Turfbrella cancellation policy.
            Venue-initiated cancellations must include full refunds and may
            result in penalty fees. Repeated cancellations may lead to
            suspension or termination.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            5. Safety Compliance
          </h2>
          <p>
            Venue owners must comply with all local safety regulations and
            address any safety concerns promptly. Turfbrella disclaims liability
            for any injuries, property damage, or disputes between venue owners
            and users.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            6. User Interactions
          </h2>
          <p>
            Venue owners agree to treat users with respect and professionalism.
            Any disputes with users should be reported to Turfbrella, and owners
            must cooperate with Turfbrella' investigation and mediation efforts.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            7. Exclusivity and Direct Bookings
          </h2>
          <p>
            Venue owners agree not to circumvent Turfbrella by accepting direct
            bookings from users initially introduced through the platform or
            offering lower rates for direct bookings. Any exclusive arrangements
            must be documented in the Venue Agreement.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            8. Reviews and Ratings
          </h2>
          <p>
            Users may leave reviews for venues, and venue owners may respond.
            Owners should not offer incentives for positive reviews or retaliate
            against users for negative reviews.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            9. Compliance with Laws
          </h2>
          <p>
            Venue owners must comply with all applicable laws, including local
            zoning laws, health and safety regulations, and tax obligations.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">10. Termination</h2>
          <p>
            Turfbrella may terminate a venue's listing for repeated violations,
            failure to maintain required standards, or consistently poor
            reviews. Venue owners may request to delist by providing written
            notice to Turfbrella and fulfilling all existing booking
            obligations.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            11. Modifications to Service
          </h2>
          <p>
            Turfbrella may modify platform features or policies affecting venue
            owners. Significant changes will be communicated 30 days in advance,
            and owners will have the opportunity to review and accept the
            changes.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            12. Intellectual Property
          </h2>
          <p>
            Venue owners grant Turfbrella a non-exclusive, worldwide license to
            use submitted content for promoting the venue and improving the
            platform.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            13. Dispute Resolution
          </h2>
          <p>
            Any disputes between Turfbrella and venue owners will be resolved
            through mediation, and if unresolved, through binding arbitration as
            outlined in the Venue Agreement.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-6 mb-4">
            14. Indemnification
          </h2>
          <p>
            Venue owners agree to indemnify Turfbrella from any claims,
            liabilities, or damages arising from the use or operation of the
            venue or any violations of these Terms.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
