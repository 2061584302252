// CustomArrow.js
import React from 'react';
import left from '../../assets/homepage/Arrow - Left 2.png'
import right from '../../assets/homepage/Arrow - Right 2.png'

export const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1 }}
      onClick={onClick}
    >
      <img
        src={right}
        alt="Next"
        style={{ width: '30px', height: '30px' }}
      />
    </div>
  );
};

export const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1 }}
      onClick={onClick}
    >
      <img
        src={left}
        alt="Prev"
        style={{ width: '30px', height: '30px' }}
      />
    </div>
  );
};
