import React, { useState } from "react";
import RoleToggleButton from "../../components/RoleToggleButton";
import googleIcon from "../../assets/loginpage/flat-color-icons_google.png";
import appleIcon from "../../assets/loginpage/foundation_social-apple.png";
import mailIcon from "../../assets/loginpage/mail-01.png";
import lockIcon from "../../assets/loginpage/lock.png";
import eyeIcon from "../../assets/loginpage/eye-slash.png";
import eyeOpenIcon from "../../assets/loginpage/eye.png";
import {
  signInWithApple,
  signInWithEmail,
  signInWithGoogle,
} from "../../firebase/authController";
import { playerSchema } from "../../firebase/schemas";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../app/features/authSlice";
import logoImg2 from "../../assets/homepage/image 3.png";

const SignIn = ({ setNewUser, handleToggle, role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  // Email regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateEmail = (value) => {
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  function handleEmailSignIn(e) {
    e.preventDefault();
    const password = e.target.password.value;
    signInWithEmail(email, password).then((userData) => {
      dispatch(setUser(userData));
      navigate("/");
    });
  }

  function handleGoogleSignIn(e) {
    e.preventDefault();
    signInWithGoogle(playerSchema, role).then((userData) => {
      dispatch(setUser(userData));
      navigate("/");
    });
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="max-w-xs lg:max-w-md p-4 lg:p-0">
      <div className="flex justify-center mb-[5vw] md:mb-[2vw] w-full h-full">
        <img src={logoImg2} className="w-full h-full object-contain" alt="" />
      </div>
      <h1 className="font-poppins my-3 text-lg font-bold">Sign In</h1>
      <p className="font-poppins mb-5 text-sm lg:text-base text-gray-700">
        Sign In to your Turfbrella {role} Account!
      </p>
      <p className="flex justify-center items-center my-6 lg:my-9">
        <span
          className={`font-poppins mx-4 text-lg lg:text-xl ${
            role === "Player" ? "text-blue-500 font-bold" : "text-blue-300"
          }`}
        >
          Player
        </span>
        <RoleToggleButton role={role} handleToggle={handleToggle} />
        <span
          className={`font-poppins mx-4 text-lg lg:text-xl ${
            role !== "Player" ? "text-blue-500 font-bold" : "text-blue-300"
          }`}
        >
          Turf Owner
        </span>
      </p>
      <form
        onSubmit={handleEmailSignIn}
        action=""
        className="flex flex-col gap-[5vw] md:gap-[1.75vw] w-full md:min-w-[30vw] md:w-full"
      >
        <div className="flex flex-col gap-[2.5vw] md:gap-[0.5vw]">
          <div className="relative w-full h-12 drop-shadow-xl">
            <img
              src={mailIcon}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              alt="icon"
            />
            <input
              className="w-full h-full px-10 py-2 select-none outline-none bg-gray-100 border border-gray-300 rounded-lg"
              type="email"
              required
              placeholder="Email"
              name="email"
              id="email"
              autocomplete="new-password"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
            />
          </div>
          {email ? (
            emailError ? (
              <p className="text-red-600 text-sm">{emailError}</p>
            ) : (
              <p className="text-green-600 text-sm">Looks good!</p>
            )
          ) : (
            <p className="text-green-600 text-sm"></p>
          )}
        </div>
        <div className="relative mb-6 lg:mb-9 w-full h-12 lg:h-12 drop-shadow-xl">
          <img
            src={lockIcon}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
            alt="icon"
          />
          <input
            className="w-full h-full px-10 py-2 bg-gray-100 border border-gray-300 rounded-lg"
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            required
            name="password"
          />
          <img
            src={passwordVisible ? eyeOpenIcon : eyeIcon}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 cursor-pointer"
            alt="icon"
            onClick={togglePasswordVisibility}
          />
        </div>
        <p className="text-right font-poppins text-sm lg:text-base cursor-pointer mb-6 lg:mb-9 text-red-600">
          Forgot Password?
        </p>
        <button
          type="submit"
          className="w-full h-12 rounded-lg font-poppins text-base mb-5 bg-blue-600 text-white"
        >
          Sign In
        </button>
      </form>
      <div className="flex items-center text-center my-6 lg:my-9">
        <div className="flex-1 h-px bg-gray-300"></div>
        <p className="font-poppins text-sm lg:text-base mx-4 text-gray-700">
          Or Sign In with
        </p>
        <div className="flex-1 h-px bg-gray-300"></div>
      </div>
      <div
        className="flex justify-center items-center w-full h-12 shadow-xl rounded-lg font-poppins text-base mb-6 lg:mb-9 cursor-pointer bg-white"
        onClick={handleGoogleSignIn}
      >
        <img src={googleIcon} alt="" className="w-6 h-6 mr-3" />
        <p>Sign In with Google</p>
      </div>
      {/* <div
        className="flex justify-center items-center w-full h-12 shadow-xl rounded-lg font-poppins text-base mb-6 lg:mb-9 cursor-pointer bg-white"
        onClick={() => {
          signInWithApple(playerSchema);
        }}
      >
        <img src={appleIcon} alt="" className="w-6 h-6 mr-3" />
        <p>Sign In with Apple</p>
      </div> */}
      <p className="text-center my-6 lg:my-9">
        Don’t have an account?{" "}
        <button onClick={() => setNewUser(true)} className="text-red-600">
          Sign Up
        </button>
      </p>
    </div>
  );
};

export default SignIn;
